import { Skeleton, Stack, Typography } from "@mui/material";
import React from "react";

export const WalletLoading: React.FC = () => {
  return (
    <Stack gap={2}>
      <Typography variant="h5">
        <Skeleton variant="text" />
      </Typography>
      <Stack direction={"row"} gap={2} flex={1}>
        <Skeleton
          sx={{ display: "flex", flex: 1, height: 100 }}
          variant="rounded"
        />
        <Skeleton
          sx={{ display: "flex", flex: 1, height: 100 }}
          variant="rounded"
        />
        <Skeleton
          sx={{ display: "flex", flex: 1, height: 100 }}
          variant="rounded"
        />
      </Stack>
      <Typography variant="h5">
        <Skeleton variant="text" />
      </Typography>
      <Skeleton sx={{ height: 360 }} variant="rounded" />
    </Stack>
  );
};
