import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { TransformationPicAngle } from "../../types";
import { TransformationImageAngleContainer } from "./components/TransformationImageAngleContainer";
import { useTransformationCard } from "./useTransformationCard";

export const TransformationCard: React.FC<
  ReturnType<typeof useTransformationCard>
> = ({
  name,
  email,
  workoutPlanName,
  totalWorkouts,
  workoutsCompleted,
  workoutsCompletedPercentage,
  transformationTime,
  selectedAngle,
  handleAngleChange,
  selectedAngleBeforePicture,
  selectedAngleAfterPicture,
  workoutPlanNameRef,
  isWorkoutPlanNameTruncated,
  downloadImage,
  hasPublicSharingConsent,
}) => {
  return (
    <Card elevation={1}>
      <CardContent sx={{ padding: 0 }}>
        <Box sx={{ padding: 2 }}>
          <Typography mb={-1} variant="h6" fontSize={18} fontWeight={600}>
            {name}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {email}
          </Typography>

          <Typography variant="body2">
            Workouts completed: {workoutsCompleted}/{totalWorkouts} (
            {workoutsCompletedPercentage}%)
          </Typography>

          <Tooltip
            title={workoutPlanName}
            placement="bottom-start"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            disableHoverListener={!isWorkoutPlanNameTruncated}
          >
            <Typography
              ref={workoutPlanNameRef}
              color="text.secondary"
              variant="body2"
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {workoutPlanName}
            </Typography>
          </Tooltip>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 1,
              marginBottom: 2,
            }}
          >
            <Typography variant="body2" fontWeight={600}>
              Transformation time
            </Typography>
            <Typography variant="body2">: {transformationTime}</Typography>
          </Box>

          <ToggleButtonGroup
            value={selectedAngle}
            exclusive
            onChange={handleAngleChange}
            aria-label="angle selection"
            sx={{
              width: "100%",
              ".MuiToggleButtonGroup-grouped": {
                flex: 1,
                borderRadius: 2,
                borderColor: "black",
                color: "black",
              },
              ".Mui-selected": {
                backgroundColor: "#E8DEF8 !important",
                border: "1px solid black !important",
              },
            }}
          >
            {[
              TransformationPicAngle.FRONT,
              TransformationPicAngle.BACK,
              TransformationPicAngle.SIDE,
            ].map((angle) => (
              <ToggleButton
                key={angle}
                value={angle}
                sx={{
                  height: 28,
                  fontSize: 16,
                  textTransform: "none",
                }}
              >
                {selectedAngle === angle && (
                  <CheckIcon fontSize="inherit" sx={{ marginRight: 0.6 }} />
                )}
                <Typography variant="body2" fontSize={14} fontWeight={500}>
                  {angle}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <TransformationImageAngleContainer
          selectedAngleBeforePicture={selectedAngleBeforePicture!}
          selectedAngleAfterPicture={selectedAngleAfterPicture}
          hasPublicSharingConsent={hasPublicSharingConsent}
          downloadImage={downloadImage}
        />
      </CardContent>
    </Card>
  );
};
