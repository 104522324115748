import Skeleton from "@mui/material/Skeleton";
import React, { ImgHTMLAttributes, useState } from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

export const ImageWithSkeleton: React.FC<Props> = ({ src, ...imgProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onLoad = () => setIsLoading(false);
  const onError = () => setIsError(true);
  return (
    <>
      {src && isLoading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ paddingBottom: "100%", ...imgProps.style, borderRadius: 1 }}
        />
      )}
      <img
        {...imgProps}
        onError={onError}
        src={isError ? "fallback.png" : src}
        onLoad={onLoad}
        style={{
          display: isLoading ? "none" : "block",
          ...imgProps.style,
          borderRadius: 8,
        }}
      />
    </>
  );
};
