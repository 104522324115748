import { useStore } from "../../../../store";
import { exerciseActions } from "../../../../store/exercises/slice";
import { favouritesActions } from "../../../../store/favourites/slice";
import { payoutsActions } from "../../../../store/payouts/slice";
import { planBuilderActions } from "../../../../store/plan-builder/slice";
import { playlistActions } from "../../../../store/playlist/slice";
import { selectUser } from "../../../../store/user/selectors";
import { userActions } from "../../../../store/user/slice";
import { workoutBuilderActions } from "../../../../store/workout-builder/slice";
import { workoutPlanActions } from "../../../../store/workout-plan/slice";
import { workoutActions } from "../../../../store/workout/slice";

export const useSideNavigation = () => {
  const [select, dispatch] = useStore();
  const user = select(selectUser);

  const resetGlobalState = () => {
    dispatch(userActions.reset());
    dispatch(playlistActions.reset());
    dispatch(workoutActions.reset());
    dispatch(exerciseActions.reset());
    dispatch(workoutPlanActions.reset());
    dispatch(workoutBuilderActions.reset());
    dispatch(planBuilderActions.reset());
    dispatch(favouritesActions.reset());
    dispatch(payoutsActions.reset());
  };

  return {
    firstName: user.data?.firstName,
    lastName: user.data?.lastName,
    resetGlobalState,
  };
};
