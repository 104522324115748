import { useSignups } from "./useSignups";
import { useSubscriptionStatusBreakdown } from "./useSubscriptionStatusBreakdown";

export const useDashboard = () => {
  const signupHelpers = useSignups();
  const subscriptionStatusBreakdownHelpers = useSubscriptionStatusBreakdown();

  return {
    ...signupHelpers,
    ...subscriptionStatusBreakdownHelpers,
  };
};
