import { CreatorExercise } from "../../../../store/exercises/types";
import {
  ExerciseEntry,
  ExerciseParameter,
  ExerciseParameterValue,
  ExerciseSet,
} from "../../../../store/workout/types";
import { randomUUID } from "../../../../util/uuid";
import {
  REPS_EXERCISE_PARAMETER,
  REST_EXERCISE_PARAMETER,
  TIME_EXERCISE_PARAMETER,
  WEIGHT_EXERCISE_PARAMETER,
  createDefaultRepsParameterValue,
  createDefaultRestParameterValue,
} from "../fixtures";

export interface GridRow {
  id: string;
  setNumber: number;
  Reps?: number;
  Time?: number;
  Weight?: number;
  Rest?: number;
  _Rest?: string;
}

export const order: Record<string, number> = {
  Reps: 0,
  Time: 1,
  Weight: 2,
  Rest: 3,
};

export const createDefaultEntryFromExercise = (
  exercise: CreatorExercise
): ExerciseEntry => ({
  id: randomUUID(),
  exercise,
  sets: [
    {
      id: randomUUID(),
      values: [
        createDefaultRepsParameterValue(),
        createDefaultRestParameterValue(),
      ],
    },
  ],
  notes: "",
  videoId: null,
});

export const createSetDuplicate = (
  setToDuplicate: ExerciseSet
): ExerciseSet => ({
  values: setToDuplicate.values.map((value) => ({
    id: randomUUID(),
    parameter: value.parameter,
    value: value.value,
  })),
  id: randomUUID(),
});
export const transformToExerciseSet = (set: GridRow): ExerciseSet => ({
  id: set.id,
  values: (() => {
    const parameters: ExerciseParameterValue[] = [];
    set.Reps !== undefined &&
      parameters.push({
        // How do we get the value of the original exercise parameter value id (so we don't have to generate a new one each time)?
        id: randomUUID(),
        parameter: REPS_EXERCISE_PARAMETER,
        value: set.Reps,
      });
    set.Time !== undefined &&
      parameters.push({
        id: randomUUID(),
        parameter: TIME_EXERCISE_PARAMETER,
        value: set.Time,
      });
    set.Weight !== undefined &&
      parameters.push({
        id: randomUUID(),
        parameter: WEIGHT_EXERCISE_PARAMETER,
        value: set.Weight,
      });
    set.Rest !== undefined &&
      parameters.push({
        id: randomUUID(),
        parameter: REST_EXERCISE_PARAMETER,
        value: set.Rest,
      });
    return parameters;
  })(),
});

export const getUniqueExerciseParametersFromSets = (sets: ExerciseSet[]) => {
  const exerciseParameters = sets.reduce(
    (acc: ExerciseParameter[], current) => {
      current.values
        .map((value) => value.parameter)
        .forEach((param) => acc.push(param));
      return acc;
    },
    []
  );
  return Array.from(
    new Set(exerciseParameters.map((item) => JSON.stringify(item)))
  ).map((item) => JSON.parse(item));
};

export const sortExerciseParameters = (
  exerciseParameters: ExerciseParameter[]
) => [...exerciseParameters.sort((a, b) => order[a.name] - order[b.name])];
