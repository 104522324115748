import { Typography, Link } from "@mui/material";
import React from "react";

export const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://myarc.io/">
        MyARC
      </Link>
      {` ${new Date().getFullYear()}.`}
    </Typography>
  );
};
