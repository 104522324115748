import MuiTable from "@mui/material/Table";
import tableBodyClasses from "@mui/material/TableBody/tableBodyClasses";
import tableCellClasses from "@mui/material/TableCell/tableCellClasses";
import tableRowClasses from "@mui/material/TableRow/tableRowClasses";
import styled from "styled-components";

export const Table = styled(MuiTable)(({ theme }) => ({
  [`& .${tableCellClasses.head}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${tableCellClasses.body}`]: {
    borderBottom: "1px solid #EAECF0",
  },
  [`.${tableBodyClasses.root} .${tableRowClasses.root}:nth-child(odd)`]: {
    backgroundColor: theme.palette.primary.xlight,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
}));
