import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import GenericAutocomplete from "../../common/Autocomplete/GenericAutocomplete";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { ChallengePlanTransformationsLoading } from "./components/ChallengePlanTransformationsLoading";
import { EmptyChallengePlanTransformations } from "./components/EmptyChallengePlanTransformations";
import TransformationChip from "./components/TransformationChip";
import { useTransformations } from "./useTransformations";
import { TransformationCardGrid } from "./components/TransformationCardGrid";

interface Props extends ReturnType<typeof useTransformations> {}

export const Transformations: React.FC<Props> = ({
  challengePlans,
  selectedChallengePlans,
  setSelectedChallengePlans,
  completedTransformationsFilter,
  hasGivenConsentFilter,
  onPressCompletedTransformationsFilter,
  onPressHasGivenConsentFilter,
  tranformationCardsData,
  currentPage,
  totalPages,
  isLoading,
  isShowingErrorMessage,
  hideErrorMessage,
  totalChallengePlanTransformations,
}) => {
  return (
    <PageTemplate>
      <PageHeader
        title="Transformations"
        subtitle="View your users' transformations, see their challenge plan results and pick winners."
      />
      <GenericAutocomplete
        options={challengePlans || []}
        getOptionLabel={(option) => option.name}
        label="Filter by challenge"
        placeholder="Search workout plans"
        selectedValues={selectedChallengePlans}
        onSelectedValuesChange={setSelectedChallengePlans}
        width={400}
        variant="outlined"
      />
      <Box sx={{ mt: 3 }}>
        {selectedChallengePlans.length ? (
          <>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Stack direction="row" spacing={1}>
                  <TransformationChip
                    label="Completed transformations"
                    active={completedTransformationsFilter}
                    onClick={onPressCompletedTransformationsFilter}
                  />
                  <TransformationChip
                    label="Has given consent"
                    active={hasGivenConsentFilter}
                    onClick={onPressHasGivenConsentFilter}
                  />
                </Stack>
              </Grid>
              {!isLoading && (
                <Grid item>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "text.disabled",
                      fontSize: "14px",
                    }}
                  >
                    Total transformations found:{" "}
                    {totalChallengePlanTransformations}
                  </Typography>
                </Grid>
              )}
            </Grid>
            {isLoading ? (
              <ChallengePlanTransformationsLoading />
            ) : (
              <Box sx={{ mt: 2 }}>
                {totalChallengePlanTransformations ? (
                  <>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      renderItem={(item) => (
                        <PaginationItem
                          component={Link}
                          to={`/transformations${
                            item.page === 1 ? "" : `?page=${item.page}`
                          }`}
                          {...item}
                        />
                      )}
                    />
                    <TransformationCardGrid
                      tranformationCardsData={tranformationCardsData}
                    />
                  </>
                ) : (
                  <Box>
                    <EmptyChallengePlanTransformations />
                  </Box>
                )}
              </Box>
            )}
          </>
        ) : (
          <Typography variant="body2" color="text.secondary">
            Select a workout plan to see your fans submissions.
          </Typography>
        )}
      </Box>
      <SnackbarAlert
        close={hideErrorMessage}
        isOpen={isShowingErrorMessage}
        severity="error"
        message="Oops! Something went wrong when getting user transformations! Please try again later."
      />
    </PageTemplate>
  );
};
