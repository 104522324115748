import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import { GetFavouriteWorkoutsPaginated } from "../../../store/favourites/actions";
import { selectPaginatedFavourites } from "../../../store/favourites/selectors";
import { selectUser } from "../../../store/user/selectors";
import { PostWorkout } from "../../../store/workout/actions";
import { FormConfirmDialogProps } from "../../common/Dialog/types";

const FAVOURITES_PER_PAGE = 6;

export const useMyFavourites = () => {
  const [select, dispatch] = useStore();
  const query = useQuery();
  const navigate = useNavigate();

  const currentPage = parseInt(query.get("page") ?? "1", 10);
  const isCreate = query.get("create") === "true";

  const user = select(selectUser);
  const paginatedFavourites = select(selectPaginatedFavourites);
  const totalFavourites = paginatedFavourites.count || 0;
  const totalPages = Math.ceil(totalFavourites / FAVOURITES_PER_PAGE);
  const { isLoading, isError } = paginatedFavourites;
  const [isShowingErrorMessage, hideErrorMessage] =
    useErrorMessageState(isError);

  const doesPageExist = useCallback(
    (pageNumber: number) => {
      return !!paginatedFavourites.data?.[pageNumber];
    },
    [paginatedFavourites.data]
  );

  useEffect(() => {
    if (!doesPageExist(currentPage)) {
      GetFavouriteWorkoutsPaginated(dispatch, currentPage);
    }
  }, [currentPage, dispatch, doesPageExist]);

  const [isNewWorkoutDialogOpen, setIsNewWorkoutDialogOpen] =
    useState(isCreate);

  const createNewWorkoutAndNavigate = async (newWorkoutName: string) => {
    const newWorkoutId = await PostWorkout(dispatch, newWorkoutName);
    await GetFavouriteWorkoutsPaginated(dispatch, currentPage);
    newWorkoutId && navigate(`edit/${newWorkoutId}`);
  };

  const openNewWorkoutDialog = () => {
    setIsNewWorkoutDialogOpen(true);
  };

  const newWorkoutDialogProps: FormConfirmDialogProps = {
    title: "First, choose a workout name",
    inputFieldId: "new-workout",
    subtitle: "Don't worry, you can change this later if you want",
    isOpen: isNewWorkoutDialogOpen,
    onClose: () => setIsNewWorkoutDialogOpen(false),
    inputFieldPlaceholder: `${user.data?.firstName}'s Amazing New Workout`,
    inputFieldLabel: `Enter a workout name`,
    onSubmitText: createNewWorkoutAndNavigate,
    onClickCancel: () => setIsNewWorkoutDialogOpen(false),
  };

  return {
    workouts: paginatedFavourites.data?.[currentPage] || [],
    currentPage,
    totalPages,
    openNewWorkoutDialog,
    newWorkoutDialogProps,
    totalWorkouts: totalFavourites,
    isLoading,
    isShowingErrorMessage,
    hideErrorMessage,
  };
};
