import styled from "styled-components";

export const BadgeImage = styled.img`
  width: auto;
  height: 80%;
`;

export const BadgeAnchor = styled.a`
  flex: 1;
  display: "flex";
  align-items: "center";
`;
