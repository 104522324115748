import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

export interface MenuListItem {
  title: string;
  icon: SvgIconComponent;
  path: `/${string}`;
  isActive: boolean;
}

interface MenuListProps {
  isOpen: boolean;
  menuListItems: MenuListItem[];
}

export const MenuList: React.FC<MenuListProps> = ({
  isOpen,
  menuListItems,
}) => {
  const theme = useTheme();
  return (
    <List>
      {menuListItems.map((item) => (
        <ListItem
          key={item.title}
          disablePadding
          sx={{
            px: 1,
          }}
        >
          <NavLink
            to={item.path}
            style={({ isActive }) => ({
              all: "inherit",
              backgroundColor: isActive
                ? `${theme.palette.primary.light}`
                : undefined,
              borderRadius: theme.shape.borderRadius,
            })}
          >
            <ListItemButton
              disableGutters
              disableRipple
              sx={{
                minHeight: 50,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  justifyContent: "center",
                }}
              >
                <item.icon
                  sx={{
                    color: "primary.contrastText",
                  }}
                />
              </ListItemIcon>
              {isOpen && (
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{
                    sx: {
                      ml: 2,
                      fontSize: 14,
                    },
                  }}
                />
              )}
            </ListItemButton>
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
};
