import React from "react";
import { PageTemplate } from "../../../common/PageTemplate";
import { OpenInNew, Wallet } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  Link,
} from "@mui/material";
import { PageHeader } from "../../../common/PageHeader/PageHeader";
import { WalletOnboardingTitleSubtitle } from "./WalletOnboardingTitleSubtitle";

interface WalletOnboardingProps {
  onPressActionButton: () => void;
  isActionButtonDisabled: boolean;
  refreshExpressAccount: () => void;
}

export const WalletOnboarding: React.FC<WalletOnboardingProps> = ({
  onPressActionButton,
  isActionButtonDisabled,
  refreshExpressAccount,
}) => {
  return (
    <PageTemplate>
      <PageHeader
        title="My Wallet"
        subtitle="You'll soon be able to view your balance and payouts here."
      />
      <Card variant="outlined" elevation={0}>
        <CardContent>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
            p={4}
            gap={1}
          >
            <Box
              borderRadius={3}
              bgcolor="primary.xlight"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={1}
              mb={2}
            >
              <Wallet color="primary" />
            </Box>
            <WalletOnboardingTitleSubtitle
              title="Wallet Onboarding"
              subtitle="Please set up your bank account information. You won't be able to receive payouts until we have this information."
            />
            <Box mb={2} mt={2}>
              <Button
                variant="contained"
                startIcon={<OpenInNew />}
                onClick={onPressActionButton}
                disabled={isActionButtonDisabled}
              >
                Complete Onboarding
              </Button>
            </Box>
            <Typography variant="caption" color="text.secondary" align="center">
              {`Already completed onboarding? Wait a few seconds then `}
              <Link
                underline="none"
                onClick={refreshExpressAccount}
                sx={{ cursor: "pointer" }}
              >
                click here
              </Link>
            </Typography>
            <Typography variant="caption" color="text.secondary" align="center">
              {`If you still can't get past this page, you might need to complete onboarding again.`}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </PageTemplate>
  );
};
