import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FavouriteWorkout } from "../../../../store/favourites/types";
import { Table } from "../../../common/Table";

interface Props {
  favourites: FavouriteWorkout[];
}

export const FavouritesTable: React.FC<Props> = ({ favourites }) => {
  const navigate = useNavigate();
  const navigateToWorkout = (id: string) => {
    navigate(`edit/${id}`);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="workouts table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {favourites.map(({ workout: row }) => (
            <TableRow
              key={row.id}
              onClick={() => navigateToWorkout(row.id)}
              hover
              sx={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.id}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
