import { Visibility, Favorite } from "@mui/icons-material";
import {
  Box,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Draggable, DraggableProps } from "react-beautiful-dnd";
import { Workout } from "../../../store/workout/types";
import { WorkoutViewDialog } from "./WorkoutViewerDialog";
import { PlanBuilderCardBase } from "./styles";

interface Props extends Omit<DraggableProps, "children"> {
  workout: Workout;
  index: number;
}

export const DraggableFavourite: React.FC<Props> = ({
  workout,
  index,
  ...props
}) => {
  const [isPeekingWorkout, setIsPeekingWorkout] = useState(false);
  return (
    <>
      <Draggable index={index} {...props}>
        {(props, snapshot) => (
          <>
            {snapshot.isDragging && (
              <PlanBuilderCardBase sx={{ opacity: 0.5 }}>
                <CardContent>{workout.name}</CardContent>
              </PlanBuilderCardBase>
            )}
            <PlanBuilderCardBase
              {...props.dragHandleProps}
              {...props.draggableProps}
              ref={props.innerRef}
            >
              <Box
                display="flex"
                justifyContent={"space-between"}
                p={1}
                pl={2}
                sx={{ position: "relative" }}
              >
                <Tooltip title={workout.name}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="80%"
                    justifyContent="center"
                  >
                    <Typography noWrap mb={0}>
                      {workout.name}
                    </Typography>
                  </Box>
                </Tooltip>

                <Box display="flex" flexDirection="column">
                  <Stack direction="row">
                    <Tooltip title="View workout">
                      <IconButton
                        aria-label="view workout"
                        size="small"
                        onClick={() => setIsPeekingWorkout(true)}
                      >
                        <Visibility fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
                {workout.isFavourite && (
                  <Favorite
                    fontSize={"small"}
                    sx={{
                      position: "absolute",
                      bottom: -30,
                      fontSize: 15,
                    }}
                    color="disabled"
                  />
                )}
              </Box>
            </PlanBuilderCardBase>
          </>
        )}
      </Draggable>
      <WorkoutViewDialog
        workout={workout}
        canEdit={false}
        disabledEditTooltipText="Favourites cannot be edited"
        close={() => setIsPeekingWorkout(false)}
        isOpen={isPeekingWorkout}
      />
    </>
  );
};
