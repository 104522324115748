import { useEffect, useState } from "react";

export const useErrorMessageState = (initalState: boolean) => {
  const [errorMessageState, setErrorMessageState] = useState(initalState);

  useEffect(() => {
    setErrorMessageState(initalState);
  }, [initalState]);

  const setErrorMessageStateToFalse = () => setErrorMessageState(false);
  const setErrorMessageStateToTrue = () => setErrorMessageState(true);

  return [
    errorMessageState,
    setErrorMessageStateToFalse,
    setErrorMessageStateToTrue,
  ] as const;
};
