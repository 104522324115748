import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSetDuplicate } from "../../components/pages/WorkoutBuilder/ExerciseEntryDataGrid/utils";
import {
  getInitialParameterValueByParameterName,
  REPS_EXERCISE_PARAMETER,
  TIME_EXERCISE_PARAMETER,
} from "../../components/pages/WorkoutBuilder/fixtures";
import {
  createSupersetBlock,
  generateExerciseBlockWithEntrys,
  getRestValuesFromEntry,
  removeRestValuesFromEntry,
} from "../../components/pages/WorkoutBuilder/utils";
import { removeNthElementFromArray } from "../../util/array";
import { CreatorExercise } from "../exercises/types";
import {
  ExerciseBlock,
  ExerciseParameter,
  ExerciseSet,
  ExpandedWorkout,
  WorkoutSection,
} from "../workout/types";
import {
  NoWorkoutBuilderSectionsError,
  NoWorkoutBuilderStateError,
} from "./errors";
import { defaultWorkoutBuilderState, WorkoutBuilderState } from "./types";
import { createRestValueFromListIndexOrDefault } from "./utils";

interface BlockIndex {
  blockIndex: number;
}
interface EntryIndex {
  entryIndex: number;
}

interface SetIndex {
  setIndex: number;
}

const getFirstSectionFromState = (
  state: WorkoutBuilderState
): WorkoutSection => {
  if (!state.workoutBuilder) {
    throw new NoWorkoutBuilderStateError();
  }
  if (!state.workoutBuilder.sections) {
    throw new NoWorkoutBuilderSectionsError();
  }
  const workoutBuilderData = state.workoutBuilder;
  const firstSection = workoutBuilderData.sections[0];
  return firstSection;
};

const workoutBuilderSlice = createSlice({
  name: "workoutBuilder",
  initialState: defaultWorkoutBuilderState,
  reducers: {
    initialiseEmptyWorkoutBuilder: (
      state,
      action: PayloadAction<{ initialExpandedWorkout: ExpandedWorkout }>
    ) => {
      state.workoutBuilder = { ...action.payload.initialExpandedWorkout };
    },
    appendExerciseBlock: (
      state,
      action: PayloadAction<{ block: ExerciseBlock; index?: number }>
    ) => {
      const { block, index } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const { blocks } = firstSection;

      const newBlocks =
        index !== undefined && index < blocks.length
          ? [...blocks.slice(0, index), block, ...blocks.slice(index)]
          : [...blocks, block];

      if (state.workoutBuilder) {
        state.workoutBuilder.sections = [
          { ...firstSection, blocks: newBlocks },
        ];
      }
    },
    removeExerciseEntry: (
      state,
      action: PayloadAction<BlockIndex & EntryIndex>
    ) => {
      const { blockIndex, entryIndex } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const exerciseBlocks = firstSection.blocks;

      const blockToModify = exerciseBlocks[blockIndex];
      const entryToRemove = blockToModify.entrys[entryIndex];

      const isFinalEntryInBlock =
        entryIndex === blockToModify.entrys.length - 1;

      const isBlockSuperset = blockToModify.entrys.length > 1;

      if (isFinalEntryInBlock && isBlockSuperset) {
        const restValues = getRestValuesFromEntry(entryToRemove);
        const createRestValueFromIndexOrDefault = (index: number) =>
          createRestValueFromListIndexOrDefault(index, restValues);

        blockToModify.entrys = (() => {
          const entrysWithRemoved = removeNthElementFromArray(
            blockToModify.entrys,
            entryIndex
          );
          const finalEntry = entrysWithRemoved.at(-1);
          if (finalEntry) {
            removeRestValuesFromEntry(finalEntry);
            finalEntry.sets.forEach((set, index) => {
              set.values.push(createRestValueFromIndexOrDefault(index));
            });
          }
          return entrysWithRemoved;
        })();
      } else if (isFinalEntryInBlock) {
        firstSection.blocks = removeNthElementFromArray(
          firstSection.blocks,
          blockIndex
        );
      } else {
        blockToModify.entrys = removeNthElementFromArray(
          blockToModify.entrys,
          entryIndex
        );
      }
    },
    appendSetToEntry: (
      state,
      action: PayloadAction<
        BlockIndex &
          EntryIndex & {
            newSet: ExerciseSet;
          }
      >
    ) => {
      const { blockIndex, entryIndex, newSet } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const selectedSets =
        firstSection.blocks[blockIndex].entrys[entryIndex].sets;
      state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
        entryIndex
      ].sets = [...selectedSets, newSet];
    },
    updateSetInEntry: (
      state,
      action: PayloadAction<
        BlockIndex &
          EntryIndex & {
            set: ExerciseSet;
          }
      >
    ) => {
      const { blockIndex, entryIndex, set: setToUpdate } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const selectedSets =
        firstSection.blocks[blockIndex].entrys[entryIndex].sets;
      const newSets = selectedSets.map((set) => {
        if (set.id === setToUpdate.id) {
          return setToUpdate;
        }
        return set;
      });
      state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
        entryIndex
      ].sets = newSets;
    },
    removeSetInEntry: (
      state,
      action: PayloadAction<
        BlockIndex &
          EntryIndex & {
            setIndex: number;
          }
      >
    ) => {
      const { blockIndex, entryIndex, setIndex } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const selectedSets =
        firstSection.blocks[blockIndex].entrys[entryIndex].sets;
      state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
        entryIndex
      ].sets = removeNthElementFromArray(selectedSets, setIndex);
    },
    setParameterValueAtBlockAndEntryIndex: (
      state,
      action: PayloadAction<
        BlockIndex &
          EntryIndex & {
            newParameter: ExerciseParameter;
          }
      >
    ) => {
      const { blockIndex, entryIndex, newParameter } = action.payload;
      const firstSection = getFirstSectionFromState(state);
      const selectedSets =
        firstSection.blocks[blockIndex].entrys[entryIndex].sets;
      const newSets = selectedSets.map((set) => ({
        id: set.id,
        values: set.values.map((value) => {
          if (
            value.parameter.name === REPS_EXERCISE_PARAMETER.name ||
            value.parameter.name === TIME_EXERCISE_PARAMETER.name
          ) {
            return {
              id: value.id,
              parameter: newParameter,
              value: getInitialParameterValueByParameterName(newParameter.name),
            };
          }
          return value;
        }),
      }));

      state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
        entryIndex
      ].sets = newSets;
    },
    getExercisesByQueryAttempt: (state) => {
      state.exercises.isLoading = true;
      state.exercises.isError = false;
    },
    getExercisesByQueryFailure: (state) => {
      state.exercises.isLoading = false;
      state.exercises.isError = true;
    },
    getExercisesByQuerySuccess: (
      state,
      action: PayloadAction<{
        results: CreatorExercise[];
        pageNumber: number;
      }>
    ) => {
      state.exercises.isLoading = false;
      state.exercises.isError = false;
      const newData = action.payload.results;
      state.exercises.data =
        action.payload.pageNumber === 1
          ? newData
          : [...(state.exercises.data || []), ...newData];
    },
    clearExerciseSearchResults: (state) => {
      state.exercises.data = null;
    },
    combineExerciseBlockWithNext: (state, action: PayloadAction<number>) => {
      const exerciseBlockIndex = action.payload;
      const blocks = state.workoutBuilder?.sections[0].blocks;

      if (blocks && exerciseBlockIndex < blocks.length - 1) {
        const currentBlock = blocks[exerciseBlockIndex];
        const nextBlock = blocks[exerciseBlockIndex + 1];

        const combinedEntryList = [...currentBlock.entrys, ...nextBlock.entrys];
        const combinedBlock = createSupersetBlock(combinedEntryList);

        const maxNumberOfSets = Math.max(
          ...combinedBlock.entrys.map((entry) => entry.sets.length)
        );
        combinedBlock.entrys.forEach((entry) => {
          const setsToAdd = maxNumberOfSets - entry.sets.length;
          if (setsToAdd > 0) {
            const lastSet = entry.sets[entry.sets.length - 1];
            for (let i = 0; i < setsToAdd; i++) {
              entry.sets.push(createSetDuplicate(lastSet));
            }
          }
        });

        const newBlocks = [...blocks];
        newBlocks.splice(exerciseBlockIndex, 2, combinedBlock);
        state.workoutBuilder!.sections[0].blocks = newBlocks;
      }
    },
    reorderExerciseBlock: (
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const blocks = state.workoutBuilder?.sections[0].blocks;

      if (
        blocks &&
        sourceIndex < blocks.length &&
        destinationIndex < blocks.length
      ) {
        const newBlocks = [...blocks];
        const [removedBlock] = newBlocks.splice(sourceIndex, 1);
        newBlocks.splice(destinationIndex, 0, removedBlock);
        state.workoutBuilder!.sections[0].blocks = newBlocks;
      }
    },
    reorderExerciseEntryInBlock: (
      state,
      action: PayloadAction<{
        blockId: string;
        sourceIndex: number;
        destinationIndex: number;
      }>
    ) => {
      const { blockId, sourceIndex, destinationIndex } = action.payload;
      const blocks = state.workoutBuilder?.sections[0].blocks;

      if (blocks) {
        const blockIndex = blocks.findIndex((block) => block.id === blockId);

        if (blockIndex !== -1) {
          const targetBlock = blocks[blockIndex];
          const numberOfEntrysInBlock = targetBlock.entrys.length;

          if (
            sourceIndex >= 0 &&
            sourceIndex < numberOfEntrysInBlock &&
            destinationIndex >= 0 &&
            destinationIndex < numberOfEntrysInBlock
          ) {
            const preReorderFinalEntry = targetBlock.entrys.at(-1);
            const restValues = preReorderFinalEntry
              ? removeRestValuesFromEntry(preReorderFinalEntry)
              : undefined;

            const targetEntry = targetBlock.entrys[sourceIndex];

            targetBlock.entrys.splice(sourceIndex, 1);
            targetBlock.entrys.splice(destinationIndex, 0, targetEntry);
            const postReorderFinalEntry = targetBlock.entrys.at(-1);

            const createRestValueFromIndexOrDefault = (index: number) =>
              createRestValueFromListIndexOrDefault(index, restValues ?? []);

            if (postReorderFinalEntry) {
              removeRestValuesFromEntry(postReorderFinalEntry);
              postReorderFinalEntry.sets.forEach((set, index) => {
                set.values.push(createRestValueFromIndexOrDefault(index));
              });
            }
          }
        }
      }
    },
    splitExerciseEntrysAtIndexForBlock: (
      state,
      action: PayloadAction<BlockIndex & EntryIndex>
    ) => {
      const { blockIndex, entryIndex } = action.payload;
      const blocks = state.workoutBuilder?.sections[0].blocks;

      if (blocks && blockIndex < blocks.length) {
        const targetBlock = blocks[blockIndex];
        const entryCount = targetBlock.entrys.length;
        const finalEntry = targetBlock.entrys.at(-1);

        const restValues = finalEntry
          ? getRestValuesFromEntry(finalEntry)
          : undefined;
        const createRestValueFromIndexOrDefault = (index: number) =>
          createRestValueFromListIndexOrDefault(index, restValues ?? []);

        if (entryIndex >= 0 && entryIndex < entryCount) {
          const splitIndex = entryIndex + 1;

          const firstEntryList = targetBlock.entrys.slice(0, splitIndex);
          const finalEntryInFirstEntryList = firstEntryList.at(-1);
          if (finalEntryInFirstEntryList) {
            removeRestValuesFromEntry(finalEntryInFirstEntryList);
            finalEntryInFirstEntryList.sets.forEach((set, index) => {
              set.values.push(createRestValueFromIndexOrDefault(index));
            });
          }
          const secondEntryList = targetBlock.entrys.slice(splitIndex);

          const firstBlock = generateExerciseBlockWithEntrys(firstEntryList);
          const secondBlock = generateExerciseBlockWithEntrys(secondEntryList);

          const newBlocks = [...blocks];
          newBlocks.splice(blockIndex, 1, firstBlock, secondBlock);

          state.workoutBuilder!.sections[0].blocks = newBlocks;
        }
      }
    },
    duplicateSetInAllExerciseEntrysInBlock: (
      state,
      action: PayloadAction<BlockIndex & SetIndex>
    ) => {
      const { blockIndex, setIndex } = action.payload;
      const block = state.workoutBuilder?.sections[0].blocks[blockIndex];

      if (block) {
        block.entrys.forEach((entry) => {
          const setDuplicate = createSetDuplicate(entry.sets[setIndex]);
          entry.sets.splice(setIndex + 1, 0, setDuplicate);
        });
      }
    },
    removeSetInAllExerciseEntrysInBlock: (
      state,
      action: PayloadAction<BlockIndex & SetIndex>
    ) => {
      const { blockIndex, setIndex } = action.payload;
      const block = state.workoutBuilder?.sections[0].blocks[blockIndex];

      if (block) {
        block.entrys.forEach((entry) => {
          if (entry.sets.length > setIndex) {
            entry.sets.splice(setIndex, 1);
          }
        });
      }
    },
    updateNotesInEntry: (
      state,
      action: PayloadAction<
        BlockIndex &
          EntryIndex & {
            notes: string;
          }
      >
    ) => {
      const { blockIndex, entryIndex, notes } = action.payload;
      const newEntry =
        state.workoutBuilder?.sections[0].blocks[blockIndex].entrys[entryIndex];
      if (newEntry) {
        newEntry.notes = notes;
        state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
          entryIndex
        ] = newEntry;
      }
    },
    updateVideoInExerciseEntry: (
      state,
      action: PayloadAction<
        BlockIndex & EntryIndex & { videoId: number | null }
      >
    ) => {
      const { blockIndex, entryIndex, videoId } = action.payload;
      state.workoutBuilder!.sections[0].blocks[blockIndex].entrys[
        entryIndex
      ].videoId = videoId;
    },
    reset: () => defaultWorkoutBuilderState,
  },
});

export const WorkoutBuilderReducer = workoutBuilderSlice.reducer;
export const workoutBuilderActions = workoutBuilderSlice.actions;
