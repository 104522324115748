import { CircularProgress, DialogActions, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useMemo, useRef, useState } from "react";
import { WorkoutPlanCheckComponentProps } from "./types";
import {
  DialogPrimaryButton,
  DialogSecondaryButton,
} from "../../../../common/Dialog/DialogButtons";

const MIN_NAME_LENGTH = 5;

export const CheckWorkoutPlanNameStep: React.FC<
  WorkoutPlanCheckComponentProps
> = ({
  workoutPlan,
  onClickPrimary,
  onClickSecondary,
  updateNewValue,
  isLoading,
  isError,
}) => {
  const textFieldInputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState(workoutPlan.name);
  const hasValueChanged = useMemo(
    () => value !== workoutPlan.name,
    [value, workoutPlan.name]
  );
  const getErrorMessage = (text: string) => {
    if (text.trim().length < MIN_NAME_LENGTH) {
      return "Your workout name must be at least 5 characters long";
    }
    return "";
  };
  const [errorMessage, setErrorMessage] = useState(
    getErrorMessage(workoutPlan.name)
  );
  return (
    <>
      <TextField
        inputRef={textFieldInputRef}
        id={"check-workout-plan-name"}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          setErrorMessage(getErrorMessage(event.target.value));
        }}
        fullWidth
        variant="outlined"
        margin="normal"
        autoFocus
        autoComplete="off"
        error={!!errorMessage}
        InputProps={{
          disabled: isLoading,
          endAdornment: isLoading && (
            <InputAdornment position="start">
              <CircularProgress size={"1.2em"} />
            </InputAdornment>
          ),
        }}
      />
      {!!errorMessage && (
        <Typography variant="body2" color="error.main" alignSelf={"baseline"}>
          {errorMessage}
        </Typography>
      )}
      <DialogActions sx={{ width: "100%" }}>
        <DialogSecondaryButton onClick={onClickSecondary}>
          {"Previous "}
        </DialogSecondaryButton>
        <DialogPrimaryButton
          variant="outlined"
          disabled={isLoading || !!errorMessage || isError}
          onClick={() => {
            const inputValue = textFieldInputRef.current?.value.trim();
            if (hasValueChanged) {
              inputValue && updateNewValue(inputValue).then(onClickPrimary);
            } else {
              onClickPrimary();
            }
          }}
        >
          {hasValueChanged ? (isLoading ? "Saving..." : "Save & Next") : "Next"}
        </DialogPrimaryButton>
      </DialogActions>
    </>
  );
};
