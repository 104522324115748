import React from "react";

import { Help } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { BalanceStatistic } from "./components/BalanceStatistic";
import { Payouts } from "./components/Payouts";
import { WalletLoading } from "./components/WalletLoading";
import { WalletOnboarding } from "./components/WalletOnboarding";
import { formatCurrencyFromBaseUnit } from "./components/utils";
import { useWallet } from "./useWallet";

interface Props extends ReturnType<typeof useWallet> {}

export const Wallet: React.FC<Props> = ({
  expressAccount,
  getOnboardingLink,
  isFetchingOnboardingLink,
  availableBalance,
  pendingBalance,
  payouts,
  isLoading,
  isErrorMessageOpen,
  closeErrorMessage,
  daysUntilPayday,
  payDate,
  getExpressAccount,
}) => {
  if (!expressAccount?.detailsSubmitted) {
    return (
      <WalletOnboarding
        onPressActionButton={getOnboardingLink}
        isActionButtonDisabled={isFetchingOnboardingLink}
        refreshExpressAccount={getExpressAccount}
      />
    );
  }

  return (
    <PageTemplate>
      <PageHeader
        title="My Wallet"
        subtitle="A breakdown of your earnings and payouts"
      />
      {isLoading ? (
        <WalletLoading />
      ) : (
        <Grid container spacing={2}>
          <>
            <Grid item xs={12}>
              <Typography variant="h5">
                Balance
                <Tooltip
                  title={
                    "The estimated breakdown amount of money that in your wallet."
                  }
                >
                  <IconButton size="small">
                    <Help color="action" fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>
            {availableBalance && (
              <Grid item xs={4}>
                <BalanceStatistic
                  title="Available Wallet Balance"
                  content={
                    <Typography
                      textAlign={"center"}
                      variant="h5"
                      fontWeight={800}
                      color={"primary"}
                    >
                      {formatCurrencyFromBaseUnit(
                        availableBalance.amount,
                        availableBalance.currency
                      )}
                    </Typography>
                  }
                  extraInfo="Funds accumulated this month for your upcoming payout."
                />
              </Grid>
            )}
            {pendingBalance && (
              <Grid item xs={4}>
                <BalanceStatistic
                  title="Pending Wallet Balance"
                  content={
                    <Typography
                      textAlign={"center"}
                      variant="h5"
                      fontWeight={800}
                    >
                      {formatCurrencyFromBaseUnit(
                        pendingBalance.amount,
                        pendingBalance.currency
                      )}
                    </Typography>
                  }
                  extraInfo="Subscription earnings on their way to your wallet."
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <BalanceStatistic
                title="Payout Date"
                content={
                  <Tooltip
                    title={`Your next payday is on ${payDate.toDateString()}.`}
                  >
                    <Typography
                      textAlign={"center"}
                      variant="h5"
                      fontWeight={800}
                    >
                      {daysUntilPayday}
                    </Typography>
                  </Tooltip>
                }
                extraInfo="The date we initiate the transfer. It usually takes up to 3 business days to settle."
              />
            </Grid>
          </>
          <Payouts payouts={payouts || []} />
        </Grid>
      )}
      <SnackbarAlert
        isOpen={isErrorMessageOpen}
        close={closeErrorMessage}
        severity={"error"}
        message="Oops! Something went wrong when refreshing your wallet! Please try again later."
      />
    </PageTemplate>
  );
};
