import { Videocam, VideocamOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";

interface VideoIconButtonProps {
  onClick?: () => void;
}

export const SuccessVideoIconButton: React.FC<VideoIconButtonProps> = ({
  onClick,
}) => {
  return (
    <Tooltip title={"Click to see video"}>
      <IconButton onClick={onClick}>
        <Videocam color="success" />
      </IconButton>
    </Tooltip>
  );
};

export const ErrorVideoIconButton: React.FC<VideoIconButtonProps> = ({
  onClick,
}) => {
  return (
    <Tooltip title={"This exercise does not have a video"}>
      <IconButton onClick={onClick}>
        <VideocamOff color="error" />
      </IconButton>
    </Tooltip>
  );
};
