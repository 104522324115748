import React from "react";
import { Button, ButtonProps } from "@mui/material";

export const DialogSecondaryButton: React.FC<ButtonProps> = ({
  onClick,
  ...customButtonProps
}) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color="neutral"
      {...customButtonProps}
    />
  );
};

export const DialogPrimaryButton: React.FC<ButtonProps> = ({
  onClick,
  ...customButtonProps
}) => {
  return (
    <Button onClick={onClick} variant="contained" {...customButtonProps} />
  );
};
