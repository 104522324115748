import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { Select } from "../../../common/Select/Select";
import { SignupLineChart } from "../Charts/SignupLineChart";
import { ReloadableChartCard } from "../ReloadableChartCard/ReloadableChartCard";
import { SubscriptionStatusBreakdown } from "../components/SubscriptionStatusBreakdown";
import { useDashboard } from "./useDashboard";

export interface DashboardProps extends ReturnType<typeof useDashboard> {}

export const Dashboard: React.FC<DashboardProps> = ({
  timeframe,
  handleChangeSignupTimeframe,
  refreshSignups,
  userSignupsResource,
  signupDataLineSerie,
  totalUserSignupsInTimeframe,
  formattedTimeframeOptions,
  refreshSubStatusBreakdown,
  subscriptionStatusBreakdownPieChartData,
  subscriptionStatusBreakdownResource,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8} height={"50vh"}>
          <ReloadableChartCard
            title="Sign ups"
            subheading={`Total fan sign ups per day`}
            tooltipText="Use this to figure out which posts work best for you"
            refreshData={refreshSignups}
            isLoading={userSignupsResource.isLoading}
            isError={userSignupsResource.isError}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Select
                  value={timeframe.toString()}
                  label="Timeframe"
                  onChange={handleChangeSignupTimeframe}
                  options={formattedTimeframeOptions}
                />
              </Box>
              <Card
                elevation={0}
                variant="outlined"
                sx={{ p: 1, px: 2, alignItems: "center", borderRadius: 1 }}
              >
                <Typography variant="body1">
                  {totalUserSignupsInTimeframe} total
                </Typography>
              </Card>
            </Box>
            {signupDataLineSerie && (
              <SignupLineChart data={[signupDataLineSerie]} />
            )}
          </ReloadableChartCard>
        </Grid>
        <Grid item xs={4} height={"50vh"}>
          <ReloadableChartCard
            title="Fan breakdown"
            subheading="By subscription status"
            tooltipText="Trialling users will convert to active once their trial finishes"
            refreshData={() => refreshSubStatusBreakdown()}
            isLoading={subscriptionStatusBreakdownResource.isLoading}
            isError={subscriptionStatusBreakdownResource.isError}
          >
            {subscriptionStatusBreakdownPieChartData && (
              <SubscriptionStatusBreakdown
                data={subscriptionStatusBreakdownPieChartData}
              />
            )}
          </ReloadableChartCard>
        </Grid>
      </Grid>
    </>
  );
};
