import { Skeleton, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

export const PlaylistCardLoading: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="h5">
        <Skeleton variant="text" width={"40%"} />
      </Typography>
      <Typography variant="subtitle2">
        <Skeleton variant="text" width="95%" />
      </Typography>
      <Skeleton
        variant="rectangular"
        width="100%"
        sx={{ paddingBottom: "100%" }}
      />

      <Typography>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width={`69%`} />
      </Typography>
    </Box>
  );
};
