import { Box } from "@mui/material";
import React from "react";
import { TransformationPicture } from "../../../types";
import { TransformationImage } from "./TransformationImage";

interface TransformationImageAngleContainerProps {
  selectedAngleBeforePicture: TransformationPicture;
  selectedAngleAfterPicture?: TransformationPicture | null;
  hasPublicSharingConsent: boolean;
  downloadImage: (imageUrl: string) => Promise<void>;
}

export const TransformationImageAngleContainer: React.FC<
  TransformationImageAngleContainerProps
> = ({
  selectedAngleBeforePicture,
  selectedAngleAfterPicture,
  downloadImage,
  hasPublicSharingConsent,
}) => {
  return (
    <Box
      sx={{
        gap: 0,
        marginBottom: 2,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <TransformationImage
        downloadImage={downloadImage}
        hasPublicSharingConsent={hasPublicSharingConsent}
        {...selectedAngleBeforePicture}
      />
      <TransformationImage
        downloadImage={downloadImage}
        hasPublicSharingConsent={hasPublicSharingConsent}
        {...selectedAngleAfterPicture}
      />
    </Box>
  );
};
