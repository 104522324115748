import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { PaginatedAPI } from "../types";
import { transformationsActions } from "./slice";
import {
  transformChallengePlansApi,
  transformChallengePlanTransformationsFromAPI,
} from "./transformer";
import { ChallengePlansApi, ChallengePlanTransformationsApi } from "./types";

export const GetChallengePlans = async (dispatch: DispatchFn) => {
  dispatch(transformationsActions.getChallengePlansAttempt());
  try {
    const response = await client.get<ChallengePlansApi>(
      "builder/challenge-plans/"
    );
    const challengePlans = transformChallengePlansApi(response.data);
    dispatch(transformationsActions.getChallengePlansSuccess(challengePlans));
  } catch {
    dispatch(transformationsActions.getChallengePlansFailure());
  }
};

export const GetChallengePlanTransformations = async (
  dispatch: DispatchFn,
  pageNumber: number,
  playlist: string,
  is_completed?: boolean,
  has_public_sharing_consent?: boolean
) => {
  dispatch(transformationsActions.getChallengePlanTransformationsAttempt());
  try {
    const response = await client.get<
      PaginatedAPI<ChallengePlanTransformationsApi>
    >("/history/challenge-plan-transformations/", {
      params: {
        page: pageNumber,
        playlist,
        is_completed,
        has_public_sharing_consent,
      },
    });
    const challengePlanTransformations =
      transformChallengePlanTransformationsFromAPI(response.data.results);
    dispatch(
      transformationsActions.setChallengePlanTransformationsCount({
        count: response.data.count,
      })
    );
    dispatch(
      transformationsActions.getChallengePlanTransformationsSuccess({
        pageNumber,
        challengePlanTransformations,
      })
    );
  } catch {
    dispatch(transformationsActions.getChallengePlanTransformationsFailure());
  }
};

export const clearChallengePlanTransformations = (dispatch: DispatchFn) => {
  dispatch(transformationsActions.clearChallengePlanTransformations());
};
