import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultFavouritesState, FavouriteWorkout } from "./types";

const favouritesSlice = createSlice({
  name: "favourites",
  initialState: defaultFavouritesState,
  reducers: {
    getFavouriteWorkoutsAttempt: (state) => {
      state.favourites.isLoading = true;
      state.favourites.isError = false;
    },
    getFavouriteWorkoutsFailure: (state) => {
      state.favourites.isLoading = false;
      state.favourites.isError = true;
    },
    getFavouriteWorkoutsSuccess: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        favourites: FavouriteWorkout[];
      }>
    ) => {
      state.favourites.isError = false;
      state.favourites.isLoading = false;
      if (state.favourites.data) {
        state.favourites.data[action.payload.pageNumber] =
          action.payload.favourites;
      } else {
        state.favourites.data = {
          [action.payload.pageNumber]: action.payload.favourites,
        };
      }
    },
    setFavouriteWorkoutsCount: (state, action: PayloadAction<number>) => {
      state.favourites.count = action.payload;
    },
    getAllFavouriteWorkoutsAttempt: (state) => {
      state.allFavourites.isLoading = true;
      state.allFavourites.isError = false;
    },
    getAllFavouriteWorkoutsSuccess: (
      state,
      action: PayloadAction<{ favourites: FavouriteWorkout[] }>
    ) => {
      state.allFavourites.isLoading = false;
      state.allFavourites.data = action.payload.favourites;
    },
    getAllFavouriteWorkoutsFailure: (state) => {
      state.allFavourites.isLoading = false;
      state.allFavourites.isError = true;
    },
    reset: () => defaultFavouritesState,
  },
});

export const FavouritesReducer = favouritesSlice.reducer;
export const favouritesActions = favouritesSlice.actions;
