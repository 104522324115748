import { RootStore } from "..";

export const selectChallengePlans = (state: RootStore) => {
  return state.transformationsState.challengePlans;
};

export const selectChallengePlanTransformationsPaginatedResource = (
  state: RootStore
) => {
  return state.transformationsState.challengePlanTransformations;
};
