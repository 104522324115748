import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from "@mui/material";

export interface ToggleOption<T> {
  value: NonNullable<T>;
  label: React.ReactNode;
}

export interface ToggleProps<T> extends ToggleButtonGroupProps {
  value: T | null;
  onChange: (event: React.MouseEvent<HTMLElement>, newValue: T | null) => void;
  options: ToggleOption<T>[];
  toggleButtonProps?: Omit<ToggleButtonProps, "value" | "onChange">;
}

export const Toggle = <T,>({
  value,
  onChange,
  options,
  toggleButtonProps,
  ...props
}: ToggleProps<T>) => {
  return (
    <ToggleButtonGroup
      {...props}
      value={value}
      onChange={(event, newValue) => {
        value && onChange(event, newValue);
      }}
      exclusive
      color="primary"
    >
      {options.map((option) => (
        <ToggleButton
          key={JSON.stringify(option.value)}
          value={option.value}
          {...toggleButtonProps}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
