import {
  BalanceAvailability,
  BalanceAvailabilityAPI,
  ExpressAccount,
  ExpressAccountAPI,
  ExpressAccountBalance,
  ExpressAccountBalanceAPI,
  ExpressAccountPayout,
  ExpressAccountPayoutAPI,
  PayoutInformation,
  PayoutInformationAPI,
} from "./types";

export const transformExpressAccountFromAPI = (
  api: ExpressAccountAPI
): ExpressAccount => {
  return {
    id: api.id,
    chargesEnabled: api.charges_enabled,
    countryCode: api.country,
    detailsSubmitted: api.details_submitted,
    stripeAccountID: api.stripe_account_id,
    trainerProfileID: api.trainer_profile_id,
    transfersEnabled: api.transfers_enabled,
  };
};

export const transformBalanceAvailablilityFromAPI = (
  api: BalanceAvailabilityAPI
): BalanceAvailability => ({
  amount: api.amount,
  currency: api.currency,
});

export const transformExpressAccountBalanceFromAPI = (
  api: ExpressAccountBalanceAPI
): ExpressAccountBalance => ({
  available: api.available.map(transformBalanceAvailablilityFromAPI),
  pending: api.pending.map(transformBalanceAvailablilityFromAPI),
});

export const transformExpressAccountPayoutFromAPI = (
  api: ExpressAccountPayoutAPI
): ExpressAccountPayout => ({
  id: api.id,
  amount: api.amount,
  arrivalDate: api.arrival_date,
  status: api.status,
  currency: api.currency,
});

export const transformPayoutInformationFromAPI = (
  api: PayoutInformationAPI
): PayoutInformation => ({
  balance: transformExpressAccountBalanceFromAPI(api.balance),
  payouts: api.payouts.map(transformExpressAccountPayoutFromAPI),
});
