import { CheckRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

interface PointDescriptionProps {
  text: string;
}
export const PointDescription: React.FC<PointDescriptionProps> = ({ text }) => {
  return (
    <Box display={"flex"} flexDirection={"row"}>
      {<CheckRounded fontSize={"small"} />}
      <Typography variant="body2" color={"text.secondary"} sx={{ ml: 1 }}>
        {text}
      </Typography>
    </Box>
  );
};
