import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { PageHeaderSkeleton } from "../../common/PageHeader/PageHeaderSkeleton";
import { PageTemplate } from "../../common/PageTemplate";

export const CreatorExerciseLoading: React.FC = () => {
  return (
    <PageTemplate>
      <PageHeaderSkeleton />
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 5fr", gap: 2 }}>
        <Typography>
          <Skeleton variant="text" />
        </Typography>
        <Box display={"flex"} flexDirection="column" gap={2}>
          <Typography>
            <Skeleton variant="text" />
          </Typography>
          <Skeleton variant="rounded" height={400} />
        </Box>
        <Typography>
          <Skeleton variant="text" />
        </Typography>
        <Box display={"flex"} flexDirection="column">
          <Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" width={`69%`} />
          </Typography>
        </Box>
      </Box>
    </PageTemplate>
  );
};
