import React from "react";
import { BadgeAnchor, BadgeImage } from "./StoreBadges.styles";

export const AppleAppStoreBadge: React.FC = () => {
  return (
    <BadgeAnchor href="https://apps.apple.com/gb/app/myarc-fitness/id1547700023?itsct=apps_box_badge&amp;itscg=30200">
      <BadgeImage src="/app-store-badge/app-store.svg" />
    </BadgeAnchor>
  );
};
