import { Add } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";

interface Props {
  onClick?: () => void;
}
export const CopyGridActionsCellItem: React.FC<Props> = ({ onClick }) => (
  <GridActionsCellItem
    icon={
      <Tooltip title="Duplicate set">
        <Add color="action" />
      </Tooltip>
    }
    label="Copy"
    className="textPrimary"
    onClick={onClick}
  />
);
