import React from "react";
import { usePublishPlanCheckModal } from "./usePublishPlanCheckModal";
import { PublishPlanCheckModal as Component } from "./PublishPlanCheckModal";
import { BaseConfirmDialogProps } from "../../../common/Dialog/types";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";

export interface PublishPlanCheckModalProps
  extends Pick<BaseConfirmDialogProps, "isOpen" | "onClose"> {
  workoutPlan: BaseWorkoutPlan;
  refreshWorkoutPlans: () => void;
}
export const PublishPlanCheckModal: React.FC<PublishPlanCheckModalProps> = (
  props
) => {
  const componentProps = usePublishPlanCheckModal(props);
  return <Component {...props} {...componentProps} />;
};
