import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
    muscleGroups: Record<string, string>;
    search: Record<string, string>;
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    muscleGroups: Record<string, string>;
    search: Record<string, string>;
  }

  interface SimplePaletteColorOptions {
    hover?: string;
    mid?: string;
    xlight?: string;
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

// Update the LinearProgress's color prop options
declare module "@mui/material/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    neutral: true;
  }
}

// Update the CircularProgress's color prop options
declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    neutral: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: "light",
    contrastThreshold: 3,
    background: {
      default: "#f7f8f9",
    },
    primary: {
      main: "#6344F5",
      hover: "#F2EFFF",
      mid: "#D4C2FF",
      xlight: "#f8f5ff",
    },
    secondary: {
      main: "#00eeaa",
    },
    error: {
      main: "#ff8585",
      contrastText: "#fff",
    },
    info: {
      main: "#6699ff",
    },
    success: {
      main: "#09bc8a",
      contrastText: "#fff",
    },
    text: {
      primary: "#101828",
      secondary: "#667085",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    muscleGroups: {
      Quadriceps: "#ee0e47",
      Hamstrings: "#ff6961",
      Calves: "#f09a9c",
      Chest: "#6600cc",
      Back: "#f8733d",
      Shoulders: "#66e863",
      Triceps: "#3333a2",
      Biceps: "#007ed6",
      Forearms: "#93dffd",
      Trapezius: "#19792d",
      Abs: "#f7b42c",
      Glutes: "#e65fae",
    },
    search: {
      background: "#ECE6F0",
      hover: "#D8C8E9",
      icon: "#49454F",
      text: "#1D1B20",
      border: "#79747E",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
  },
  spacing: 8,
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {
        raised: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus-visible": {
            borderWidth: 2,
            borderStyle: "solid",
          },
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        siblingCount: 8,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "unset",
          "&:focus-visible": {
            borderWidth: 2,
            borderStyle: "solid",
          },
        },
      },
    },
  },
});
