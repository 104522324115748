import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { GridEditInputCell, GridEditInputCellProps } from "@mui/x-data-grid";
import React from "react";

interface TooltipEditCellProps extends Omit<GridEditInputCellProps, "error"> {
  error?: string[];
}

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const TooltipEditCell: React.FC<TooltipEditCellProps> = (props) => {
  const errorMessage = props.error || [];
  return (
    <>
      <ErrorTooltip
        open={!!(errorMessage as any[]).length}
        title={
          <Box>
            {(errorMessage as never as string[])?.map((x) => {
              return (
                <Typography fontSize={"x-small"} key={x}>
                  {"• "}
                  {x}
                </Typography>
              );
            })}
          </Box>
        }
      >
        <span>
          <GridEditInputCell {...props} error={!!props.error?.length} />
        </span>
      </ErrorTooltip>
    </>
  );
};

export const renderTooltipEditCell = (params: TooltipEditCellProps) => {
  return <TooltipEditCell {...params} />;
};
