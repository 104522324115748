import {
  ChallengePlanEntry,
  ChallengePlanTransformation,
} from "../../../store/transformations/types";
import {
  formatLongDateWithoutWeekday,
  getDateDifferenceInWholeDays,
} from "../../../util/date";
import {
  TransformationCardProps,
  TransformationPicAngle,
  TransformationType,
} from "./types";

const calculateWorkoutsCompletedPercentage = (
  workoutsCompleted: number,
  totalWorkouts: number
) => {
  if (!totalWorkouts) return 0;
  return Math.round((workoutsCompleted / totalWorkouts) * 100);
};

const getProgressPictureFromEntry = (
  transformationPicAngle: TransformationPicAngle,
  challengePlanEntry: ChallengePlanEntry
) => {
  switch (transformationPicAngle) {
    case TransformationPicAngle.FRONT:
      return challengePlanEntry.front;
    case TransformationPicAngle.BACK:
      return challengePlanEntry.back;
    case TransformationPicAngle.SIDE:
      return challengePlanEntry.side;
  }
};

const createEntry = (
  transformationPicAngle: TransformationPicAngle,
  beforeEntry: ChallengePlanEntry,
  afterEntry: ChallengePlanEntry | null
) => {
  const beforeEntryProgressPicture = getProgressPictureFromEntry(
    transformationPicAngle,
    beforeEntry
  );
  const afterEntryProgressPicture = afterEntry
    ? getProgressPictureFromEntry(transformationPicAngle, afterEntry)
    : null;
  return {
    [TransformationType.BEFORE]: {
      image: beforeEntryProgressPicture.media.url,
      submittedAt: formatLongDateWithoutWeekday(
        new Date(beforeEntry.submittedAt)
      ),
    },
    [TransformationType.AFTER]:
      afterEntry && afterEntryProgressPicture
        ? {
            image: afterEntryProgressPicture.media.url,
            submittedAt: formatLongDateWithoutWeekday(
              new Date(afterEntry.submittedAt)
            ),
          }
        : null,
  };
};

export const mapTransformationToCardData = (
  transformation: ChallengePlanTransformation
): TransformationCardProps => {
  const { afterEntry, beforeEntry, hasPublicSharingConsent, runThrough, user } =
    transformation;
  const { playlist } = runThrough;

  return {
    transformationAngles: {
      [TransformationPicAngle.FRONT]: createEntry(
        TransformationPicAngle.FRONT,
        beforeEntry,
        afterEntry
      ),
      [TransformationPicAngle.BACK]: createEntry(
        TransformationPicAngle.BACK,
        beforeEntry,
        afterEntry
      ),
      [TransformationPicAngle.SIDE]: createEntry(
        TransformationPicAngle.SIDE,
        beforeEntry,
        afterEntry
      ),
    },
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    workoutPlanName: playlist.name,
    totalWorkouts: playlist.numberOfWorkouts,
    workoutsCompleted: runThrough.numberOfCompletedWorkoutDays,
    workoutsCompletedPercentage: calculateWorkoutsCompletedPercentage(
      runThrough.numberOfCompletedWorkoutDays,
      playlist.numberOfWorkouts
    ),
    transformationTime: afterEntry?.submittedAt
      ? getDateDifferenceInWholeDays(
          new Date(beforeEntry.submittedAt),
          new Date(afterEntry?.submittedAt)
        )
      : null,
    hasPublicSharingConsent,
  };
};
