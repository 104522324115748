import axios, { AxiosError } from "axios";
import { getAccessTokenFromCookie, refreshAccessToken } from "./jwt";

export const setBearerToken = (token: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const API_URL = `${process.env.REACT_APP_BASE_URL}/api/v3`;

const accessToken = getAccessTokenFromCookie();

const client = axios.create({
  baseURL: API_URL,
  responseType: "json",
});

accessToken && setBearerToken(accessToken);

let isRefreshingAccessToken = false;
let refreshAccessTokenPromise: Promise<any> | null = null;

client.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401 && !isRefreshingAccessToken) {
      isRefreshingAccessToken = true;
      refreshAccessTokenPromise = refreshAccessToken()
        .then((accessToken) => {
          if (accessToken) {
            client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return client(error.config!);
          }
        })
        .finally(() => {
          isRefreshingAccessToken = false;
          refreshAccessTokenPromise = null;
        });
      return refreshAccessTokenPromise;
    }
    return Promise.reject(error);
  }
);

export default client;
