import Chip, { ChipProps } from "@mui/material/Chip";
import React from "react";

interface Props extends Omit<ChipProps, "label"> {
  muscleGroup: string;
}

export const MuscleGroupChip: React.FC<Props> = ({ muscleGroup, ...props }) => {
  return (
    <Chip
      label={muscleGroup}
      sx={({ palette }) => {
        const muscleGroupColor = palette.muscleGroups[muscleGroup] || undefined;
        return {
          bgcolor: muscleGroupColor || "inherit",
          color: muscleGroupColor
            ? palette.getContrastText(muscleGroupColor)
            : "inherit",
        };
      }}
      {...props}
    />
  );
};
