export class NoWorkoutBuilderStateError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NoWorkoutBuilderStateError";
  }
}

export class NoWorkoutBuilderSectionsError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "NoWorkoutBuilderSectionsError";
  }
}
