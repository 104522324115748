import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { YouTubeEmbed } from "../../../common/YoutubeEmbed";
import { CreatorVideo } from "../../../../store/exercises/types";
import { MuxPlayer } from "../../../common/MuxPlayer";

interface ReadOnlyCreatorVideoDialogProps {
  isVisible: boolean;
  closeModal: () => void;
  name: string;
  video: CreatorVideo;
}

export const ReadOnlyCreatorVideoDialog: React.FC<
  ReadOnlyCreatorVideoDialogProps
> = ({ isVisible, closeModal, name, video }) => {
  return (
    <Dialog open={isVisible} fullWidth maxWidth="md">
      <DialogContent sx={{ p: 3 }}>
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography variant="h5" fontWeight={800}>
            {name}
          </Typography>

          <IconButton onClick={closeModal} disabled={false}>
            <Close color="action" />
          </IconButton>
        </Stack>
        <Typography variant="caption" fontWeight={800} sx={{ mb: 2 }}>
          {video.muxVideo?.title || video.youtubeLink}
        </Typography>
        {video.muxVideo && (
          <MuxPlayer
            playbackId={video.muxVideo.playbackId}
            tokens={{
              playback: video.muxVideo.videoToken,
              thumbnail: video.muxVideo.thumbnailToken,
            }}
            metadata={{ videoTitle: video.muxVideo.title }}
          />
        )}
        {!video.muxVideo && video.youtubeLink && (
          <YouTubeEmbed videoLink={video.youtubeLink} />
        )}
      </DialogContent>
    </Dialog>
  );
};
