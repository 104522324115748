import { useEffect } from "react";

export const useDebouncedAction = (
  callback: () => void,
  timeoutInMs: number,
  deps: React.DependencyList
) => {
  useEffect(() => {
    const debouncedAction = setTimeout(() => {
      callback();
    }, timeoutInMs);
    return () => clearTimeout(debouncedAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutInMs, callback, ...deps]);
};
