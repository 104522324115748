import { useEffect } from "react";

export const useExternalScript = (script: string) => {
  useEffect(() => {
    const head = document.querySelector("head");
    if (head) {
      const scriptTag = document.createElement("script");

      scriptTag.innerHTML = script;
      head.appendChild(scriptTag);

      return () => {
        head.removeChild(scriptTag);
      };
    }
  }, [script]);
};
