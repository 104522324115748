import {
  Box,
  Card,
  CardMedia,
  CardMediaProps,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BaseText } from "../BaseText";
import { extractVideoIdFromUrl } from "./YouTubeEmbed.utils";

interface YouTubeEmbedProps extends CardMediaProps<"iframe"> {
  videoLink: string;
}

const LANDSCAPE_YOUTUBE_VIDEO_ASPECT_RATIO = 16 / 9;

const HELP_TEXT_TIMEOUT = 2000;

export const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({
  videoLink,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoId = extractVideoIdFromUrl(videoLink.trim());
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowText(true), HELP_TEXT_TIMEOUT);

    return () => {
      setShowText(false);
    };
  }, [setShowText]);

  return (
    <Box justifyContent="center" alignItems="center" display="flex">
      <CardMedia
        component={"iframe"}
        style={{
          aspectRatio: LANDSCAPE_YOUTUBE_VIDEO_ASPECT_RATIO,
          border: "none",
        }}
        onLoad={() => {
          setIsLoading(false);
        }}
        src={`https://www.youtube.com/embed/${videoId}?wmode=opaque`}
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowFullScreen
        title="Embedded youtube"
        sx={{
          borderRadius: 2,
        }}
        {...props}
      />
      {isLoading && (
        <Card
          style={{
            aspectRatio: LANDSCAPE_YOUTUBE_VIDEO_ASPECT_RATIO,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            backgroundColor: showText ? undefined : "transparent",
          }}
          sx={{
            gap: 2,
            p: 5,
          }}
        >
          <CircularProgress />
          {showText && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <BaseText>Loading your video...</BaseText>
              <BaseText>
                If this takes too long, try refreshing the page
              </BaseText>
            </Box>
          )}
        </Card>
      )}
    </Box>
  );
};
