import { Construction } from "@mui/icons-material";
import React from "react";
import { EmptyList } from "../../common/EmptyList";

interface Props {
  onClickCreate: () => void;
}

export const WorkoutPlanEmptySchedule: React.FC<Props> = ({
  onClickCreate,
}) => {
  return (
    <EmptyList
      buttonText="Add workouts"
      icon={<Construction />}
      onClickButton={onClickCreate}
      primaryText="No workouts"
      secondaryText="Looks like you haven't started building this workout plan yet! Let's get started!"
    />
  );
};
