import React from "react";
import { theme } from "./theme";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider";

interface Props extends Omit<ThemeProviderProps, "theme"> {}

export const ThemeProvider: React.FC<Props> = (props) => {
  return <MuiThemeProvider theme={theme} {...props} />;
};
