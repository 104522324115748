import { AxiosResponse } from "axios";
import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { PaginatedAPI } from "../types";
import { exerciseActions } from "./slice";
import {
  transformAllCreatorExercisesFromAPI,
  transformCreatorExerciseFromAPI,
} from "./transformer";
import { CreatorExercise, CreatorExerciseAPI } from "./types";

export const GetExercises = async (
  dispatch: DispatchFn,
  pageNumber: number,
  limit: number
) => {
  dispatch(exerciseActions.getExercisesAttempt());
  try {
    const response = await client.get<PaginatedAPI<CreatorExerciseAPI>>(
      "/builder/exercise",
      {
        params: { page: pageNumber, limit },
      }
    );
    const exercises = transformAllCreatorExercisesFromAPI(
      response.data.results
    );
    dispatch(exerciseActions.setExercisesCount({ count: response.data.count }));
    dispatch(exerciseActions.getExercisesSuccess({ pageNumber, exercises }));
  } catch {
    dispatch(exerciseActions.getExercisesFailure());
  }
};

export const GetCreatorExercise = async (
  dispatch: DispatchFn,
  creatorExerciseId: number
) => {
  dispatch(exerciseActions.getCreatorExerciseAttempt());
  try {
    const response = await client.get<CreatorExerciseAPI>(
      `/builder/exercise/${creatorExerciseId}`
    );
    const creatorExercise = transformCreatorExerciseFromAPI(response.data);
    dispatch(exerciseActions.getCreatorExerciseSuccess({ creatorExercise }));
  } catch {
    dispatch(exerciseActions.getCreatorExerciseFailure());
  }
};

export const UpdateCreatorExerciseNote = async (
  dispatch: DispatchFn,
  creatorExerciseId: number,
  instruction: string
) => {
  dispatch(exerciseActions.updateCreatorExerciseNoteAttempt());
  try {
    const response = await client.patch<
      CreatorExerciseAPI,
      AxiosResponse<CreatorExerciseAPI>,
      Partial<CreatorExerciseAPI>
    >(`/builder/exercise/${creatorExerciseId}/`, {
      default_note: instruction,
    });
    const exercise = transformCreatorExerciseFromAPI(response.data);
    dispatch(exerciseActions.updateCreatorExerciseNoteSuccess({ instruction }));
    dispatch(exerciseActions.clearExercisesData());

    return exercise;
  } catch {
    dispatch(exerciseActions.updateCreatorExerciseNoteFailure());
  }
};

export const CreateExerciseVideo = async (
  dispatch: DispatchFn,
  exerciseId: number,
  youtubeLink: string
) => {
  try {
    dispatch(exerciseActions.createExerciseVideoAttempt());
    await client.post(
      "/builder/exercise/video/",
      {
        youtube_link: youtubeLink,
      },
      { params: { exercise_id: exerciseId } }
    );
    dispatch(exerciseActions.createExerciseVideoSuccess());
  } catch {
    dispatch(exerciseActions.createExerciseVideoFailure());
  }
};

export const DeleteExerciseVideo = async (
  dispatch: DispatchFn,
  videoId: number
) => {
  try {
    dispatch(exerciseActions.deleteExerciseVideoAttempt());
    await client.delete(`/builder/exercise/video/${videoId}`);
    dispatch(exerciseActions.deleteExerciseVideoSuccess());
  } catch {
    dispatch(exerciseActions.deleteExerciseVideoFailure());
  }
};

export const SetCurrentCreatorExercise = (
  dispatch: DispatchFn,
  creatorExercise: CreatorExercise
) => {
  dispatch(exerciseActions.setCurrentCreatorExercise({ creatorExercise }));
};
