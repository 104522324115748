import { Info, Refresh } from "@mui/icons-material";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

export interface ReloadableChartCardProps {
  title: string;
  subheading: string;
  tooltipText: string;
  refreshData: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

export const ReloadableChartCard: React.FC<
  React.PropsWithChildren<ReloadableChartCardProps>
> = ({
  title,
  subheading,
  tooltipText,
  refreshData,
  isLoading,
  isError,
  children,
}) => {
  return (
    <Card
      elevation={1}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "stretch",
        p: 2,
        opacity: isLoading ? 0.5 : undefined,
        pointerEvents: isLoading ? "none" : undefined,
      }}
    >
      <CardHeader
        sx={{ p: 0 }}
        title={
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Typography>{title}</Typography>
            <Tooltip title={tooltipText} placement="top">
              <Info color="disabled" fontSize="small" />
            </Tooltip>
          </Box>
        }
        subheader={subheading}
        action={
          isLoading ? (
            <CircularProgress
              size="1.5em"
              thickness={5}
              sx={{ color: "primary.mid" }}
            />
          ) : (
            <IconButton size="small" onClick={refreshData}>
              <Refresh color="disabled" fontSize="medium" />
            </IconButton>
          )
        }
      />
      <CardContent sx={{ p: 1, height: "85%", overflow: "scroll" }}>
        {isError && (
          <Alert severity="error">
            Oops! Something went wrong. Please try again later.
          </Alert>
        )}
        {children}
      </CardContent>
    </Card>
  );
};
