import React from "react";
import { Edit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export interface OverviewSectionProps {
  title: string;
  onClick: () => void;
}
export const OverviewSection: React.FC<
  React.PropsWithChildren<OverviewSectionProps>
> = ({ title, onClick, children }) => {
  return (
    <>
      <Stack>
        <Box display="flex" flexDirection="row" alignItems={"center"} gap={1}>
          <Typography variant="body1" fontWeight={800}>
            {title}
          </Typography>
          <Tooltip title={`Edit ${title}`} placement="right-start">
            <span>
              <IconButton sx={{ p: 0.5 }} onClick={onClick}>
                <Edit fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
        {children}
      </Stack>
    </>
  );
};
