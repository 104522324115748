import {
  CopyAll,
  Delete,
  Edit,
  Photo,
  Public,
  PublicOff,
  Visibility,
} from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ConfirmDialog } from "../../../common/Dialog";
import { ImageWithSkeleton } from "../../../common/Image/ImageWithSkeleton";
import { SnackbarAlert } from "../../../common/SnackbarAlert";
import { PublishPlanCheckModal } from "../PublishPlanCheckModal";
import { UploadArtworkDialog } from "../UploadArtworkDialog";
import { NoWorkoutPlanArtwork } from "./NoWorkoutPlanArtwork";
import { PlaylistCardMenu } from "./PlaylistCardMenu";
import { usePlaylistCard } from "./usePlaylistCard";

interface PlaylistCardComponentProps
  extends ReturnType<typeof usePlaylistCard> {}

export const PlaylistCard: React.FC<PlaylistCardComponentProps> = ({
  workoutPlan,
  currentWorkoutPlan,
  handleOpenMenu,
  playlistMenuCardProps,
  navigateToViewPlan,
  navigateToEditPlan,
  uploadArtworkDialogConfig,
  unpublishWorkoutPlanDialogConfig,
  deleteWorkoutPlanDialogConfig,
  duplicatePlan,
  isDuplicateErrorMessageOpen,
  closeDuplicateErrorMessage,
  isPublishPlanModalVisible,
  closePublishPlanModal,
  beginPublishingCheckFlow,
  refreshWorkoutPlans,
}) => {
  return (
    <>
      <Card elevation={1} sx={{ height: "100%" }}>
        <CardActionArea
          component={Link}
          to={`/workout-plans/${workoutPlan.id}`}
          sx={{ height: "100%" }}
        >
          <Box display="flex" justifyContent={"space-between"} p={2} pr={1}>
            <Box display="flex" flexDirection="column" width="80%">
              <Typography variant="h5" noWrap>
                {workoutPlan.name}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {`Last updated: ${new Date(
                  workoutPlan.updatedAt
                ).toLocaleDateString("en-gb", {
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}`}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <IconButton
                aria-label="settings"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleOpenMenu(e);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
          <Stack direction="row" px={2} gap={0.5}>
            {workoutPlan.publishedAt ? (
              <Chip
                sx={{
                  ml: 0,
                  mb: 2,
                }}
                size="small"
                color={"success"}
                icon={<Public />}
                label="Published"
              />
            ) : (
              <Chip
                sx={{
                  ml: 0,
                  mb: 2,
                }}
                size="small"
                color={"default"}
                icon={<PublicOff />}
                label="Not published"
              />
            )}
          </Stack>
          <CardMedia component={Box}>
            {workoutPlan.thumbnail ? (
              <ImageWithSkeleton
                alt={`${workoutPlan.name} workout plan cover`}
                src={workoutPlan.thumbnail}
                style={{
                  aspectRatio: 1,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <NoWorkoutPlanArtwork />
            )}
          </CardMedia>
          <CardContent>
            <Typography
              variant="body2"
              color="text.secondary"
              fontStyle={workoutPlan.description.length ? "inherit" : "italic"}
            >
              {workoutPlan.description.length
                ? workoutPlan.description
                : "No description"}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <PlaylistCardMenu {...playlistMenuCardProps}>
        <MenuItem onClick={navigateToViewPlan}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>

        <Tooltip
          title={workoutPlan.publishedAt ? "Can't edit a published plan" : ""}
        >
          <span>
            <MenuItem
              disabled={!!workoutPlan.publishedAt || !!workoutPlan.assignations}
              onClick={navigateToEditPlan}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>

        <MenuItem onClick={uploadArtworkDialogConfig.openDialog}>
          <ListItemIcon>
            <Photo fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change artwork</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => duplicatePlan(workoutPlan.id)}>
          <ListItemIcon>
            <CopyAll fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>

        <Tooltip title={workoutPlan.publishedAt ? "Take offline" : "Go live!"}>
          <span>
            <MenuItem
              onClick={
                workoutPlan.publishedAt
                  ? unpublishWorkoutPlanDialogConfig.openDialog
                  : beginPublishingCheckFlow
              }
            >
              <ListItemIcon>
                {workoutPlan.publishedAt ? (
                  <PublicOff fontSize="small" />
                ) : (
                  <Public fontSize="small" />
                )}
              </ListItemIcon>
              <ListItemText>
                {workoutPlan.publishedAt ? "Unpublish" : "Publish"}
              </ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Divider />
        <Tooltip
          title={
            workoutPlan.publishedAt ? "This plan is published" : "Delete Plan"
          }
        >
          <span>
            <MenuItem
              disabled={!!workoutPlan.publishedAt}
              onClick={deleteWorkoutPlanDialogConfig.openDialog}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
      </PlaylistCardMenu>
      <ConfirmDialog {...unpublishWorkoutPlanDialogConfig.props} />
      <ConfirmDialog {...deleteWorkoutPlanDialogConfig.props} />
      <UploadArtworkDialog
        artworkSrc={workoutPlan.thumbnail}
        {...uploadArtworkDialogConfig.props}
      />
      {currentWorkoutPlan && (
        <PublishPlanCheckModal
          isOpen={isPublishPlanModalVisible}
          workoutPlan={currentWorkoutPlan}
          onClose={closePublishPlanModal}
          refreshWorkoutPlans={refreshWorkoutPlans}
        />
      )}
      <SnackbarAlert
        isOpen={isDuplicateErrorMessageOpen}
        close={closeDuplicateErrorMessage}
        message="Something went wrong whilst duplicating the workout plan. Please try again later."
        severity="error"
      />
    </>
  );
};
