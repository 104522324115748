import React from "react";
import MuxPlayerReact, {
  MuxPlayerProps as MuxPlayerReactProps,
} from "@mux/mux-player-react";
import { useTheme } from "@mui/material";
import { MuxPlayerWrapper } from "./MuxPlayer.styles";

interface MuxPlayerProps {
  playbackId: string;
  streamType?: MuxPlayerReactProps["streamType"];
  tokens?: MuxPlayerReactProps["tokens"];
  metadata?: {
    videoTitle: string;
    videoId?: string;
    ViewerUserId?: string;
  };
}

export const MuxPlayer: React.FC<MuxPlayerProps> = ({
  playbackId,
  streamType,
  tokens,
  metadata,
}) => {
  const theme = useTheme();
  return (
    <MuxPlayerWrapper>
      <MuxPlayerReact
        playbackId={playbackId}
        streamType={streamType}
        tokens={tokens}
        metadata={metadata}
        accentColor={theme.palette.primary.main}
        style={{
          borderRadius: theme.shape.borderRadius,
          width: "100%",
          height: "100%",
        }}
      />
    </MuxPlayerWrapper>
  );
};
