import {
  ListMaxLengthViolationError,
  WorkoutList as WorkoutListType,
  WorkoutPlanDraggableItem,
} from "../../../store/plan-builder/types";
import { Schedule, ScheduleItem, Workout } from "../../../store/workout/types";
import { randomUUID } from "../../../util/uuid";

export class WorkoutPlanInitialiser {
  private currentSchedule: Schedule;
  private workoutLists: WorkoutListType[] = [];
  private workoutListIdArray: string[] = [];
  private currentScheduleRecord: Record<number, ScheduleItem> = {};
  private DAYS_PER_WEEK = 7;

  constructor(currentSchedule: Schedule) {
    this.currentSchedule = currentSchedule;
    const currentScheduleEntries = currentSchedule?.map((scheduleItem) => [
      scheduleItem.weekAndDay.dayNumber,
      scheduleItem,
    ]);

    this.currentScheduleRecord =
      currentScheduleEntries && Object.fromEntries(currentScheduleEntries);
  }

  private getFinalScheduleItemFromSchedule = () => {
    return this.currentSchedule
      .map((item) => item.weekAndDay.dayNumber)
      .reduce(
        (maxDay, currentDay) => (maxDay >= currentDay ? maxDay : currentDay),
        0
      );
  };

  private setWorkoutListIdArray = () => {
    return this.workoutLists.map((item) => item.id);
  };

  static createWorkoutList = (
    workouts: WorkoutPlanDraggableItem[] = []
  ): WorkoutListType => {
    return { id: randomUUID(), workouts };
  };

  private createNWorkoutListArrays = (n: number) => {
    const createdWorkoutLists = [...new Array(n)].map(() => {
      return WorkoutPlanInitialiser.createWorkoutList();
    });
    this.workoutLists = createdWorkoutLists;
    this.workoutListIdArray = this.setWorkoutListIdArray();
  };

  private createRestScheduleItem = ({
    workoutListId,
    scheduleWeekDay,
  }: {
    workoutListId: string;
    scheduleWeekDay: number;
  }) => {
    this.createWorkoutOrRestScheduleItem({
      workoutListId,
      scheduleWeekDay,
      workout: null,
    });
  };
  private createWorkoutOrRestScheduleItem = ({
    workoutListId,
    scheduleWeekDay,
    workout,
  }: {
    workoutListId: string;
    scheduleWeekDay: number;
    workout: Workout | null;
  }) => {
    const newLists = [...this.workoutLists];
    const listToAddTo = newLists.filter((list) => list.id === workoutListId)[0];
    if (listToAddTo.workouts.length >= this.DAYS_PER_WEEK) {
      throw new ListMaxLengthViolationError();
    }
    listToAddTo.workouts.splice(scheduleWeekDay - 1, 0, {
      uuid: randomUUID(),
      workout,
    });
    this.workoutLists = newLists;
  };

  build() {
    const totalNumberOfDays = this.getFinalScheduleItemFromSchedule();
    const totalNumberOfWeeks = Math.ceil(
      totalNumberOfDays / this.DAYS_PER_WEEK
    );
    this.createNWorkoutListArrays(totalNumberOfWeeks);

    [...new Array(totalNumberOfDays)].forEach((_, index) => {
      const daySlot = index + 1;
      const scheduleItem = this.currentScheduleRecord[daySlot];
      const scheduleWeek = Math.ceil(daySlot / this.DAYS_PER_WEEK);
      const scheduleWeekDay = ((daySlot - 1) % this.DAYS_PER_WEEK) + 1;

      if (!scheduleItem) {
        this.createRestScheduleItem({
          workoutListId: this.workoutListIdArray[scheduleWeek - 1],
          scheduleWeekDay,
        });
      } else {
        this.createWorkoutOrRestScheduleItem({
          workoutListId: this.workoutListIdArray[scheduleWeek - 1],
          scheduleWeekDay,
          workout: scheduleItem.workout,
        });
      }
    });
    return this.workoutLists;
  }
}
