import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { searchPages } from "../../util/array";
import { defaultPlaylistState, Playlist } from "./types";

const playlistSlice = createSlice({
  name: "playlist",
  initialState: defaultPlaylistState,
  reducers: {
    postPlaylistAttempt: (state) => {
      state.create.isError = false;
      state.create.isLoading = true;
    },
    postPlaylistFailure: (state) => {
      state.create.isError = true;
      state.create.isLoading = false;
    },
    postPlaylistSuccess: (
      state,
      action: PayloadAction<{
        newPlaylist: Playlist;
      }>
    ) => {
      state.create.isError = false;
      state.create.isLoading = false;
      state.currentPlaylist = action.payload.newPlaylist;
    },
    getPlaylistAttempt: (state) => {
      state.retrieve.isError = false;
      state.retrieve.isLoading = true;
    },
    getPlaylistFailure: (state) => {
      state.retrieve.isError = true;
      state.retrieve.isLoading = false;
    },
    getPlaylistSuccess: (
      state,
      action: PayloadAction<{
        selectedPlaylist: Playlist;
      }>
    ) => {
      state.retrieve.isError = false;
      state.retrieve.isLoading = false;
      state.currentPlaylist = action.payload.selectedPlaylist;
    },
    patchPlaylistAttempt: (state) => {
      state.update.isError = false;
      state.update.isLoading = true;
    },
    patchPlaylistFailure: (state) => {
      state.update.isError = true;
      state.update.isLoading = false;
    },
    patchPlaylistSuccess: (
      state,
      action: PayloadAction<{ patchedPlaylist: Playlist }>
    ) => {
      state.update.isError = false;
      state.update.isLoading = false;
      state.currentPlaylist = action.payload.patchedPlaylist;
    },
    deletePlaylistAttempt: (state) => {
      state.destroy.isError = false;
      state.destroy.isLoading = true;
    },
    deletePlaylistFailure: (state) => {
      state.destroy.isError = true;
      state.destroy.isLoading = false;
    },
    deletePlaylistSuccess: (state) => {
      state.destroy.isError = false;
      state.destroy.isLoading = false;
    },
    getPlaylistsAttempt: (state) => {
      state.playlists.isError = false;
      state.playlists.isLoading = true;
    },
    getPlaylistsFailure: (state) => {
      state.playlists.isError = true;
      state.playlists.isLoading = false;
    },
    getPlaylistsSuccess: (
      state,
      action: PayloadAction<{ pageNumber: number; playlists: Playlist[] }>
    ) => {
      state.playlists.isError = false;
      state.playlists.isLoading = false;
      if (state.playlists.data) {
        state.playlists.data[action.payload.pageNumber] =
          action.payload.playlists;
      } else {
        state.playlists.data = {
          [action.payload.pageNumber]: action.payload.playlists,
        };
      }
    },
    setPlaylistsCount: (state, action: PayloadAction<{ count: number }>) => {
      state.playlists.count = action.payload.count;
    },
    clearPlaylistsData: (state) => {
      state.playlists.data = null;
    },
    replacePlaylistInPaginatedPlaylists: (
      state,
      action: PayloadAction<Playlist>
    ) => {
      const result = searchPages(
        { ...state.playlists.data },
        (item) => item.id === action.payload.id
      );
      if (result && state.playlists.data) {
        state.playlists.data[result.pageNumber][result.index] = action.payload;
      }
    },
    reset: () => defaultPlaylistState,
  },
});

export const PlaylistReducer = playlistSlice.reducer;
export const playlistActions = playlistSlice.actions;
