import { useEffect, useState } from "react";

export const useDebouncedState = <S>(
  initialState: S | (() => S),
  timeoutMs: number
) => {
  const [liveState, setLiveState] = useState(initialState);
  const [debouncedState, setDebouncedState] = useState(initialState);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedState(liveState);
    }, timeoutMs);

    return () => {
      clearTimeout(timer);
    };
  }, [liveState, timeoutMs]);

  return [liveState, debouncedState, setLiveState, setDebouncedState] as const;
};
