import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultWorkoutState, ExpandedWorkout, Workout } from "./types";

const workoutSlice = createSlice({
  name: "workout",
  initialState: defaultWorkoutState,
  reducers: {
    postWorkoutAttempt: (state) => {
      state.create.isError = false;
      state.create.isLoading = true;
    },
    postWorkoutFailure: (state) => {
      state.create.isError = true;
      state.create.isLoading = false;
    },
    postWorkoutSuccess: (
      state,
      action: PayloadAction<{
        newWorkout: ExpandedWorkout;
      }>
    ) => {
      state.create.isError = false;
      state.create.isLoading = false;
      state.currentWorkout = action.payload.newWorkout;
    },
    getWorkoutAttempt: (state) => {
      state.retrieve.isError = false;
      state.retrieve.isLoading = true;
    },
    getWorkoutFailure: (state) => {
      state.retrieve.isError = true;
      state.retrieve.isLoading = false;
    },
    getWorkoutSuccess: (
      state,
      action: PayloadAction<{
        selectedWorkout: ExpandedWorkout;
      }>
    ) => {
      state.retrieve.isError = false;
      state.retrieve.isLoading = false;
      state.currentWorkout = action.payload.selectedWorkout;
    },
    patchWorkoutAttempt: (state) => {
      state.update.isError = false;
      state.update.isLoading = true;
    },
    patchWorkoutFailure: (state) => {
      state.update.isError = true;
      state.update.isLoading = false;
    },
    patchWorkoutSuccess: (
      state,
      action: PayloadAction<{ patchedWorkout: ExpandedWorkout }>
    ) => {
      state.update.isError = false;
      state.update.isLoading = false;
      state.currentWorkout = action.payload.patchedWorkout;
    },
    deleteWorkoutAttempt: (state) => {
      state.destroy.isError = false;
      state.destroy.isLoading = true;
    },
    deleteWorkoutFailure: (state) => {
      state.destroy.isError = true;
      state.destroy.isLoading = false;
    },
    deleteWorkoutSuccess: (state) => {
      state.destroy.isError = false;
      state.destroy.isLoading = false;
    },
    getWorkoutsAttempt: (state) => {
      state.workouts.isError = false;
      state.workouts.isLoading = true;
    },
    getWorkoutsFailure: (state) => {
      state.workouts.isError = true;
      state.workouts.isLoading = false;
    },
    getWorkoutsSuccess: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        workouts: Workout[];
      }>
    ) => {
      state.workouts.isError = false;
      state.workouts.isLoading = false;
      if (state.workouts.data) {
        state.workouts.data[action.payload.pageNumber] =
          action.payload.workouts;
      } else {
        state.workouts.data = {
          [action.payload.pageNumber]: action.payload.workouts,
        };
      }
    },
    setWorkoutsCount: (state, action: PayloadAction<{ count: number }>) => {
      state.workouts.count = action.payload.count;
    },
    clearWorkoutsData: (state) => {
      state.workouts.data = null;
    },
    getAllWorkoutsAttempt: (state) => {
      state.allWorkouts.isError = false;
      state.allWorkouts.isLoading = true;
    },
    getAllWorkoutsFailure: (state) => {
      state.allWorkouts.isError = true;
      state.allWorkouts.isLoading = false;
    },
    getAllWorkoutsSuccess: (
      state,
      action: PayloadAction<{ workouts: Workout[] }>
    ) => {
      state.allWorkouts.data = action.payload.workouts;
      state.allWorkouts.isLoading = false;
    },
    reset: () => defaultWorkoutState,
  },
});

export const WorkoutReducer = workoutSlice.reducer;
export const workoutActions = workoutSlice.actions;
