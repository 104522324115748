import { User, UserAPI, TrainerProfile, TrainerProfileApi } from "./types";

const transformTrainerProfileFromAPI = (
  api: TrainerProfileApi
): TrainerProfile => {
  return {
    id: api.id,
  };
};

export const transformUserFromAPI = (api: UserAPI): User => {
  return {
    id: api.id,
    firstName: api.first_name,
    lastName: api.last_name,
    email: api.email,
    username: api.username,
    trainerProfile: api.trainer_profile
      ? transformTrainerProfileFromAPI(api.trainer_profile)
      : null,
  };
};
