import MUISelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

export interface SelectOption {
  value: string | number;
  label: string;
}

interface SelectProps {
  value: string;
  label: string;
  onChange: (newValue: string) => void;
  options: SelectOption[];
}

export const Select: React.FC<SelectProps> = ({
  value,
  label,
  onChange,
  options,
}) => {
  return (
    <FormControl size="small">
      <InputLabel id="select-label">{label}</InputLabel>
      <MUISelect
        labelId="select-label"
        id="select"
        value={value}
        label={label}
        onChange={({ target }) => onChange(target.value)}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
