import CloseIcon from "@mui/icons-material/Close";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Footer } from "../../common/Footer/Footer";
import { useSignIn } from "./useSignIn";

interface Props extends ReturnType<typeof useSignIn> {}
export const SignIn: React.FC<Props> = ({
  password,
  emailAddress,
  onChangeEmailAddress,
  onChangePassword,
  onSubmit,
  canSignIn,
  isEmailAddressValid,
  isSignInError,
  resetSignInError,
  isSignInLoading,
}) => {
  const isEmailAddressError = !!emailAddress.length && !isEmailAddressValid;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={onSubmit}
            noValidate
            sx={{ mt: 1 }}
            data-testid="t_sign_in_form"
          >
            <Collapse in={isSignInError} data-testid="t_sign_in_error_message">
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={resetSignInError}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {`Sorry, we couldn't log you in! Please check your email and password and try again.`}
              </Alert>
            </Collapse>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={emailAddress}
              onChange={(e) => onChangeEmailAddress(e.target.value)}
              error={isEmailAddressError}
              helperText={isEmailAddressError && "Enter a valid email address"}
              inputProps={{ "data-testid": "t_email_address_input" }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => onChangePassword(e.target.value)}
              inputProps={{ "data-testid": "t_password_input" }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!canSignIn || isSignInLoading}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href={`${process.env.REACT_APP_BASE_URL}/password_reset`}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};
