import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { WorkoutPlan } from "../../../../store/workout-plan/types";
import { ScheduleItem, WeekAndDay } from "../../../../store/workout/types";
import { groupBy } from "../../../../util/array";
import { WorkoutAndSchedule } from "./WorkoutAndSchedule";
import { TableBody, TableHead } from "./styles";
import { useNavigate } from "react-router-dom";

interface WorkoutPlanTable {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  schedule: ScheduleItem[][];
}
interface Props {
  workoutPlan: WorkoutPlan;
}
const getDayNumberFromDayAndWeek = (weekAndDay: WeekAndDay) =>
  (weekAndDay.week - 1) * 7 + weekAndDay.dayOfWeek;

const transformWorkoutPlanToTable = (plan: WorkoutPlan): WorkoutPlanTable => {
  return {
    id: plan.id,
    description: plan.description,
    name: plan.name,
    thumbnail: plan.thumbnail,
    schedule: groupBy(
      [...plan.schedule].sort(
        (a, b) =>
          getDayNumberFromDayAndWeek(a.weekAndDay) -
          getDayNumberFromDayAndWeek(b.weekAndDay)
      ),
      (item) => item.weekAndDay.week
    ),
  };
};

export const WorkoutPlanTable: React.FC<Props> = ({ workoutPlan }) => {
  const workoutPlanData = useMemo(
    () => transformWorkoutPlanToTable(workoutPlan),
    [workoutPlan]
  );
  const navigate = useNavigate();
  const navigateToEditWorkout = (scheduleItemId: string) => {
    navigate(`/workout-plans/edit/${workoutPlan.id}/day/${scheduleItemId}`);
  };
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={"20%"}>Schedule</TableCell>
            <TableCell width={"80%"}>Workout Name</TableCell>
          </TableRow>
        </TableHead>
        {workoutPlanData.schedule.map((week, weekNumber) => {
          return (
            <React.Fragment key={weekNumber}>
              <TableBody>
                {week.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <WorkoutAndSchedule
                        scheduleItem={item}
                        canEdit={!workoutPlan.publishedAt}
                        onClickEdit={() => navigateToEditWorkout(item.id)}
                      />
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </React.Fragment>
          );
        })}
      </Table>
    </TableContainer>
  );
};
