import {
  Box,
  CircularProgress,
  DialogActions,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { BaseWorkoutPlan } from "../../../../../store/workout-plan/types";
import { ImageWithSkeleton } from "../../../../common/Image/ImageWithSkeleton";
import { OverviewSection } from "./OverviewSection";
import {
  DialogPrimaryButton,
  DialogSecondaryButton,
} from "../../../../common/Dialog/DialogButtons";
import { getSentences } from "../../../../../util/string/sentence";
import { PointDescription } from "./PointDescription";

export interface WorkoutPlanSummaryProps {
  workoutPlan: BaseWorkoutPlan;
  onClickEditName: () => void;
  onClickEditDescription: () => void;
  onClickEditArtwork: () => void;
  onClickPrimaryAsync: () => Promise<void>;
  onClickSecondary: () => void;
  isLoading: boolean;
}

export const CheckWorkoutPlanOverview: React.FC<WorkoutPlanSummaryProps> = ({
  workoutPlan,
  onClickEditName,
  onClickEditDescription,
  onClickEditArtwork,
  onClickPrimaryAsync,
  onClickSecondary,
  isLoading,
}) => {
  const descriptionPoints = getSentences(workoutPlan.description);
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        width={"100%"}
        my={2}
        gap={2}
      >
        <Stack gap={1} sx={{ width: "50%" }}>
          <OverviewSection title="Name" onClick={onClickEditName}>
            <Typography variant="h6">{workoutPlan.name}</Typography>
          </OverviewSection>
          <OverviewSection title="Description" onClick={onClickEditDescription}>
            <Stack gap={1} mt={1}>
              {descriptionPoints.map((point, pointIndex) => (
                <>
                  <PointDescription key={pointIndex} text={point} />
                </>
              ))}
            </Stack>
          </OverviewSection>
        </Stack>
        <Stack gap={1} sx={{ width: "50%" }}>
          <OverviewSection title="Artwork" onClick={onClickEditArtwork}>
            <Box sx={{ width: "80%" }}>
              <ImageWithSkeleton
                alt={`${workoutPlan.name} workout plan cover`}
                src={workoutPlan.thumbnail}
                style={{
                  aspectRatio: 1,
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </OverviewSection>
        </Stack>
      </Box>
      <DialogActions sx={{ width: "100%" }}>
        <DialogSecondaryButton onClick={onClickSecondary}>
          {"Previous "}
        </DialogSecondaryButton>
        <DialogPrimaryButton
          variant="contained"
          disabled={isLoading}
          onClick={onClickPrimaryAsync}
        >
          {"Publish"}
          {isLoading && (
            <CircularProgress size={"1rem"} sx={{ ml: 1, color: "white" }} />
          )}
        </DialogPrimaryButton>
      </DialogActions>
    </>
  );
};
