import { Wallet as WalletIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

export const EmptyPayouts: React.FC = () => {
  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        alignItems="center"
        justifyContent="center"
        p={4}
        gap={1}
      >
        <Box
          borderRadius={3}
          bgcolor="primary.xlight"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          mb={2}
          sx={({ palette }) => ({
            ".MuiSvgIcon-root": {
              color: palette.primary.main,
            },
          })}
        >
          {<WalletIcon />}
        </Box>
        <Box width="40%">
          <Typography variant="h5" fontWeight={700} align="center" gutterBottom>
            {"No Payouts"}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            align="center"
            gutterBottom
          >
            {"Your payouts will appear here once they're generated."}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
