import { CreatorVideo } from "../../../store/exercises/types";
import {
  extractVideoIdFromUrl,
  getYoutubeThumbnailURL,
} from "../YoutubeEmbed/YouTubeEmbed.utils";

export const getMuxThumbnailUrl = (playbackId: string, token: string) => {
  return `https://image.mux.com/${playbackId}/thumbnail.jpg?token=${token}`;
};

export const getMediaUrl = (video: CreatorVideo) => {
  if (video.muxVideo) {
    return getMuxThumbnailUrl(
      video.muxVideo.playbackId,
      video.muxVideo.thumbnailToken
    );
  }
  if (video.youtubeLink) {
    const videoId = extractVideoIdFromUrl(video.youtubeLink);
    return getYoutubeThumbnailURL(videoId!);
  }
  return "";
};
