import React from "react";
import { EmptyList } from "../../common/EmptyList";
import { FitnessCenter } from "@mui/icons-material";

interface Props {
  onClickAddExercise: () => void;
}
export const EmptyWorkout: React.FC<Props> = ({ onClickAddExercise }) => {
  return (
    <EmptyList
      onClickButton={onClickAddExercise}
      primaryText={"No exercises yet"}
      secondaryText={"Time to get building"}
      buttonText={"Add exercise"}
      icon={<FitnessCenter />}
    />
  );
};
