import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import { GetWorkoutPlans } from "../../../store/workout-plan/actions";
import { selectWorkoutPlansPaginatedResource } from "../../../store/workout-plan/selectors";
import { selectUser } from "../../../store/user/selectors";
import { PostWorkoutPlan } from "../../../store/workout-plan/actions";
import { FormConfirmDialogProps } from "../../common/Dialog/types";

const WORKOUT_PLANS_PER_PAGE = 6;

export const useMyWorkoutPlans = () => {
  const [select, dispatch] = useStore();
  const query = useQuery();
  const currentPage = parseInt(query.get("page") ?? "1", 10);
  const isCreate = query.get("create") === "true";

  const paginatedWorkoutPlansResource = select(
    selectWorkoutPlansPaginatedResource
  );
  const totalWorkoutPlans = paginatedWorkoutPlansResource.count || 0;
  const totalPages = Math.ceil(totalWorkoutPlans / WORKOUT_PLANS_PER_PAGE);
  const user = select(selectUser);
  const { isError, isLoading } = paginatedWorkoutPlansResource;

  const [isShowingErrorMessage, hideErrorMessage] =
    useErrorMessageState(isError);

  const doesPageExist = useCallback(
    (pageNumber: number) => {
      return !!paginatedWorkoutPlansResource.data?.[pageNumber];
    },
    [paginatedWorkoutPlansResource.data]
  );

  useEffect(() => {
    if (!doesPageExist(currentPage)) {
      GetWorkoutPlans(dispatch, currentPage);
    }
  }, [currentPage, dispatch, doesPageExist]);

  const navigate = useNavigate();

  const createNewWorkoutPlanAndNavigate = async (text: string) => {
    const newWorkoutPlan = await PostWorkoutPlan(dispatch, { name: text });
    newWorkoutPlan && navigate(`/workout-plans/edit/${newWorkoutPlan.id}`);
  };

  const [isNewWorkoutPlanDialogOpen, setIsNewWorkoutPlanDialogOpen] =
    useState(isCreate);
  const openNewWorkoutPlanDialog = () => {
    setIsNewWorkoutPlanDialogOpen(true);
  };

  const newWorkoutPlanDialogProps: FormConfirmDialogProps = {
    title: "First, choose a workout plan name",
    inputFieldId: "new-workout-plan",
    subtitle: "Don't worry, you can change this later if you want",
    isOpen: isNewWorkoutPlanDialogOpen,
    onClose: () => setIsNewWorkoutPlanDialogOpen(false),
    inputFieldPlaceholder: `${user.data?.firstName}'s Amazing New Plan`,
    inputFieldLabel: `Enter a workout plan name`,
    onSubmitText: createNewWorkoutPlanAndNavigate,
    onClickCancel: () => setIsNewWorkoutPlanDialogOpen(false),
  };

  return {
    workoutPlans: paginatedWorkoutPlansResource.data?.[currentPage] || [],
    currentPage,
    totalPages,
    openNewWorkoutPlanDialog,
    newWorkoutPlanDialogProps,
    isLoading,
    isShowingErrorMessage,
    hideErrorMessage,
    totalWorkoutPlans,
  };
};
