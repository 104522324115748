import React from "react";
import { BaseAlertDialogProps } from "./types";
import { BaseDialog } from "./BaseDialog";
import { DialogActions, DialogTitle } from "@mui/material";
import { DialogSecondaryButton } from "./DialogButtons";

export const BaseAlertDialog: React.FC<
  React.PropsWithChildren<BaseAlertDialogProps>
> = ({
  isOpen,
  onClose,
  title,
  onClickButton,
  buttonText = "Close",
  buttonProps,
  children,
}) => {
  return (
    <BaseDialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 2,
          width: "60%",
          maxWidth: 500,
        },
      }}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      {children}
      <DialogActions>
        <DialogSecondaryButton onClick={onClickButton} {...buttonProps}>
          {buttonText}
        </DialogSecondaryButton>
      </DialogActions>
    </BaseDialog>
  );
};
