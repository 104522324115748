import { createDefaultResource, Resource } from "../types";

export interface TrainerProfileApi {
  id: number;
}

export interface TrainerProfile {
  id: number;
}
export interface UserAPI {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  trainer_profile: TrainerProfileApi | null;
}

export interface User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  trainerProfile: TrainerProfile | null;
}

export interface UserState {
  user: Resource<User>;
}

export const defaultUserState: UserState = {
  user: createDefaultResource(),
};
