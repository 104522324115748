import {
  TableBody as MuiTableBody,
  tableCellClasses,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  tableRowClasses,
} from "@mui/material";
import styled from "styled-components";

export const TableWeekRow = styled(MuiTableRow)(({ theme }) => ({
  [`& .${tableCellClasses.body}`]: {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

export const TableBody = styled(MuiTableBody)(({ theme }) => ({
  [`& .${tableRowClasses.root}:first-child td`]: {
    borderTop: `solid 2px ${theme.palette.primary.main}`,
  },
}));

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
  [`& .${tableCellClasses.head}`]: {
    color: theme.palette.text.secondary,
    border: "none",
    fontWeight: 700,
  },
}));

export const WorkoutRow = styled(MuiTableRow)(
  ({ theme, $isRest }: { theme: any; $isRest: boolean }) => ({
    [`& .${tableCellClasses.root}`]: {
      color: $isRest ? theme.palette.text.disabled : theme.palette.text.primary,
    },
  })
);
