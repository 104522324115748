import { useStore } from "../../../store";
import { selectUser } from "../../../store/user/selectors";

export const useAppStoreShowcase = () => {
  const [select] = useStore();
  const user = select(selectUser);

  return {
    firstName: user.data?.firstName,
  };
};
