import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorMessageState } from "../../../../hooks/useErrorMessageState";
import { useStore } from "../../../../store";
import {
  ClearWorkoutPlansData,
  DeleteWorkoutPlan,
  DuplicateWorkoutPlan,
  PatchWorkoutPlan,
  PatchWorkoutPlanThumbnail,
  SetCurrentWorkoutPlan,
} from "../../../../store/workout-plan/actions";
import {
  selectCurrentWorkoutPlanResource,
  selectDuplicateWorkoutPlanCallState,
} from "../../../../store/workout-plan/selectors";
import {
  DialogName,
  useConfirmDialog,
  useDialog,
} from "../../../common/Dialog/hooks";
import { useUploadArtworkDialog } from "../useUploadArtworkDialog";
import { PlaylistCardProps } from "./index";

export const usePlaylistCard = ({ workoutPlan }: PlaylistCardProps) => {
  const [select, dispatch] = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const navigateToViewSpecificPlan = (id: string) => {
    navigate(`/workout-plans/${id}`);
  };
  const navigateToViewPlan = () => {
    navigateToViewSpecificPlan(workoutPlan.id);
  };
  const navigateToEditPlan = () => {
    navigate(`/workout-plans/edit/${workoutPlan.id}`);
  };

  const currentWorkoutPlanResource = select(selectCurrentWorkoutPlanResource);

  const duplicateWorkoutPlanCallState = select(
    selectDuplicateWorkoutPlanCallState
  );

  const [isDuplicateErrorMessageOpen, closeDuplicateErrorMessage] =
    useErrorMessageState(duplicateWorkoutPlanCallState.isError);

  const [
    isPublishPlanModalVisible,
    openPublishPlanModal,
    closePublishPlanModal,
  ] = useDialog(false);

  const beginPublishingCheckFlow = () => {
    SetCurrentWorkoutPlan(dispatch, { ...workoutPlan, schedule: [] });
    openPublishPlanModal();
  };

  const refreshWorkoutPlans = () => {
    ClearWorkoutPlansData(dispatch);
  };

  const unpublishWorkoutPlan = async () => {
    await PatchWorkoutPlan(dispatch, workoutPlan.id, {
      published_at: null,
    });
    ClearWorkoutPlansData(dispatch);
  };

  const deleteWorkoutPlan = async () => {
    await DeleteWorkoutPlan(dispatch, workoutPlan.id);
  };

  const unpublishWorkoutPlanDialogConfig = useConfirmDialog(
    DialogName.UNPUBLISH_PLAN,
    unpublishWorkoutPlan,
    { isLoading: currentWorkoutPlanResource.isLoading }
  );

  const deleteWorkoutPlanDialogConfig = useConfirmDialog(
    DialogName.DELETE_PLAN,
    deleteWorkoutPlan,
    { isLoading: currentWorkoutPlanResource.isLoading }
  );

  const saveImage = async (selectedFile: File | null) => {
    if (selectedFile) {
      await PatchWorkoutPlanThumbnail(dispatch, workoutPlan.id, selectedFile);
    }
  };

  const uploadArtworkDialogConfig = useUploadArtworkDialog(saveImage, {
    isUploading: currentWorkoutPlanResource.isLoading,
  });

  const duplicatePlan = async (playlistId: string) => {
    const id = await DuplicateWorkoutPlan(dispatch, playlistId);
    if (id) navigateToViewSpecificPlan(id);
  };

  return {
    workoutPlan,
    currentWorkoutPlan: currentWorkoutPlanResource.data,
    handleOpenMenu,
    playlistMenuCardProps: {
      isOpen: isMenuOpen,
      onClose: handleCloseMenu,
      anchorEl: anchorEl,
    },
    navigateToViewPlan,
    navigateToEditPlan,
    uploadArtworkDialogConfig,
    unpublishWorkoutPlanDialogConfig,
    deleteWorkoutPlanDialogConfig,
    duplicatePlan,
    isDuplicateErrorMessageOpen,
    closeDuplicateErrorMessage,
    isPublishPlanModalVisible,
    closePublishPlanModal,
    beginPublishingCheckFlow,
    refreshWorkoutPlans,
  };
};
