import { Box, Button, Tooltip, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import AddIcon from "@mui/icons-material/Add";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

interface PageHeaderWithButtonProps extends PageHeaderProps {
  onClickButton?: () => void;
  buttonTitle?: string;
  tooltip?: string;
}

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <Box
      width={"100%"}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      mb={2}
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h1" variant="h4" fontWeight="800">
          {title}
        </Typography>
        {subtitle && (
          <Typography component="h2" variant="subtitle1" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};

export const PageHeaderWithButton: React.FC<PageHeaderWithButtonProps> = ({
  onClickButton,
  buttonTitle,
  tooltip,
  ...props
}) => {
  return (
    <PageHeader {...props}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Tooltip title={tooltip}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onClickButton}
          >
            {buttonTitle}
          </Button>
        </Tooltip>
      </Box>
    </PageHeader>
  );
};
