import { Refresh } from "@mui/icons-material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { Box, IconButton, Paper, Tooltip } from "@mui/material";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { BaseText } from "../../../common/BaseText";
import { DraggableFavourite } from "../DraggableFavourite";
import { SearchField } from "./SearchField";
import { useMyFavouritedWorkoutsList } from "./useMyFavouritedWorkoutsList";

interface MyFavouritedWorkoutsListProps
  extends ReturnType<typeof useMyFavouritedWorkoutsList> {}

export const MyFavouritedWorkoutsList: React.FC<
  MyFavouritedWorkoutsListProps
> = ({
  myWorkoutsListRef,
  onClickRefreshButton,
  isLoadingMyWorkouts,
  filterWorkoutsBySearchText,
  workoutsList = [],
  isCollapsed,
  expandMyWorkouts,
  collapseMyWorkouts,
  myWorkoutsListWidth,
}) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "none",
        overflowX: "hidden",
        p: 2,
        gap: 2,
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "primary.xlight",
        width: myWorkoutsListWidth,
        transition: "0.5s ease",
      }}
      ref={myWorkoutsListRef}
    >
      <>
        <Box
          display="flex"
          justifyContent={isCollapsed ? "center" : "space-between"}
          alignItems="center"
          width="100%"
        >
          {!isCollapsed && (
            <BaseText variant="h6" whiteSpace="nowrap">
              My Favourite Workouts
            </BaseText>
          )}
          <Box display="flex">
            {!isCollapsed && (
              <Tooltip title="Refresh your workouts">
                <span>
                  <IconButton
                    onClick={onClickRefreshButton}
                    disabled={isLoadingMyWorkouts}
                  >
                    <Refresh
                      sx={
                        isLoadingMyWorkouts
                          ? {
                              animation: "spin 0.5s linear infinite",
                              "@keyframes spin": {
                                "0%": {
                                  transform: "rotate(0deg)",
                                },
                                "100%": {
                                  transform: "rotate(360deg)",
                                },
                              },
                            }
                          : undefined
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            <Tooltip title="Collapse Toolbar">
              <span>
                <IconButton
                  onClick={isCollapsed ? expandMyWorkouts : collapseMyWorkouts}
                >
                  <FirstPageIcon
                    sx={{
                      transform: isCollapsed
                        ? "rotate(0.5turn)"
                        : "rotate(0turn)",
                      transition: "0.5s ease",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
        {!isCollapsed && (
          <SearchField
            isSearchFieldDisabled={isLoadingMyWorkouts}
            handleOnChangeSearchField={filterWorkoutsBySearchText}
          />
        )}

        {!isCollapsed && (
          <Droppable droppableId="origin" type="workout" isDropDisabled>
            {(props) => (
              <Box
                display="flex"
                flexDirection="column"
                ref={props.innerRef}
                sx={{ flexGrow: 1, overflowY: "scroll" }}
                {...props.droppableProps}
              >
                {workoutsList.map((workout, index) => {
                  return (
                    <DraggableFavourite
                      index={index}
                      workout={workout.workout!}
                      key={workout.uuid}
                      draggableId={workout.uuid}
                      isDragDisabled={isLoadingMyWorkouts}
                    />
                  );
                })}
                {props.placeholder}
              </Box>
            )}
          </Droppable>
        )}
      </>
    </Paper>
  );
};
