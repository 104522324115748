import { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { decodeToken } from "react-jwt";
import client, { setBearerToken } from "./axios";
import { AccessToken, DecodedToken, RefreshToken } from "./types";

export const ACCESS_TOKEN_COOKIE_NAME = "access_token";
export const REFRESH_TOKEN_COOKIE_NAME = "refresh_token";

export const setAccessTokenCookie = (accessToken: string) => {
  const d = new Date();
  const expiresInSeconds = getTokenExpiryInSeconds(accessToken)!;
  d.setTime(d.getTime() + expiresInSeconds * 1000);
  Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
    expires: d,
  });
};

export const clearAccessTokenCookie = () => {
  Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
};
export const clearRefreshTokenCookie = () => {
  Cookies.remove(REFRESH_TOKEN_COOKIE_NAME);
};
export const getAccessTokenFromCookie = () => {
  const cookieValue = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
  return cookieValue === "undefined" ? undefined : cookieValue;
};

export const setRefreshTokenCookie = (refreshToken: string) => {
  const d = new Date();
  const expiresInSeconds = getTokenExpiryInSeconds(refreshToken)!;
  d.setTime(d.getTime() + expiresInSeconds * 1000);
  Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refreshToken, {
    expires: d,
  });
};

export const getRefreshTokenFromCookie = () => {
  const cookieValue = Cookies.get(REFRESH_TOKEN_COOKIE_NAME);
  return cookieValue === "undefined" ? undefined : cookieValue;
};

const getTokenExpiryInSeconds = (token: string) => {
  const decodedToken = decodeToken<DecodedToken>(token);
  if (decodedToken) {
    return Math.floor(decodedToken.exp - Date.now() / 1000);
  }
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshTokenFromCookie();

  try {
    if (refreshToken) {
      const response = await client.post<
        AccessToken & RefreshToken,
        AxiosResponse<AccessToken & RefreshToken>,
        RefreshToken
      >("/phoenix/token/refresh/", { refresh: refreshToken });
      if (response.data) {
        const { access, refresh } = response.data;
        setBearerToken(access);
        setAccessTokenCookie(access);
        setRefreshTokenCookie(refresh);
        return access;
      }
    } else {
      clearAuthenticationHeadersAndCookies();
    }
  } catch (e) {
    clearAuthenticationHeadersAndCookies();
  }
};

export const signIn = async (email: string, password: string) => {
  const response = await client.post<AccessToken & RefreshToken>(
    "/phoenix/token/",
    {
      email,
      password,
    }
  );
  setAccessTokenCookie(response.data.access);
  setRefreshTokenCookie(response.data.refresh);
  client.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;
  const tokenPayload = decodeToken<DecodedToken>(response.data.access)!;
  return tokenPayload;
};

export const clearAuthenticationHeadersAndCookies = async () => {
  delete client.defaults.headers.common["Authorization"];
  client.interceptors.response.clear();
  clearAccessTokenCookie();
  clearRefreshTokenCookie();
};
