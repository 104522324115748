import React from "react";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { WorkoutBuilderLoading } from "../WorkoutBuilder/WorkoutBuilderLoading";
import { useEditWorkoutPage } from "./useEditWorkoutPage";

interface EditWorkoutPageProps extends ReturnType<typeof useEditWorkoutPage> {}

export const EditWorkoutPage: React.FC<EditWorkoutPageProps> = ({
  expandedWorkout,
  isGetWorkoutError,
  hideGetWorkoutError,
}) => {
  return (
    <>
      {expandedWorkout ? (
        <PageTemplate></PageTemplate>
      ) : (
        <WorkoutBuilderLoading />
      )}
      <SnackbarAlert
        close={hideGetWorkoutError}
        isOpen={isGetWorkoutError}
        message="Something went wrong whilst retrieving the workout. Please try again later."
        severity="error"
      />
    </>
  );
};
