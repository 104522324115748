import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { searchPages } from "../../util/array";
import { Workout } from "../workout/types";
import { BaseWorkoutPlan, defaultWorkoutPlanState, WorkoutPlan } from "./types";

const workoutPlanSlice = createSlice({
  name: "workoutPlan",
  initialState: defaultWorkoutPlanState,
  reducers: {
    getWorkoutPlansAttempt: (state) => {
      state.workoutPlans.isError = false;
      state.workoutPlans.isLoading = true;
    },
    getWorkoutPlansFailure: (state) => {
      state.workoutPlans.isError = true;
      state.workoutPlans.isLoading = false;
    },
    getWorkoutPlansSuccess: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        workoutPlans: BaseWorkoutPlan[];
      }>
    ) => {
      state.workoutPlans.isError = false;
      state.workoutPlans.isLoading = false;
      if (state.workoutPlans.data) {
        state.workoutPlans.data[action.payload.pageNumber] =
          action.payload.workoutPlans;
      } else {
        state.workoutPlans.data = {
          [action.payload.pageNumber]: action.payload.workoutPlans,
        };
      }
    },
    setWorkoutPlansCount: (state, action: PayloadAction<{ count: number }>) => {
      state.workoutPlans.count = action.payload.count;
    },
    clearWorkoutPlansData: (state) => {
      state.workoutPlans.data = null;
      state.workoutPlans.count = null;
    },
    setCurrentWorkoutPlan: (
      state,
      action: PayloadAction<{ workoutPlan: WorkoutPlan }>
    ) => {
      state.currentWorkoutPlan.data = action.payload.workoutPlan;
    },
    clearCurrentWorkoutPlan: (state) => {
      state.currentWorkoutPlan.data = null;
    },
    replaceWorkoutPlanInPaginatedWorkoutPlans: (
      state,
      action: PayloadAction<BaseWorkoutPlan>
    ) => {
      const result = searchPages(
        { ...state.workoutPlans.data },
        (item) => item.id === action.payload.id
      );
      if (result && state.workoutPlans.data) {
        state.workoutPlans.data[result.pageNumber][result.index] =
          action.payload;
      }
    },
    getWorkoutPlanAttempt: (state) => {
      state.currentWorkoutPlan.isLoading = true;
      state.currentWorkoutPlan.isError = false;
    },
    getWorkoutPlanSuccess: (
      state,
      action: PayloadAction<{ workoutPlan: WorkoutPlan }>
    ) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.data = action.payload.workoutPlan;
    },
    getWorkoutPlanFailure: (state) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = true;
    },
    postWorkoutPlanAttempt: (state) => {
      state.currentWorkoutPlan.isLoading = true;
      state.currentWorkoutPlan.isError = false;
    },
    postWorkoutPlanSuccess: (
      state,
      action: PayloadAction<{ workoutPlan: WorkoutPlan }>
    ) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = false;
      state.currentWorkoutPlan.data = action.payload.workoutPlan;
    },
    postWorkoutPlanFailure: (state) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = true;
    },
    patchWorkoutPlanAttempt: (state) => {
      state.currentWorkoutPlan.isLoading = true;
      state.currentWorkoutPlan.isError = false;
    },
    patchWorkoutPlanSuccess: (
      state,
      action: PayloadAction<{ workoutPlan: WorkoutPlan }>
    ) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = false;
      state.currentWorkoutPlan.data = action.payload.workoutPlan;
    },
    patchWorkoutPlanFailure: (state) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = true;
    },
    deleteWorkoutPlanAttempt: (state) => {
      state.currentWorkoutPlan.isLoading = true;
      state.currentWorkoutPlan.isError = false;
    },
    deleteWorkoutPlanSuccess: (state) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = false;
    },
    deleteWorkoutPlanFailure: (state) => {
      state.currentWorkoutPlan.isLoading = false;
      state.currentWorkoutPlan.isError = true;
    },
    updateWorkoutInAllScheduleItems: (
      state,
      action: PayloadAction<{ workout: Workout }>
    ) => {
      const { workout } = action.payload;
      if (state.currentWorkoutPlan.data) {
        const currentSchedule = state.currentWorkoutPlan.data.schedule;
        const newSchedule = currentSchedule.map((item) => {
          if (item.workout?.id === workout.id) {
            return {
              ...item,
              workout,
            };
          }
          return item;
        });
        state.currentWorkoutPlan.data.schedule = newSchedule;
      }
    },
    duplicateWorkoutPlanAttempt: (state) => {
      state.duplicatePlanAPICallState.isLoading = true;
      state.duplicatePlanAPICallState.isError = false;
    },
    duplicateWorkoutPlanSuccess: (state) => {
      state.duplicatePlanAPICallState.isLoading = false;
      state.duplicatePlanAPICallState.isError = false;
    },
    duplicateWorkoutPlanFailure: (state) => {
      state.duplicatePlanAPICallState.isLoading = false;
      state.duplicatePlanAPICallState.isError = true;
    },
    reset: () => defaultWorkoutPlanState,
  },
});

export const WorkoutPlanReducer = workoutPlanSlice.reducer;
export const workoutPlanActions = workoutPlanSlice.actions;
