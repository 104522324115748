import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";
import { WorkoutPlanDraggableItem } from "../../../../store/plan-builder/types";
import { MyFavouritedWorkoutsListProps } from "./index";
import { Theme, useTheme } from "@mui/material";
import { DRAGGABLE_WORKOUT_WIDTH_SPACING } from "../styles";

const fuzzyOptions: Fuse.IFuseOptions<WorkoutPlanDraggableItem> = {
  shouldSort: true,
  keys: ["workout.name"],
  includeScore: true,
  threshold: 0.5,
};

export const useMyFavouritedWorkoutsList = ({
  myFavouritedWorkoutsListRef: myWorkoutsListRef,
  onClickRefreshButton,
  isLoadingMyWorkouts,
  myWorkouts,
}: MyFavouritedWorkoutsListProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapseMyWorkouts = () => setIsCollapsed(true);
  const expandMyWorkouts = () => setIsCollapsed(false);
  const [workoutsList, setWorkoutsList] = useState(myWorkouts.workouts);
  const theme = useTheme<Theme>();
  const myWorkoutsListWidth = isCollapsed
    ? "60px"
    : theme.spacing(DRAGGABLE_WORKOUT_WIDTH_SPACING + 2 * 2);

  const fuse = useMemo(
    () => new Fuse(myWorkouts.workouts, fuzzyOptions),
    [myWorkouts.workouts]
  );

  const filterWorkoutsBySearchText = (searchString: string) => {
    const fuseResults = fuse.search(searchString).map((result) => result.item);
    setWorkoutsList(() => {
      return searchString.length ? fuseResults : myWorkouts.workouts;
    });
  };

  useEffect(() => {
    setWorkoutsList(myWorkouts.workouts);
  }, [myWorkouts.workouts]);

  return {
    myWorkoutsListRef,
    onClickRefreshButton,
    isLoadingMyWorkouts,
    filterWorkoutsBySearchText,
    workoutsList,
    isCollapsed,
    collapseMyWorkouts,
    expandMyWorkouts,
    myWorkoutsListWidth,
  };
};
