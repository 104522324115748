import { Loop, ViewList, Whatshot } from "@mui/icons-material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { Chip, Tooltip, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { ExerciseBlock } from "../../../store/workout/types";
import { filterUnique } from "../../../util/array";
import { pluralize } from "../../../util/string";
import { convertSecondsToMMSS } from "../../pages/WorkoutBuilder/ExerciseEntryDataGrid/EditCells/config";
import { MuscleGroupChip } from "../../pages/WorkoutBuilder/MuscleGroupChip";
import { REST_EXERCISE_PARAMETER } from "../../pages/WorkoutBuilder/fixtures";
interface Props {
  block: ExerciseBlock;
  showMuscleGroupChips?: boolean;
  showRestChip?: boolean;
}

export const ExerciseBlockChips: React.FC<Props> = ({
  block,
  showMuscleGroupChips = false,
  showRestChip = false,
}) => {
  const isSuperset = block.entrys.length > 1;
  const muscleGroups = useMemo(
    () =>
      block.entrys
        .map((entry) => entry.exercise.muscleGroup)
        .filter(filterUnique),
    [block.entrys]
  );

  const theme = useTheme();

  const restTimeInSeconds = block.entrys[
    block.entrys.length - 1
  ].sets[0].values.find(
    (item) => item.parameter.id === REST_EXERCISE_PARAMETER.id
  )?.value;
  return (
    <>
      {isSuperset && (
        <Chip
          label="Superset"
          icon={<Whatshot style={{ color: theme.palette.primary.main }} />}
          sx={{ bgcolor: "background.default" }}
        />
      )}

      {isSuperset && (
        <Chip
          label={`${block.entrys[0].sets.length} ${pluralize(
            "Round",
            block.entrys[0].sets.length
          )}`}
          icon={<Loop />}
          sx={{ bgcolor: "background.default" }}
        />
      )}
      {!isSuperset && (
        <Chip
          label={`${block.entrys[0].sets.length} ${pluralize(
            "Set",
            block.entrys[0].sets.length
          )}`}
          icon={<ViewList color="action" />}
        />
      )}

      <Tooltip title={"Rest (mm:ss) for each round"} placement="top">
        <span>
          {restTimeInSeconds && showRestChip && (
            <Chip
              label={convertSecondsToMMSS(restTimeInSeconds)}
              icon={<HourglassTopIcon color="action" />}
              sx={{
                bgcolor: isSuperset ? "background.default" : undefined,
              }}
            />
          )}
        </span>
      </Tooltip>
      {showMuscleGroupChips && (
        <>
          {muscleGroups.map((muscleGroup) => (
            <MuscleGroupChip muscleGroup={muscleGroup} key={muscleGroup} />
          ))}
        </>
      )}
    </>
  );
};
