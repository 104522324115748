import React from "react";
import { BadgeAnchor, BadgeImage } from "./StoreBadges.styles";

export const GooglePlayStoreBadge: React.FC = () => {
  return (
    <BadgeAnchor href="https://play.google.com/store/apps/details?id=io.myarc.myarcapp&hl=en_GB&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <BadgeImage src="/app-store-badge/play-store.svg" />
    </BadgeAnchor>
  );
};
