import { useEffect, useMemo } from "react";
import { useGet } from "../../../../hooks/request";
import { ExpandedWorkoutAPI } from "../../../../store/workout/types";
import { transformExpandedWorkoutFromAPI } from "../../../../store/workout/transformer";

export const useWorkoutViewer = (workoutId: string) => {
  const [getWorkout, workoutResource] = useGet<ExpandedWorkoutAPI>(
    `/builder/workout/${workoutId}`
  );

  const workout = useMemo(
    () =>
      workoutResource.data
        ? transformExpandedWorkoutFromAPI(workoutResource.data)
        : null,
    [workoutResource.data]
  );

  useEffect(() => {
    getWorkout();
  }, [getWorkout]);

  return {
    workout,
    isLoading: workoutResource.isLoading,
    isError: workoutResource.isError,
  };
};
