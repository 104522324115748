import { RootStore } from "..";

export const selectCurrentWorkoutPlanResource = (state: RootStore) => {
  return state.workoutPlanState.currentWorkoutPlan;
};

export const selectWorkoutPlansPaginatedResource = (state: RootStore) => {
  return state.workoutPlanState.workoutPlans;
};

export const selectDuplicateWorkoutPlanCallState = (state: RootStore) => {
  return state.workoutPlanState.duplicatePlanAPICallState;
};
