import React, { useMemo } from "react";
import { STRIPE_PAYOUT_STATUSES } from "./utils";
import { Chip } from "@mui/material";
import { StripePayoutStatus } from "../../../../store/payouts/types";

interface Props {
  status: StripePayoutStatus;
}
export const PayoutStatusChip: React.FC<Props> = ({ status }) => {
  const statusConfig = useMemo(() => STRIPE_PAYOUT_STATUSES[status], [status]);
  return (
    <Chip
      label={statusConfig.displayName}
      color={statusConfig.color as any}
      size="small"
      icon={<statusConfig.icon />}
    />
  );
};
