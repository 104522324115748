import React from "react";
import { PageHeaderSkeleton } from "../../common/PageHeader/PageHeaderSkeleton";
import { PageTemplate } from "../../common/PageTemplate";
import { Box, Skeleton } from "@mui/material";
export const WorkoutBuilderLoading: React.FC = () => {
  return (
    <PageTemplate data-testid={"t_workout_builder_loader"}>
      <PageHeaderSkeleton />
      <Box gap={2} display="flex" flexDirection={"column"}>
        <Skeleton variant="rounded" height={200} />
        <Skeleton variant="rounded" height={400} />
        <Skeleton variant="rounded" height={300} />
        <Skeleton variant="rounded" height={200} />
      </Box>
    </PageTemplate>
  );
};
