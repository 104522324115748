import { Grid } from "@mui/material";
import React from "react";
import { PlaylistCardLoading } from "./PlaylistCard/PlaylistCardLoading";

interface Props {}

export const MyWorkoutPlansLoading: React.FC<Props> = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      py={2}
      alignItems="stretch"
    >
      {[...new Array(6)].map((_, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
          <PlaylistCardLoading />
        </Grid>
      ))}
    </Grid>
  );
};
