import { Skeleton, Stack } from "@mui/material";
import React from "react";
import { getRandomNumber } from "../../../util/number/random";

export const WorkoutSkeleton: React.FC = () => {
  return (
    <Stack gap={2}>
      {[...new Array(3)].map((_, index) => (
        <Stack key={index} gap={1}>
          <Stack flexDirection={"row-reverse"} gap={1}>
            {[...new Array(getRandomNumber(2, 4))].map((_, index) => (
              <Skeleton key={index} variant="rounded" width={75} />
            ))}
          </Stack>
          <Skeleton variant="rounded" height={150} />
        </Stack>
      ))}
    </Stack>
  );
};
