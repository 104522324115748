import { useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedState } from "../../../../hooks/useDebouncedState";
import { useStore } from "../../../../store";
import { CreatorExerciseAPI } from "../../../../store/exercises/types";
import { PaginatedAPI } from "../../../../store/types";
import {
  ClearExerciseSearchResults,
  GetExercisesBySearchQuery,
} from "../../../../store/workout-builder/actions";
import { selectExerciseSearchResultsResource } from "../../../../store/workout-builder/selectors";

const MIN_QUERY_LENGTH = 3;
const MAX_PAGES = 5;
export const useExerciseAutocomplete = () => {
  const [select, dispatch] = useStore();
  const exerciseSearchResultsResource = select(
    selectExerciseSearchResultsResource
  );

  const hasMoreResults = useRef<boolean>();

  const updateHasMoreResults = (
    result: PaginatedAPI<CreatorExerciseAPI> | undefined
  ) => {
    hasMoreResults.current = !!result?.next;
    return;
  };
  const [, setPageNumber] = useState(1);

  const [searchQuery, debouncedSearchQuery, setSearchQuery] = useDebouncedState(
    "",
    500
  );

  const getSearchedExercisesByPage = useCallback(
    async (pageNumber: number) => {
      if (debouncedSearchQuery.length >= MIN_QUERY_LENGTH) {
        const result = await GetExercisesBySearchQuery(
          dispatch,
          debouncedSearchQuery,
          pageNumber
        );
        updateHasMoreResults(result);
        return result;
      }
    },
    [debouncedSearchQuery, dispatch]
  );

  const getFirstPageExercisesBySearchQuery = useCallback(
    async () => getSearchedExercisesByPage(1),
    [getSearchedExercisesByPage]
  );

  const getNextPage = () => {
    if (hasMoreResults.current) {
      setPageNumber((x) => {
        const newPageNumber = x + 1;
        if (newPageNumber > MAX_PAGES) return x;
        getSearchedExercisesByPage(newPageNumber).then(updateHasMoreResults);
        return newPageNumber;
      });
    }
  };

  const clearSearchResults = useCallback(
    () => ClearExerciseSearchResults(dispatch),
    [dispatch]
  );

  useEffect(() => {
    clearSearchResults();
    getFirstPageExercisesBySearchQuery();
  }, [clearSearchResults, getFirstPageExercisesBySearchQuery]);

  return {
    exerciseSearchResultsResource,
    searchQuery,
    setSearchQuery,
    getNextPage,
  };
};
