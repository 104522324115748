import { useSearchParams } from "react-router-dom";

type useWorkoutPlanParamReturn = [boolean, (isOpen: boolean) => void];

export const useWorkoutPlanParam = (id: string): useWorkoutPlanParamReturn => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pwdId = searchParams.get("pwd_id");
  const isPeekingWorkout = pwdId === id;

  const setIsPeekingWorkout = (isOpen: boolean) => {
    if (isOpen) {
      setSearchParams({ pwd_id: id });
    } else {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.delete("pwd_id");
        return newParams;
      });
    }
  };

  return [isPeekingWorkout, setIsPeekingWorkout];
};
