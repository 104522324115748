import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";
import { DesktopOnlyMessage } from "../components/pages/DesktopOnlyMessage";

interface Props {
  shouldAllowMobile: boolean;
}
export const MobileGate: React.FC<PropsWithChildren<Props>> = ({
  children,
  shouldAllowMobile,
}) => {
  const theme = useTheme();
  const isMobileBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobileBreakpoint && !shouldAllowMobile ? (
        <DesktopOnlyMessage />
      ) : (
        children
      )}
    </>
  );
};
