import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import {
  ClearCurrentWorkoutPlan,
  GetWorkoutPlan,
} from "../../../store/workout-plan/actions";
import { selectCurrentWorkoutPlanResource } from "../../../store/workout-plan/selectors";

export const useWorkoutPlan = () => {
  const [select, dispatch] = useStore();
  const {
    data: workoutPlan,
    isLoading,
    isError,
  } = select(selectCurrentWorkoutPlanResource);
  const [isShowingErrorMessage, hideErrorMessage] =
    useErrorMessageState(isError);
  const navigate = useNavigate();
  const query = useQuery();
  const showNoEditMessage = useMemo(
    () => query.get("no_edit") === "true",
    [query]
  );

  const [isShowingNoEditWarn, hideNoEditWarning] =
    useErrorMessageState(showNoEditMessage);

  const { id: playlistIdFromPath } = useParams();
  const playlistId = playlistIdFromPath!;

  useEffect(() => {
    if (!workoutPlan || workoutPlan?.id !== playlistId) {
      GetWorkoutPlan(dispatch, playlistId);
    }
  }, [dispatch, playlistId, workoutPlan]);

  const workoutPlanEditPath = `/workout-plans/edit/${playlistId}`;

  const navigateToEditWorkoutPlanPage = () => {
    navigate(workoutPlanEditPath);
  };

  const allWorkoutPlansPath = `/workout-plans`;
  const navigateToAllPlans = () => {
    ClearCurrentWorkoutPlan(dispatch);
    navigate(allWorkoutPlansPath);
  };

  return {
    workoutPlan,
    isLoading,
    isShowingErrorMessage,
    hideErrorMessage,
    navigateToEditWorkoutPlanPage,
    workoutPlanEditPath,
    isShowingNoEditWarn,
    hideNoEditWarning,
    navigateToAllPlans,
  };
};
