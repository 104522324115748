import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import { SignIn } from "../../../store/user/actions";
import { selectUser } from "../../../store/user/selectors";
import { isEmailAddress } from "../../../util/validation";

export const useSignIn = () => {
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isSignInError, setIsSignInError] = useState<boolean>(false);
  const [select, dispatch] = useStore();
  const navigate = useNavigate();
  const queryParams = useQuery();

  const user = select(selectUser);
  const resetSignInError = () => {
    setIsSignInError(false);
  };

  const isEmailAddressValid = isEmailAddress(emailAddress);

  const canSignIn =
    !!emailAddress.length && !!password.length && isEmailAddressValid;

  const onChangeEmailAddress = (input: string) => setEmailAddress(input);

  const onChangePassword = (input: string) => setPassword(input);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    resetSignInError();
    try {
      const user = await SignIn(dispatch, emailAddress, password);
      if (user.trainerProfile) {
        const next = queryParams.get("next");
        next ? navigate(next) : navigate("/workout-plans");
      } else {
        navigate("/app-store-showcase");
      }
    } catch (e) {
      setIsSignInError(true);
    }
  };

  return {
    emailAddress,
    onChangeEmailAddress,
    password,
    onChangePassword,
    onSubmit,
    canSignIn,
    isEmailAddressValid,
    isSignInError,
    resetSignInError,
    isSignInLoading: user.isLoading,
  };
};
