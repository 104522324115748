import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  ExerciseEntry as ExerciseEntryType,
  ExerciseParameter,
  ExerciseSet,
} from "../../../../store/workout/types";
import { ExerciseEntry as Component } from "./ExerciseEntry";
import { useExerciseEntry } from "./useExerciseEntry";
import { CreatorExercise } from "../../../../store/exercises/types";

export interface ExerciseEntryProps {
  entry: ExerciseEntryType;
  deleteEntry: () => void;
  updateExerciseType: (exerciseType: ExerciseParameter) => void;
  deleteSet: (setIndex: number) => void;
  updateSet: (set: ExerciseSet) => void;
  duplicateSet: (setIndex: number) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  updateNotes: (value: string) => void;
  disableRest?: boolean;
  updateVideo: (videoId: number | null) => void;
  saveWorkoutSectionsNameAndDescriptionThenNavigate: (
    exercise: CreatorExercise
  ) => void;
  isExpanded: boolean;
  toggleExpanded: () => void;
}

export const ExerciseEntry: React.FC<ExerciseEntryProps> = (props) => {
  const componentProps = useExerciseEntry(props);
  return <Component {...componentProps} />;
};
