import React, { useState } from "react";
import { useDialog } from "../../common/Dialog/hooks";
import { UploadArtworkDialogProps } from "./UploadArtworkDialog";

type RequiredUploadArtworkDialogProps = Pick<
  UploadArtworkDialogProps,
  "isUploading"
> &
  Omit<UploadArtworkDialogProps, "artworkSrc">;

type OptionalCustomProps = Pick<UploadArtworkDialogProps, "isUploading"> &
  Partial<Omit<UploadArtworkDialogProps, "artworkSrc">>;

interface UseUploadArtworkDialogConfig {
  props: RequiredUploadArtworkDialogProps;
  openDialog: () => void;
}

export const useUploadArtworkDialog = (
  handleSubmit: (selectedFile: File | null) => Promise<void>,
  customProps: OptionalCustomProps
): UseUploadArtworkDialogConfig => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isVisible, openDialog, closeDialog] = useDialog();

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    file && setSelectedFile(file);
  };

  return {
    openDialog,
    props: {
      selectedFile,
      isOpen: isVisible,
      onClose: closeDialog,
      onChangeFile: handleChangeFile,
      handleSave: () => {
        handleSubmit(selectedFile).finally(() => closeDialog());
      },
      ...customProps,
    },
  };
};
