import { Box, BoxProps, Button, Tooltip } from "@mui/material";
import React from "react";
import { BaseText } from "../BaseText";

export const ActionButtonsContainer: React.FC<
  React.PropsWithChildren<BoxProps>
> = ({ children, ...props }) => {
  return (
    <Box display="flex" flexDirection="row" gap={1} {...props}>
      {children}
    </Box>
  );
};

interface SuccessActionButtonProps {
  isActive: boolean;
  onClick: () => void;
  text: string;
  inactiveText?: string;
  loadingText?: string;
  label?: string;
  inactiveLabel?: string;
  loadingLabel?: string;
}

export const SuccessActionButton: React.FC<SuccessActionButtonProps> = ({
  isActive,
  onClick,
  text,
  inactiveText,
  label,
  inactiveLabel,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Button
        variant={isActive ? "contained" : "outlined"}
        onClick={() => {
          isActive && onClick();
        }}
        disabled={!isActive}
        color="success"
        sx={({ palette }) => ({
          "&.Mui-disabled": {
            borderColor: palette.success.main,
            borderWidth: 1,
            color: palette.success.main,
          },
        })}
      >
        {isActive ? text : inactiveText || text}
      </Button>

      {!!label && (
        <BaseText variant="caption" color="text.secondary">
          {isActive ? label : inactiveLabel || label}
        </BaseText>
      )}
    </Box>
  );
};

interface OutlinedActionButtonProps {
  isDisabled: boolean;
  onClick: () => void;
  text: string;
  disabledText?: string;
  tooltipText?: string;
  disabledTooltipText?: string;
}

export const OutlinedActionButton: React.FC<OutlinedActionButtonProps> = ({
  isDisabled,
  onClick,
  disabledText,
  text,
  tooltipText,
  disabledTooltipText,
}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Tooltip title={isDisabled ? disabledTooltipText : tooltipText}>
        <span>
          <Button variant="outlined" onClick={onClick} disabled={isDisabled}>
            {isDisabled ? disabledText || text : text}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
