import { useCallback } from "react";
import { usePost } from "../../../hooks/request";
import { DispatchFn } from "../../../store";
import { transformWorkoutListsIntoScheduleAPI } from "../../../store/plan-builder/transformer";
import { WorkoutList } from "../../../store/plan-builder/types";
import { PatchWorkoutPlan } from "../../../store/workout-plan/actions";
import { WriteableScheduleItem } from "../../../store/workout-plan/types";
import {
  ExpandedScheduleItem,
  ScheduleItem,
} from "../../../store/workout/types";
import { randomUUID } from "../../../util/uuid";

export interface UpdateWorkoutPlanHookArgs {
  dispatchStore: DispatchFn;
  playlistId: string;
  workoutLists: WorkoutList[];
  currentSchedule: WriteableScheduleItem[];
  hasPlanBuilderStateChanged: boolean;
  navigateToPlaylistWorkoutDay: (playlistWorkoutDayId: string) => void;
}

interface UsePostBodyInterface {
  playlist: number;
  day: number;
  workout: { name: string };
}

export const useUpdateWorkoutPlan = ({
  dispatchStore,
  playlistId,
  workoutLists,
  currentSchedule,
  hasPlanBuilderStateChanged,
  navigateToPlaylistWorkoutDay,
}: UpdateWorkoutPlanHookArgs) => {
  const updateWorkoutPlanName = async (newName: string) => {
    playlistId &&
      (await PatchWorkoutPlan(dispatchStore, playlistId, { name: newName }));
  };

  const updateWorkoutPlanDescription = async (newDescription: string) => {
    playlistId &&
      (await PatchWorkoutPlan(dispatchStore, playlistId, {
        description: newDescription,
      }));
  };

  const saveWorkoutPlanChanges = async (
    inputSchedule?: WriteableScheduleItem[]
  ) => {
    const schedule =
      inputSchedule ?? transformWorkoutListsIntoScheduleAPI(workoutLists);
    if (playlistId) {
      await PatchWorkoutPlan(dispatchStore, playlistId, { schedule });
    }
  };

  const [_postPlaylistWorkoutDay, playlistWorkoutDayResource] = usePost<
    ExpandedScheduleItem,
    UsePostBodyInterface
  >("", {} as any);

  const createPlaylistWorkoutDay = useCallback(
    async (dayNumber: number, name: string) => {
      const newWorkoutUUID = randomUUID();
      let playlistWorkoutDay: ScheduleItem | undefined;
      playlistWorkoutDay = await _postPlaylistWorkoutDay(
        "/builder/playlist-workout-day/",
        {
          data: {
            playlist: playlistId,
            day: dayNumber,
            workout: { id: newWorkoutUUID, name },
          },
        }
      );
      if (hasPlanBuilderStateChanged) {
        const scheduleWithNewWorkout: WriteableScheduleItem[] = [
          ...currentSchedule.slice(0, dayNumber - 1),
          {
            day: dayNumber,
            workout: newWorkoutUUID,
          },
          ...currentSchedule.slice(dayNumber - 1),
        ];
        const workoutPlan = await PatchWorkoutPlan(dispatchStore, playlistId, {
          schedule: scheduleWithNewWorkout,
        });
        playlistWorkoutDay = workoutPlan?.schedule.find(
          (item) => item.weekAndDay.dayNumber === dayNumber
        );
      }
      if (playlistWorkoutDay) {
        navigateToPlaylistWorkoutDay(playlistWorkoutDay.id);
      }
    },
    [
      _postPlaylistWorkoutDay,
      currentSchedule,
      dispatchStore,
      hasPlanBuilderStateChanged,
      navigateToPlaylistWorkoutDay,
      playlistId,
    ]
  );

  return {
    updateWorkoutPlanName,
    updateWorkoutPlanDescription,
    saveWorkoutPlanChanges,
    createPlaylistWorkoutDay,
    playlistWorkoutDayResource,
  };
};
