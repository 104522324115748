import { Stack, Typography, useTheme } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import React from "react";
import { PublishPlanCheckModalProps } from ".";
import { BaseAlertDialog } from "../../../common/Dialog/BaseAlertDialog";
import { BaseStepperDialog } from "../../../common/Dialog/BaseStepperDialog";
import { SnackbarAlert } from "../../../common/SnackbarAlert";
import { usePublishPlanCheckModal } from "./usePublishPlanCheckModal";
import Confetti from "react-confetti";

interface ComponentAndExternalProps
  extends ReturnType<typeof usePublishPlanCheckModal>,
    PublishPlanCheckModalProps {}

export const PublishPlanCheckModal: React.FC<ComponentAndExternalProps> = ({
  isOpen,
  workoutPlan,
  onClose,
  currentStep,
  currentStepIndex,
  resetPublishPlanCheck,
  closeModalAndRefreshPlans,
  PUBLISH_PLAN_CHECK_STEPS,
  formattedSteps,
  isShowingAPIErrorMessage,
  hideAPIErrorMessage,
}) => {
  const theme = useTheme();
  if (workoutPlan.publishedAt) {
    return (
      <>
        <BaseAlertDialog
          isOpen={isOpen}
          title="Congratulations!"
          onClose={closeModalAndRefreshPlans}
          onClickButton={closeModalAndRefreshPlans}
          buttonText="Close"
        >
          <Confetti
            colors={[
              theme.palette.primary.main,
              theme.palette.primary.light,
              theme.palette.neutral.main,
              theme.palette.neutral.light,
            ]}
          />
          <DialogContentText>
            {`You've just published this plan!`}{" "}
          </DialogContentText>
          <Typography variant="h4" sx={{ my: 2 }}>
            {workoutPlan.name}
          </Typography>
        </BaseAlertDialog>
      </>
    );
  }
  return (
    <>
      <BaseStepperDialog
        title={PUBLISH_PLAN_CHECK_STEPS[currentStep].title}
        isOpen={isOpen}
        onClose={onClose}
        steps={formattedSteps}
        currentStepIndex={currentStepIndex}
        onExitedTransition={resetPublishPlanCheck}
      >
        <DialogContentText
          id="dialog-description"
          sx={{ fontWeight: "light", typography: "body2" }}
        >
          {PUBLISH_PLAN_CHECK_STEPS[currentStep].subtitle}
        </DialogContentText>
        <Stack alignItems={"center"}>
          {PUBLISH_PLAN_CHECK_STEPS[currentStep].component(workoutPlan)}
        </Stack>
      </BaseStepperDialog>
      <SnackbarAlert
        close={hideAPIErrorMessage}
        isOpen={isShowingAPIErrorMessage}
        severity="error"
        message="Oops! Something went wrong when updating this workout plan! Please try again later."
      />
    </>
  );
};
