import CheckIcon from "@mui/icons-material/Check";
import { Chip, ChipProps } from "@mui/material";
import React from "react";

interface StatusChipProps extends ChipProps {
  active?: boolean;
}

const TransformationChip: React.FC<StatusChipProps> = ({
  active = false,
  ...props
}) => {
  return (
    <Chip
      {...props}
      icon={active ? <CheckIcon sx={{ fontSize: "16px" }} /> : undefined}
      variant={active ? "filled" : "outlined"}
      sx={{
        fontWeight: 500,
        color: "text.primary",
        backgroundColor: active ? "primary.mid" : "transparent",
        borderRadius: "8px",
        fontSize: "14px",
        "& .MuiChip-icon": {
          color: "text.primary",
        },
        "&:hover": {
          backgroundColor: active ? "primary.mid" : "primary.xlight",
        },
      }}
    />
  );
};

export default TransformationChip;
