import {
  APICallState,
  createDefaultPaginatedResource,
  createDefaultResource,
  PaginatedResource,
  Resource,
} from "../types";
import { ScheduleItem, ScheduleItemAPI } from "../workout/types";

export interface BaseWorkoutPlanAPI {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  updated_at: string;
  published_at: string | null;
  schedule: ScheduleItemAPI[];
  assignations: number;
}
export interface WorkoutPlanAPI extends BaseWorkoutPlanAPI {
  schedule: ScheduleItemAPI[];
}

export interface BaseWorkoutPlan {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  updatedAt: string;
  publishedAt: string | null;
  assignations: number;
}

export interface WorkoutPlan extends BaseWorkoutPlan {
  schedule: ScheduleItem[];
}

export interface WriteableScheduleItem {
  day: number;
  workout: string | null;
}

export interface PatchableWorkoutPlan
  extends Partial<Pick<BaseWorkoutPlan, "name" | "description">> {
  published_at?: string | null;
  schedule?: WriteableScheduleItem[];
}

export interface WorkoutPlanState {
  workoutPlans: PaginatedResource<BaseWorkoutPlan>;
  currentWorkoutPlan: Resource<WorkoutPlan>;
  currentScheduleItem: Resource<ScheduleItem>;
  duplicatePlanAPICallState: APICallState;
}

export const defaultWorkoutPlanState: WorkoutPlanState = {
  workoutPlans: createDefaultPaginatedResource(),
  currentWorkoutPlan: createDefaultResource(),
  currentScheduleItem: createDefaultResource(),
  duplicatePlanAPICallState: { isError: false, isLoading: false },
};
