import { Help } from "@mui/icons-material";
import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {
  title: string;
  content: React.ReactNode;
  extraInfo?: string;
}

export const BalanceStatistic: React.FC<Props> = ({
  content,
  title,
  extraInfo,
}) => {
  return (
    <Card elevation={1}>
      <CardContent>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems="center"
          mb={1.5}
        >
          <Typography color="text.secondary">{title}</Typography>
          {extraInfo && (
            <Tooltip title={extraInfo} placement="top">
              <IconButton size="small">
                <Help color="disabled" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        {content}
      </CardContent>
    </Card>
  );
};
