import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
  subtitle?: string;
}

export const WalletOnboardingTitleSubtitle: React.FC<Props> = ({
  title,
  subtitle,
}) => {
  return (
    <Box width="40%">
      <Typography variant="h5" fontWeight={700} align="center" gutterBottom>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="body1"
          color="text.secondary"
          align="center"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
