import { Alert, AlertTitle, Box, Typography } from "@mui/material";

import React, { useMemo } from "react";
import { pluralize } from "../../../../util/string";
import { PieChartDatum } from "../../../common/Charts/types";
import { SubscripionBreakdownPieChart } from "../Charts/SubscriptionBreakdownPieChart";
import { DISPLAYED_SUBSCRIPTION_STATUSES, SubscriptionStatus } from "../types";

interface SubscriptionStatusBreakdownProps {
  data: PieChartDatum<SubscriptionStatus>[];
}

export const SubscriptionStatusBreakdown: React.FC<
  SubscriptionStatusBreakdownProps
> = ({ data }) => {
  const activeUserValue =
    data.find((item) => item.id === SubscriptionStatus.ACTIVE)?.value ?? 0;

  const pastDueUserValue =
    data.find((item) => item.id === SubscriptionStatus.PAST_DUE)?.value ?? 0;

  const pieChartData = useMemo(
    () =>
      data.filter((item) =>
        DISPLAYED_SUBSCRIPTION_STATUSES.includes(item.id as SubscriptionStatus)
      ),
    [data]
  );
  const formattedActiveLabelText = useMemo(
    () => `${activeUserValue} paying ${pluralize("fan", activeUserValue)}`,
    [activeUserValue]
  );
  const formattedActiveSubtitle = useMemo(
    () => `You have ${formattedActiveLabelText}!`,
    [formattedActiveLabelText]
  );

  const formattedOverdueLabelText = useMemo(
    () => `${pastDueUserValue} overdue ${pluralize("fan", pastDueUserValue)}`,
    [pastDueUserValue]
  );

  return (
    <Box
      sx={{
        height: "70%",
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 1,
      }}
    >
      <SubscripionBreakdownPieChart data={pieChartData} />
      <Alert severity="success" sx={{ fontSize: "0.8rem" }}>
        <AlertTitle sx={{ fontWeight: 800 }}>
          <Typography fontWeight={800} fontSize={"1rem"}>
            {formattedActiveLabelText}
          </Typography>
        </AlertTitle>
        {formattedActiveSubtitle}
      </Alert>
      <Box display="flex" justifyContent={"center"}>
        <Typography color="error" fontWeight={800} fontSize={"0.8rem"}>
          {formattedOverdueLabelText}
        </Typography>
      </Box>
    </Box>
  );
};
