import { randomUUID } from "../../util/uuid";
import { Workout } from "../workout/types";
export interface WorkoutPlanDraggableItem {
  workout: Workout | null;
  uuid: string;
}

export type WorkoutIdOrNull = string | null;

export interface WorkoutList {
  id: string;
  workouts: WorkoutPlanDraggableItem[];
}

export interface PlanBuilder {
  workoutLists: WorkoutList[];
}

export interface PlanBuilderState {
  planBuilder: PlanBuilder;
}

export const defaultPlanBuilderState: PlanBuilderState = {
  planBuilder: { workoutLists: [{ id: randomUUID(), workouts: [] }] },
};

export const MAX_ITEMS_IN_LIST = 7;

export class ListMaxLengthViolationError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "ListMaxLengthViolationError";
  }
}
