import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

export const PageHeaderSkeleton: React.FC = () => {
  return (
    <Box
      width={"100%"}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column">
        <Typography component="h1" variant="h4" fontWeight="800">
          <Skeleton variant="text" width={200} />
        </Typography>
        <Typography component="h2" variant="subtitle1" color="textSecondary">
          <Skeleton variant="text" width={150} />
        </Typography>
      </Box>
    </Box>
  );
};
