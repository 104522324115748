import React from "react";
import { useWorkoutViewer } from "./useWorkoutViewer";
import { WorkoutViewer as Component } from "./WorkoutViewer";

interface Props {
  workoutId: string;
}

export const WorkoutViewer: React.FC<Props> = ({ workoutId }) => {
  const props = useWorkoutViewer(workoutId);
  return <Component {...props} />;
};
