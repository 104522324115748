import { Lightbulb } from "@mui/icons-material";
import { Alert, Box, DialogContent, DialogContentText } from "@mui/material";
import React from "react";
import { BaseConfirmDialog } from "../../common/Dialog/BaseConfirmDialog";
import { ImageInput } from "../../common/ImageInput";

export interface UploadArtworkDialogProps {
  isOpen: boolean;
  artworkSrc: string | null;
  selectedFile: File | null;
  onClose: () => void;
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  isUploading: boolean;
}
export const UploadArtworkDialog: React.FC<UploadArtworkDialogProps> = ({
  artworkSrc,
  selectedFile,
  onClose,
  onChangeFile,
  handleSave,
  isOpen,
  isUploading,
}) => {
  const imageSrc =
    (selectedFile && URL.createObjectURL(selectedFile)) ?? artworkSrc ?? null;
  return (
    <BaseConfirmDialog
      onClose={onClose}
      isOpen={isOpen}
      title="Upload Image"
      onClickCancel={onClose}
      onClickConfirm={handleSave}
      confirmText="Save"
      confirmButtonProps={{ disabled: !selectedFile }}
      isLoading={isUploading}
    >
      <DialogContent>
        <DialogContentText>
          Give your workout plan some artwork.
        </DialogContentText>
        <Alert severity="info" variant="outlined" icon={<Lightbulb />}>
          {"Don't forget that square images look best!"}
        </Alert>
        <Box display={"flex"} justifyContent={"center"}>
          <ImageInput imageSrc={imageSrc} onChangeFile={onChangeFile} />
        </Box>
      </DialogContent>
    </BaseConfirmDialog>
  );
};
