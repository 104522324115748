import * as UpChunk from "@mux/upchunk";
import { useCallback, useState } from "react";

const UPLOAD_VIDEO_CHUNK_SIZE = 30720;

export const useHandleUpChunk = () => {
  const [progress, setProgress] = useState(0);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleUpload = useCallback(
    (
      video: File,
      endpoint: string,
      onSuccess: () => Promise<void>,
      onError: () => void
    ) => {
      setIsInProgress(true);
      const upload = UpChunk.createUpload({
        endpoint,
        file: video,
        chunkSize: UPLOAD_VIDEO_CHUNK_SIZE,
      });

      upload.on("success", async () => {
        await onSuccess();
        setIsInProgress(false);
        setIsSuccess(true);
      });

      upload.on("error", () => {
        onError();
        setIsInProgress(false);
        setIsError(true);
      });

      upload.on("progress", (progress) => {
        setIsInProgress(true);
        setProgress(progress.detail);
      });
    },
    []
  );

  return {
    progress,
    isSuccess,
    isError,
    isInProgress,
    handleUpload,
  };
};
