import React, { useCallback, useMemo } from "react";
import { NewWorkoutDialogProps } from "./types";
import { BaseConfirmDialog } from "./BaseConfirmDialog";
import {
  Alert,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import { isWorkoutNameValid } from "../../../util/validation/workoutValidations";

export const NewWorkoutDialog: React.FC<NewWorkoutDialogProps> = ({
  inputFieldId,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const showDayWeekWarning = useMemo(() => {
    return !isWorkoutNameValid(inputValue);
  }, [inputValue]);

  const handleConfirmClick = useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event?.preventDefault();
      inputValue && props.onSubmitText?.(inputValue);
      props.onClickConfirm?.(event);
    },
    [inputValue, props]
  );

  return (
    <BaseConfirmDialog
      {...props}
      title="First, choose a workout name"
      onClickConfirm={handleConfirmClick}
      confirmButtonProps={{ ...props.confirmButtonProps, type: "submit" }}
    >
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          sx={{ fontWeight: "light", typography: "body2" }}
        >
          {"Don't worry, you can change this later if you want"}
        </DialogContentText>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          id={inputFieldId}
          label={"Enter workout name"}
          placeholder={"My New Workout"}
          fullWidth
          autoFocus
          variant="outlined"
          margin="normal"
          autoComplete="off"
        />
      </DialogContent>

      {showDayWeekWarning ? (
        <Alert severity="info">
          You don’t need to include the words ‘Week’ and ‘Day’ in your workout
          name - this will automatically be set as you create and modify your
          plan.
        </Alert>
      ) : null}
    </BaseConfirmDialog>
  );
};
