import { Box, DialogActions, DialogTitle, LinearProgress } from "@mui/material";
import React from "react";
import { BaseDialog } from "./BaseDialog";
import { DialogSecondaryButton, DialogPrimaryButton } from "./DialogButtons";
import { BaseConfirmDialogProps } from "./types";

export const BaseConfirmDialog: React.FC<
  React.PropsWithChildren<BaseConfirmDialogProps>
> = ({
  title,
  isOpen,
  onClose,
  cancelText = "Cancel",
  confirmText = "Confirm",
  onClickCancel,
  onClickConfirm,
  confirmButtonProps,
  cancelButtonProps,
  isLoading,
  children,
}) => {
  return (
    <BaseDialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 2,
          width: "60%",
          maxWidth: 500,
        },
      }}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <Box component="form">
        {children}
        <DialogActions>
          <DialogSecondaryButton onClick={onClickCancel} {...cancelButtonProps}>
            {cancelText}
          </DialogSecondaryButton>
          <DialogPrimaryButton
            onClick={onClickConfirm}
            disabled={isLoading}
            {...confirmButtonProps}
          >
            {confirmText}
          </DialogPrimaryButton>
        </DialogActions>
      </Box>
      {isLoading && <LinearProgress color={confirmButtonProps?.color} />}
    </BaseDialog>
  );
};
