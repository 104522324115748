import { Box, Container } from "@mui/material";
import React from "react";
import { Copyright } from "./Copyright";

interface Props {}
export const Footer: React.FC<Props> = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Copyright />
      </Container>
    </Box>
  );
};
