import { Grid } from "@mui/material";
import React from "react";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";
import { PlaylistCard } from "../PlaylistCard";

interface Props {
  workoutPlans: BaseWorkoutPlan[];
}

export const PlaylistCardsGrid: React.FC<Props> = ({ workoutPlans }) => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      py={2}
      alignItems="stretch"
    >
      {workoutPlans.map((item) => (
        <Grid item xs={2} sm={4} md={4} key={item.id}>
          <PlaylistCard workoutPlan={item} />
        </Grid>
      ))}
    </Grid>
  );
};
