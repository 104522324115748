import {
  APICallState,
  PaginatedResource,
  Resource,
  createDefaultPaginatedResource,
  createDefaultResource,
  successLoadingErrorState,
} from "../types";

export enum MuxVideoStatus {
  PROCESSING = "processing",
  READY = "ready",
  ERRORED = "errored",
}

export enum MuxPlaybackPolicy {
  SIGNED = "signed",
  PUBLIC = "public",
}

export interface MuxVideoAPI {
  title: string;
  video_token: string;
  thumbnail_token: string;
  status: MuxVideoStatus;
  asset_id: string;
  playback_id: string;
  playback_policy: MuxPlaybackPolicy;
  duration: number | null;
}

export interface CreatorVideoAPI {
  id: number;
  youtube_link: string | null;
  created_by: number;
  created_at: string;
  is_default_video: boolean;
  mux_video: MuxVideoAPI | null;
}

export interface CreatorNoteAPI {
  id: number;
  content: string;
  created_by: number;
}

export interface MuxVideo {
  title: string;
  videoToken: string;
  thumbnailToken: string;
  status: MuxVideoStatus;
  assetId: string;
  playbackId: string;
  playbackPolicy: MuxPlaybackPolicy;
  duration: number | null;
}

export interface CreatorVideo {
  id: number;
  youtubeLink: string | null;
  createdBy: number;
  createdAt: string;
  isDefault: boolean;
  muxVideo: MuxVideo | null;
}

export interface CreatorNote {
  id: number;
  content: string;
  createdBy: number;
}

export interface CreatorExerciseAPI {
  id: number;
  name: string;
  muscle_group: string;
  default_note: string;
  weighted: boolean;
  creator_notes: CreatorNoteAPI[];
  creator_videos: CreatorVideoAPI[];
}

export interface CreateExerciseVideoUploadLinkAPI {
  id: string;
  url: string;
}

export interface CreatorExercise {
  id: number;
  name: string;
  muscleGroup: string;
  defaultNote: string;
  isWeighted: boolean;
  creatorNotes: CreatorNote[];
  creatorVideos: CreatorVideo[];
}

export interface ExerciseState {
  exercises: PaginatedResource<CreatorExercise>;
  currentCreatorExercise: Resource<CreatorExercise>;
  updateCreatorExerciseVideo: APICallState;
  updateCreatorExerciseNote: APICallState;
  createExerciseVideo: APICallState;
  deleteExerciseVideo: APICallState;
}

export const defaultExerciseState: ExerciseState = {
  exercises: createDefaultPaginatedResource(),
  currentCreatorExercise: createDefaultResource(),
  updateCreatorExerciseVideo: successLoadingErrorState,
  updateCreatorExerciseNote: successLoadingErrorState,
  createExerciseVideo: successLoadingErrorState,
  deleteExerciseVideo: successLoadingErrorState,
};
