import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useStore } from "../../../store";
import { GetWorkout, PatchWorkout } from "../../../store/workout/actions";
import {
  selectRetrieveWorkoutResource,
  selectUpdateWorkoutResource,
} from "../../../store/workout/selectors";
import { ExpandedWorkout, WorkoutSection } from "../../../store/workout/types";
import { generateEmptyWorkoutSection } from "../WorkoutBuilder/utils";

export const useEditWorkoutPage = () => {
  const { workoutId: workoutIdString } = useParams();
  const workoutIdFromURL = workoutIdString!;

  const [select, dispatch] = useStore();

  const [expandedWorkout, setExpandedWorkout] =
    useState<ExpandedWorkout | null>(null);

  const getExpandedWorkoutFromAPI = useCallback(async () => {
    if (workoutIdFromURL) {
      const expandedWorkoutFromAPI = await GetWorkout(
        dispatch,
        workoutIdFromURL
      );
      expandedWorkoutFromAPI &&
        setExpandedWorkout(() => ({
          ...expandedWorkoutFromAPI,
          sections: expandedWorkoutFromAPI.sections.length
            ? expandedWorkoutFromAPI.sections
            : [generateEmptyWorkoutSection()],
        }));
    }
  }, [dispatch, workoutIdFromURL]);

  const updateName = useCallback(
    async (name: string) => {
      if (expandedWorkout) {
        const updatedWorkout = await PatchWorkout(
          dispatch,
          expandedWorkout.id,
          { name }
        );
        updatedWorkout && setExpandedWorkout(updatedWorkout);
      }
    },
    [dispatch, expandedWorkout]
  );

  const updateDescription = useCallback(
    async (description: string) => {
      if (expandedWorkout) {
        const updatedWorkout = await PatchWorkout(
          dispatch,
          expandedWorkout.id,
          { description }
        );
        updatedWorkout && setExpandedWorkout(updatedWorkout);
      }
    },
    [dispatch, expandedWorkout]
  );

  const saveWorkout = useCallback(
    async (sections: WorkoutSection[]) => {
      if (expandedWorkout) {
        const updatedWorkout = await PatchWorkout(
          dispatch,
          expandedWorkout.id,
          { sections }
        );
        updatedWorkout && setExpandedWorkout(updatedWorkout);
      }
    },
    [dispatch, expandedWorkout]
  );

  const retrieveWorkoutResource = select(selectRetrieveWorkoutResource);
  const [isGetWorkoutError, hideGetWorkoutError] = useErrorMessageState(
    retrieveWorkoutResource.isError
  );
  const updateWorkoutResource = select(selectUpdateWorkoutResource);

  useEffect(() => {
    getExpandedWorkoutFromAPI();
  }, [getExpandedWorkoutFromAPI]);

  return {
    expandedWorkout,
    isGetWorkoutError,
    hideGetWorkoutError,
    updateDescription,
    updateName,
    saveWorkout,
    isUpdating: updateWorkoutResource.isLoading,
    isUpdateError: updateWorkoutResource.isError,
  };
};
