import { Grid } from "@mui/material";
import React from "react";
import { TransformationCardProps } from "../types";
import { TransformationCard } from "./TransformationCard";

interface Props {
  tranformationCardsData: TransformationCardProps[];
}

export const TransformationCardGrid: React.FC<Props> = ({
  tranformationCardsData,
}) => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      py={2}
      alignItems="stretch"
    >
      {tranformationCardsData.map((item, i) => (
        <Grid item xs={2} sm={4} md={4} key={i}>
          <TransformationCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
};
