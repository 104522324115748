import {
  TableHead,
  TableRow,
  TableCell,
  Skeleton,
  TableBody,
  Table,
} from "@mui/material";
import React from "react";

interface Props {
  rows?: number;
  columns?: string[];
}

export const TableSkeleton: React.FC<Props> = ({
  rows = 7,
  columns = ["15%", "60%", "25%"],
}) => {
  return (
    <Table
      sx={{
        minWidth: 700,
        border: 0,
        ".MuiTableCell-root": { borderBottom: "none" },
      }}
      aria-label="workouts table"
    >
      <TableHead>
        <TableRow>
          {columns.map((width, index) => (
            <TableCell width={width} key={index}>
              <Skeleton variant="rounded" />
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...new Array(rows - 1)].map((_, index) => (
          <TableRow key={index}>
            {columns.map((width, index) => (
              <TableCell width={width} key={index}>
                <Skeleton variant="rounded" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
