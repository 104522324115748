import Box from "@mui/material/Box";
import React, { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import { protectedRoutes, unprotectedRoutes } from "./routes";

export const Router: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {children}
      </Box>
    </BrowserRouter>
  );
};

export const routes = {
  protectedRoutes,
  unprotectedRoutes,
};
