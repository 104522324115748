import { Box, Typography } from "@mui/material";
import React from "react";
import { ExpandedWorkout } from "../../../store/workout/types";
import { Section } from "./Section";

interface Props {
  workout: ExpandedWorkout;
}

export const Workout: React.FC<Props> = ({ workout }) => {
  return (
    <Box>
      <Box>
        {workout.sections.map((section) => (
          <Section key={section.id} section={section} />
        ))}
        {!workout.sections.length && (
          <Typography variant="body1">No Sections</Typography>
        )}
      </Box>
    </Box>
  );
};
