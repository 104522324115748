import { Card, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props {
  xValue: string;
  yValue: string;
}
export const ChartTooltip: React.FC<PropsWithChildren<Props>> = ({
  xValue,
  yValue,
  children,
}) => {
  return (
    <Card elevation={1} sx={{ p: 1, borderRadius: 1 }}>
      <Stack>
        <Typography variant="body2" fontSize={12}>
          {xValue}
        </Typography>
        <Typography variant="body2" fontWeight={800}>
          {yValue}
        </Typography>
        {children}
      </Stack>
    </Card>
  );
};
