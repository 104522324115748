import { useState, useEffect } from "react";
import { CustomSystemProps } from "@mui/system";
import { ExerciseEntry } from "../../../../store/workout/types";

export const useInvalidEntryStyle = (entry: ExerciseEntry) => {
  const [invalidEntryStyle, setInvalidEntryStyle] = useState<CustomSystemProps>(
    {}
  );
  useEffect(() => {
    const sets = entry.sets.flatMap((set) => {
      const restValue = set.values.find(
        (value) => value.parameter.name === "Rest" && value.value <= 0
      );
      return restValue ?? [];
    });

    setInvalidEntryStyle(() =>
      sets.length > 0
        ? {
            border: 2,
            borderRadius: 1,
            borderColor: "error.main",
          }
        : {}
    );
  }, [entry.sets]);

  return {
    invalidEntryStyle,
  };
};
