import React from "react";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface SaveButtonProps {
  isLoading?: boolean;
  onClickButton?: () => void;
  isButtonDisabled?: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  isLoading,
  onClickButton,
  isButtonDisabled,
}) => {
  return isLoading ? (
    <CircularProgress size="1.5em" sx={{ ml: 1 }} />
  ) : (
    <Tooltip title="Save">
      <span>
        <Button
          aria-label="search"
          disabled={isButtonDisabled}
          variant="contained"
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onClickButton?.();
          }}
          endIcon={<CheckCircleIcon />}
        >
          Save
        </Button>
      </span>
    </Tooltip>
  );
};
