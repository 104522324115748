import {
  GridColDef,
  GridValueSetterParams,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridColType,
} from "@mui/x-data-grid";
import { GridRow } from "../utils";
import {
  ExerciseParameterID,
  ExerciseParameterName,
} from "../../../../../store/workout/types";

export const GRID_COL_TYPES_BY_PARAM: Record<ExerciseParameterID, GridColType> =
  {
    [ExerciseParameterID.REPS]: "number",
    [ExerciseParameterID.REST]: "string",
    [ExerciseParameterID.TIME]: "string",
    [ExerciseParameterID.WEIGHT]: "number",
  };

export type ParameterTypeMap = {
  [ExerciseParameterName.REPS]: number;
  [ExerciseParameterName.REST]: string;
  [ExerciseParameterName.TIME]: string;
  [ExerciseParameterName.WEIGHT]: number;
};

export type ParameterNameToType = {
  [K in keyof ParameterTypeMap]: ParameterTypeMap[K];
};

export const convertSecondsToMMSS = (seconds: number) => {
  const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
  const remainingSeconds = String(seconds % 60).padStart(2, "0");
  return `${minutes}:${remainingSeconds}`;
};

export const convertMMSSToSeconds = (input: string) => {
  if (!input.includes(":")) {
    return parseInt(input);
  }
  const [minutes, seconds] = input.split(":");
  return parseInt(minutes) * 60 + parseInt(seconds);
};

export const formatColonInString = (input: string) => {
  const cleaned = input.replace(/[^\d]/g, "").slice(0, 4);
  if (cleaned.length <= 2) {
    return cleaned;
  }
  let output = "";

  for (let i = cleaned.length - 1; i >= 0; i--) {
    const reversedIndex = cleaned.length - 1 - i;
    output = `${cleaned[i]}${output}`;
    if (reversedIndex % 2 === 1 && i !== 0) {
      output = `:${output}`;
    }
  }
  return output;
};

export const durationEditingGridProps = (
  gridRowTitle: keyof GridRow
): Pick<
  GridColDef,
  "valueFormatter" | "valueGetter" | "valueParser" | "valueSetter"
> => ({
  valueSetter: ({ value, row }: GridValueSetterParams<GridRow>) => {
    return {
      ...row,
      [gridRowTitle]: convertMMSSToSeconds(value),
    };
  },
  valueFormatter: ({ value }: GridValueFormatterParams<string>) => {
    return formatColonInString(value);
  },
  valueParser: (value: string) => {
    return formatColonInString(value);
  },
  valueGetter: ({ value }: GridValueGetterParams) => {
    return convertSecondsToMMSS(value);
  },
});

export const editingGridPropsByParameter: {
  [K in keyof ParameterNameToType]: Partial<GridColDef>;
} = {
  Reps: {},
  Time: durationEditingGridProps("Time"),
  Rest: durationEditingGridProps("Rest"),
  Weight: {},
};

const unitLabelByParameter: {
  [K in keyof ParameterNameToType]: string | undefined;
} = {
  Reps: "",
  Time: "mm:ss",
  Rest: "mm:ss",
  Weight: undefined,
};

export const getUnitLabelByParameterName = <
  K extends keyof ParameterNameToType
>(
  parameterName: K
) => unitLabelByParameter[parameterName] || (() => undefined);

export const formattersByParameter: {
  [K in keyof ParameterNameToType]: (value: number) => string;
} = {
  Reps: (value: number) => `${value}`,
  Time: (value: number) => convertSecondsToMMSS(value),
  Rest: (value: number) => convertSecondsToMMSS(value),
  Weight: (value: number) => `${value}`,
};
