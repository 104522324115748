import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChallengePlan,
  ChallengePlanTransformation,
  defaultTransformationsState,
} from "./types";

const transformationsSlice = createSlice({
  name: "transformations",
  initialState: defaultTransformationsState,
  reducers: {
    getChallengePlansAttempt: (state) => {
      state.challengePlans.isError = false;
      state.challengePlans.isLoading = true;
    },
    getChallengePlansFailure: (state) => {
      state.challengePlans.isError = true;
      state.challengePlans.isLoading = false;
    },
    getChallengePlansSuccess: (
      state,
      action: PayloadAction<ChallengePlan[]>
    ) => {
      state.challengePlans.isError = false;
      state.challengePlans.isLoading = false;
      state.challengePlans.data = action.payload;
    },
    getChallengePlanTransformationsAttempt: (state) => {
      state.challengePlanTransformations.isError = false;
      state.challengePlanTransformations.isLoading = true;
    },
    getChallengePlanTransformationsFailure: (state) => {
      state.challengePlanTransformations.isError = true;
      state.challengePlanTransformations.isLoading = false;
    },
    getChallengePlanTransformationsSuccess: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        challengePlanTransformations: ChallengePlanTransformation[];
      }>
    ) => {
      state.challengePlanTransformations.isError = false;
      state.challengePlanTransformations.isLoading = false;
      if (state.challengePlanTransformations.data) {
        state.challengePlanTransformations.data[action.payload.pageNumber] =
          action.payload.challengePlanTransformations;
      } else {
        state.challengePlanTransformations.data = {
          [action.payload.pageNumber]:
            action.payload.challengePlanTransformations,
        };
      }
    },
    setChallengePlanTransformationsCount: (
      state,
      action: PayloadAction<{ count: number }>
    ) => {
      state.challengePlanTransformations.count = action.payload.count;
    },
    clearChallengePlanTransformations: (state) => {
      state.challengePlanTransformations.data = null;
      state.challengePlanTransformations.count = null;
    },
  },
});

export const TransformationsReducer = transformationsSlice.reducer;
export const transformationsActions = transformationsSlice.actions;
