import { useEffect, useMemo, useState } from "react";
import { useDebouncedState } from "../../../../hooks/useDebouncedState";
import { useDialog } from "../../../common/Dialog/hooks";
import { getEntryNotes, getExerciseParameterFromExerciseEntry } from "../utils";
import { ExerciseEntryProps } from "./index";
import { formattersByParameter } from "../ExerciseEntryDataGrid/EditCells/config";

export const useExerciseEntry = ({
  entry,
  deleteEntry,
  updateExerciseType,
  deleteSet,
  updateSet,
  dragHandleProps,
  duplicateSet,
  updateNotes,
  disableRest,
  updateVideo,
  saveWorkoutSectionsNameAndDescriptionThenNavigate,
  isExpanded,
  toggleExpanded,
}: ExerciseEntryProps) => {
  const [liveNotes, debouncedNotes, setLiveNotes] = useDebouncedState(
    () => getEntryNotes(entry),
    500
  );

  const getInitialSelectedVideo = () => {
    if (entry.videoId !== null) {
      return entry.exercise.creatorVideos.findIndex(
        (video) => video.id === entry.videoId
      );
    } else {
      return -1;
    }
  };
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(
    getInitialSelectedVideo
  );

  const selectedVideo =
    selectedVideoIndex >= 0
      ? entry.exercise.creatorVideos[selectedVideoIndex]
      : null;

  const defaultVideo = useMemo(
    () => entry.exercise.creatorVideos.find((it) => it.isDefault),
    [entry.exercise.creatorVideos]
  );

  useEffect(() => {
    updateVideo(selectedVideo?.id || null);
  }, [selectedVideo?.id, updateVideo]);

  const [isNotesExpanded, setIsNotesExpanded] = useState(true);

  useEffect(() => {
    updateNotes(debouncedNotes);
  }, [debouncedNotes, updateNotes]);

  const [
    isCreatorExerciseVisible,
    openCreatorExerciseModal,
    closeCreatorExerciseModal,
  ] = useDialog();

  const [
    isDeleteEntryConfirmVisible,
    openDeleteEntryConfirm,
    closeDeleteEntryConfirm,
  ] = useDialog();

  const mainActionParameter = getExerciseParameterFromExerciseEntry(entry);
  const mainParameterCsv = entry.sets
    .map((set) => {
      const rawValue = set.values.find(
        (value) => value.parameter.id === mainActionParameter.id
      )?.value;
      return rawValue !== undefined
        ? formattersByParameter[mainActionParameter.name](rawValue)
        : "-";
    })
    .join(", ");

  return {
    entry,
    liveNotes,
    setLiveNotes,
    isNotesExpanded,
    setIsNotesExpanded,
    isCreatorExerciseVisible,
    openCreatorExerciseModal,
    closeCreatorExerciseModal,
    deleteEntry,
    updateExerciseType,
    deleteSet,
    updateSet,
    dragHandleProps,
    duplicateSet,
    isDeleteEntryConfirmVisible,
    openDeleteEntryConfirm,
    closeDeleteEntryConfirm,
    disableRest,
    updateVideo,
    defaultVideo,
    selectedVideo,
    setSelectedVideoIndex,
    selectedVideoIndex,
    saveWorkoutSectionsNameAndDescriptionThenNavigate,
    isExpanded,
    toggleExpanded,
    mainActionParameter,
    mainParameterCsv,
  };
};
