import {
  DragIndicator,
  ExpandLess,
  ExpandMore,
  LinkOff,
} from "@mui/icons-material";

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import React from "react";
import {
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from "react-beautiful-dnd";
import { CreatorExercise } from "../../../store/exercises/types";
import {
  ExerciseBlock as ExerciseBlockType,
  ExerciseParameter,
  ExerciseSet,
} from "../../../store/workout/types";
import { ExerciseBlockChips } from "../../common/Workout/ExerciseBlockChips";
import { ExerciseEntry } from "./ExerciseEntry";
import { isBlockSuperset } from "./utils";

interface Props {
  exerciseBlock: ExerciseBlockType;
  deleteExerciseEntry: (entryIndex: number) => void;
  deleteSetInEntry: (setIndex: number) => void;
  duplicateSetInEntry: (setIndex: number) => void;
  updateSetInEntry: (entryIndex: number, set: ExerciseSet) => void;
  updateExerciseType: (
    entryIndex: number,
    parameter: ExerciseParameter
  ) => void;
  updateNotesInEntry: (entryIndex: number, notes: string) => void;
  splitAfterEntryWithIndex?: (entryIndex: number) => void;
  dragBlockHandleProps?: DraggableProvidedDragHandleProps | null;
  updateVideoInExerciseEntry: (
    entryIndex: number,
    videoId: number | null
  ) => void;
  saveWorkoutSectionsNameAndDescriptionThenNavigate: (
    exercise: CreatorExercise
  ) => Promise<void>;
}

export const ExerciseBlock: React.FC<Props> = ({
  exerciseBlock,
  deleteExerciseEntry,
  deleteSetInEntry,
  duplicateSetInEntry,
  updateSetInEntry,
  updateExerciseType,
  splitAfterEntryWithIndex,
  updateNotesInEntry,
  dragBlockHandleProps,
  updateVideoInExerciseEntry,
  saveWorkoutSectionsNameAndDescriptionThenNavigate,
}) => {
  const isSuperset = isBlockSuperset(exerciseBlock);
  const [isEntrysExpandedById, setIsEntrysExpandedById] = React.useState(() => {
    return Object.fromEntries(
      exerciseBlock.entrys.map((entry) => [entry.id, true])
    );
  });
  const isBlockExpanded = Object.values(isEntrysExpandedById).every(
    (isExpanded) => isExpanded
  );

  const setAllCollapsed = () => {
    setIsEntrysExpandedById(
      Object.fromEntries(exerciseBlock.entrys.map((entry) => [entry.id, false]))
    );
  };
  const setAllExpanded = () => {
    setIsEntrysExpandedById(
      Object.fromEntries(exerciseBlock.entrys.map((entry) => [entry.id, true]))
    );
  };

  return (
    <Card
      elevation={1}
      sx={{
        width: "100%",
      }}
    >
      <Box
        {...dragBlockHandleProps}
        display="flex"
        flexDirection="row"
        p={1}
        justifyContent={"space-between"}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <DragIndicator color="action" />
        </Box>
        <Box gap={1} display="flex">
          <ExerciseBlockChips
            block={exerciseBlock}
            showRestChip={!isBlockExpanded}
          />
          <Tooltip title={"Expand all"} placement="top">
            <span>
              <Button
                variant="outlined"
                onClick={setAllExpanded}
                size="small"
                color="neutral"
                sx={{
                  minWidth: 0,
                  bgcolor: "background.default",
                }}
              >
                <ExpandMore />
              </Button>
            </span>
          </Tooltip>
          <Tooltip title={"Collapse all"} placement="top">
            <span>
              <Button
                variant="outlined"
                onClick={setAllCollapsed}
                size="small"
                color="neutral"
                sx={{
                  minWidth: 0,
                  bgcolor: "background.default",
                }}
              >
                <ExpandLess />
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      <Divider />
      <Droppable droppableId={exerciseBlock.id} type="entry">
        {(droppableProvided) => (
          <Box
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            sx={{
              p: 1,
              backgroundColor: isSuperset ? "primary.mid" : undefined,
            }}
          >
            {exerciseBlock.entrys.map((entry, entryIndex, allEntrys) => (
              <Draggable
                draggableId={entry.id}
                index={entryIndex}
                key={entry.id}
              >
                {(draggableProvided, snap) => (
                  <>
                    <CardContent
                      {...draggableProvided.draggableProps}
                      ref={draggableProvided.innerRef}
                      sx={{
                        p: 0,
                      }}
                    >
                      <ExerciseEntry
                        entry={entry}
                        deleteEntry={() => deleteExerciseEntry(entryIndex)}
                        updateExerciseType={(exerciseType: ExerciseParameter) =>
                          updateExerciseType(entryIndex, exerciseType)
                        }
                        deleteSet={(setIndex) => {
                          deleteSetInEntry(setIndex);
                        }}
                        updateSet={(set: ExerciseSet) =>
                          updateSetInEntry(entryIndex, set)
                        }
                        duplicateSet={(setIndex: number) =>
                          duplicateSetInEntry(setIndex)
                        }
                        updateNotes={(value) =>
                          updateNotesInEntry(entryIndex, value)
                        }
                        updateVideo={(videoId) =>
                          updateVideoInExerciseEntry(entryIndex, videoId)
                        }
                        dragHandleProps={draggableProvided.dragHandleProps}
                        disableRest={
                          isSuperset && entryIndex !== allEntrys.length - 1
                        }
                        saveWorkoutSectionsNameAndDescriptionThenNavigate={
                          saveWorkoutSectionsNameAndDescriptionThenNavigate
                        }
                        isExpanded={isEntrysExpandedById[entry.id]}
                        toggleExpanded={() =>
                          setIsEntrysExpandedById((IsEntrysExpandedById) => ({
                            ...IsEntrysExpandedById,
                            [entry.id]: !IsEntrysExpandedById[entry.id],
                          }))
                        }
                      />
                    </CardContent>
                    {entryIndex !== exerciseBlock.entrys.length - 1 && (
                      <Divider
                        sx={{
                          opacity: snap.isDragging ? 0 : 0.4,
                          transition: "opacity 0.75s ease",
                          my: 1,
                          ":hover": {
                            opacity: 1,
                            transition: "opacity 0.75s ease",
                          },
                        }}
                      >
                        <Tooltip title={"Split superset"}>
                          <IconButton
                            onClick={() =>
                              splitAfterEntryWithIndex?.(entryIndex)
                            }
                          >
                            <LinkOff />
                          </IconButton>
                        </Tooltip>
                      </Divider>
                    )}
                  </>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </Box>
        )}
      </Droppable>
    </Card>
  );
};
