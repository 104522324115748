import { Close, CloudUpload, Error, Videocam } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useMuxAddVideoDialogForm } from "./useMuxAddVideoDialogForm";

interface Props {
  onSubmit: (video: File) => Promise<void>;
  close: () => void;
  exerciseName: string;
  isSubmitting: boolean;
  progress?: number;
}

export const MuxAddVideoDialogForm: React.FC<Props> = ({
  onSubmit,
  close,
  exerciseName,
  isSubmitting,
  progress = 0,
}) => {
  const {
    selectedFile,
    fileUrl,
    getInputProps,
    getRootProps,
    removeFile,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useMuxAddVideoDialogForm();

  return (
    <>
      <DialogTitle>Add Video for {exerciseName}</DialogTitle>
      <DialogContent>
        <Box py={1}>
          <Stack gap={2}>
            {selectedFile && (
              <Box
                sx={(theme) => ({
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  border: `1px solid ${theme.palette.grey[300]}`,
                  py: 2,
                  px: 1,
                  borderRadius: 1,
                })}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <Videocam fontSize="small" sx={{ color: "grey.300" }} />
                  <Typography>{selectedFile.name}</Typography>
                </Box>
                {!isSubmitting && (
                  <IconButton onClick={removeFile}>
                    <Close />
                  </IconButton>
                )}
              </Box>
            )}
            {!fileUrl ? (
              <Card
                {...getRootProps()}
                variant="outlined"
                sx={{
                  alignItems: "center",
                  borderRadius: 2,
                  borderWidth: 1,
                  borderStyle: "dashed",
                  display: "flex",
                  height: "400px",
                  justifyContent: "center",
                  textAlign: "center",
                  width: "auto",
                  borderColor: "grey.500",
                  transition: "border-color 0.3s ease",
                  bgcolor: "background.default",
                }}
              >
                <input {...getInputProps()} />
                <Stack>
                  {isDragReject && (
                    <Stack alignItems="center" gap={1}>
                      <Error sx={{ fontSize: 64 }} color="primary" />
                      <Typography variant="h4">
                        {"Not a valid file type"}
                      </Typography>
                      <Typography variant="subtitle1">
                        {"Please upload a video file (.mp4, .mov, .avi, .mkv)"}
                      </Typography>
                    </Stack>
                  )}
                  {!isDragActive && (
                    <Stack gap={1} alignItems="center">
                      <CloudUpload sx={{ fontSize: 64 }} color="action" />
                      <Typography variant="h4">
                        {"Drag and drop a video here"}
                      </Typography>
                      <Typography>OR</Typography>
                      <Box>
                        <Button variant="contained">Upload a video</Button>
                      </Box>
                    </Stack>
                  )}
                  {isDragAccept && (
                    <Stack gap={1} alignItems="center">
                      <CloudUpload
                        sx={{
                          fontSize: 64,
                          animation: "bounce 2s infinite",
                          "@keyframes bounce": {
                            "0%": {
                              transform: "scale(1, 1) translateY(0)",
                            },
                            "10%": {
                              transform: "scale(1.1, 0.9) translateY(0)",
                            },
                            "30%": {
                              transform: "scale(0.9, 1.1) translateY(-50px)",
                            },
                            "50%": {
                              transform: "scale(1.05, 0.95) translateY(0)",
                            },
                            "57%": {
                              transform: "scale(1, 1) translateY(-7px)",
                            },
                            "64%": {
                              transform: "scale(1, 1) translateY(0)",
                            },
                            "100%": {
                              transform: "scale(1, 1) translateY(0)",
                            },
                          },
                        }}
                        color="primary"
                      />
                      <Typography variant="h4">
                        {"Drop your video here to preview"}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Card>
            ) : (
              <Stack alignItems="center" justifyContent="center" width="100%">
                <video
                  width="auto"
                  height="400px"
                  controls
                  style={{ borderRadius: "8px", overflow: "hidden" }}
                >
                  <source src={fileUrl} type={selectedFile?.type} />
                </video>
              </Stack>
            )}
          </Stack>
        </Box>
      </DialogContent>
      {isSubmitting ? (
        <Box px={3} pt={1} pb={3}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 10, borderRadius: 2 }}
          />
        </Box>
      ) : (
        <DialogActions>
          <Button variant="outlined" color="neutral" onClick={close}>
            Cancel
          </Button>
          <Button
            disabled={!selectedFile}
            variant="contained"
            onClick={() => selectedFile && onSubmit(selectedFile)}
          >
            Save
          </Button>
        </DialogActions>
      )}
    </>
  );
};
