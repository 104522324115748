import FitnessCenter from "@mui/icons-material/FitnessCenter";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import TimerIcon from "@mui/icons-material/Timer";
import React from "react";
import {
  ExerciseParameter,
  ExerciseParameterID,
  ExerciseParameterName,
  ExerciseParameterValue,
} from "../../../store/workout/types";
import { randomUUID } from "../../../util/uuid";
import { ToggleOption } from "../../common/Toggle";

export const INITIAL_REPS_VALUE = 8;
export const INITIAL_TIME_VALUE = 60;
export const INITIAL_REST_VALUE = 60;

const INITIAL_PARAMETER_VALUES: Record<string, number> = {
  Reps: INITIAL_REPS_VALUE,
  Time: INITIAL_TIME_VALUE,
  Rest: INITIAL_REST_VALUE,
};

export const TIME_EXERCISE_PARAMETER: ExerciseParameter = {
  id: ExerciseParameterID.TIME,
  name: ExerciseParameterName.TIME,
  unit: "s",
};

export const REPS_EXERCISE_PARAMETER: ExerciseParameter = {
  id: ExerciseParameterID.REPS,
  name: ExerciseParameterName.REPS,
  unit: "",
};

export const WEIGHT_EXERCISE_PARAMETER: ExerciseParameter = {
  id: ExerciseParameterID.WEIGHT,
  name: ExerciseParameterName.WEIGHT,
  unit: "kg",
};

export const REST_EXERCISE_PARAMETER: ExerciseParameter = {
  id: ExerciseParameterID.REST,
  name: ExerciseParameterName.REST,
  unit: "s",
};

export const createDefaultRepsParameterValue = (
  value = INITIAL_REPS_VALUE
): ExerciseParameterValue => ({
  id: randomUUID(),
  parameter: REPS_EXERCISE_PARAMETER,
  value,
});

export const createDefaultTimeParameterValue = (
  value = INITIAL_TIME_VALUE
): ExerciseParameterValue => ({
  id: randomUUID(),
  parameter: TIME_EXERCISE_PARAMETER,
  value,
});

export const createDefaultWeightParameterValue =
  (): ExerciseParameterValue => ({
    id: randomUUID(),
    parameter: WEIGHT_EXERCISE_PARAMETER,
    value: 0,
  });

export const createDefaultRestParameterValue = (
  value = INITIAL_REST_VALUE
): ExerciseParameterValue => ({
  id: randomUUID(),
  parameter: REST_EXERCISE_PARAMETER,
  value,
});

export const EXERCISE_TYPE_OPTIONS: ToggleOption<ExerciseParameter>[] = [
  {
    label: "Reps",
    value: REPS_EXERCISE_PARAMETER,
  },
  {
    label: "Time",
    value: TIME_EXERCISE_PARAMETER,
  },
];

export const EXERCISE_TYPE_ICONS: Record<ExerciseParameterID, React.ReactNode> =
  {
    [ExerciseParameterID.REPS]: React.createElement(FitnessCenter),
    [ExerciseParameterID.REST]: React.createElement(HourglassTopIcon),
    [ExerciseParameterID.WEIGHT]: React.createElement(FitnessCenter),
    [ExerciseParameterID.TIME]: React.createElement(TimerIcon),
  };

export const getInitialParameterValueByParameterName = (
  parameterName: string
) => INITIAL_PARAMETER_VALUES[parameterName] || 0;
