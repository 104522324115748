export enum TransformationType {
  BEFORE = "Before",
  AFTER = "After",
}

export enum TransformationPicAngle {
  FRONT = "Front",
  BACK = "Back",
  SIDE = "Side",
}

export interface TransformationPicture {
  image: string;
  submittedAt: string;
}

export interface TransformationCardProps {
  name: string;
  email: string;
  workoutPlanName: string;
  totalWorkouts: number;
  workoutsCompleted: number;
  workoutsCompletedPercentage: number;
  transformationTime: number | null;
  transformationAngles: {
    [TransformationPicAngle.FRONT]: {
      [TransformationType.BEFORE]: TransformationPicture;
      [TransformationType.AFTER]: TransformationPicture | null;
    };
    [TransformationPicAngle.BACK]: {
      [TransformationType.BEFORE]: TransformationPicture;
      [TransformationType.AFTER]: TransformationPicture | null;
    };
    [TransformationPicAngle.SIDE]: {
      [TransformationType.BEFORE]: TransformationPicture;
      [TransformationType.AFTER]: TransformationPicture | null;
    };
  };
  hasPublicSharingConsent: boolean;
}
