import { CreatorExercise } from "../exercises/types";
import { createDefaultResource, Resource } from "../types";
import { ExpandedWorkout } from "../workout/types";

export interface ExerciseAPI {
  id: number;
  name: string;
  major_muscle_group: string;
}
export interface Exercise {
  id: number;
  name: string;
  muscleGroup: string;
}

export interface WorkoutBuilderState {
  workoutBuilder: ExpandedWorkout | null;
  exercises: Resource<CreatorExercise[]>;
}

export const defaultWorkoutBuilderState: WorkoutBuilderState = {
  workoutBuilder: null,
  exercises: createDefaultResource(),
};
