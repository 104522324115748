import {
  ContentCopy,
  DeleteOutline,
  Edit,
  Favorite,
  HeartBroken,
  MoreVert,
  Visibility,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Draggable, DraggableProps } from "react-beautiful-dnd";
import { Workout } from "../../../store/workout/types";
import { BaseText } from "../../common/BaseText";
import { ConfirmDialog } from "../../common/Dialog";
import { useDialog } from "../../common/Dialog/hooks";
import { WorkoutViewDialog } from "./WorkoutViewerDialog";
import { PlanBuilderCardBase } from "./styles";
import { MAX_ITEMS_IN_LIST } from "../../../store/plan-builder/types";

interface Props extends Omit<DraggableProps, "children"> {
  workout: Workout;
  index: number;
  onDelete: () => void;
  onEdit: () => void;
  onFavourite: (isFavourite: boolean) => Promise<void>;
  isFavouritingWorkoutLoading: boolean;
  canEdit: boolean;
  onDuplicate: () => void;
  canDuplicate: boolean;
  weekIndex: number;
}

export const DraggableWorkout: React.FC<Props> = ({
  workout,
  index,
  onDelete,
  onEdit,
  onFavourite,
  canEdit,
  isFavouritingWorkoutLoading,
  onDuplicate,
  canDuplicate,
  weekIndex,
  ...props
}) => {
  const [isPeekingWorkout, openPeekWorkout, closePeekWorkout] =
    useDialog(false);
  const [isConfirmRemoveDialogShowing, openConfirmRemove, closeConfirmRemove] =
    useDialog(false);
  const [isFavouritingWorkout, openFavouriteWorkout, closeFavouriteWorkout] =
    useDialog(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const closeMenuAnd = (runFunctionAnd: () => void) => () => {
    runFunctionAnd();
    closeMenu();
  };
  return (
    <>
      <Draggable index={index} {...props}>
        {(props, snapshot) => (
          <>
            <PlanBuilderCardBase
              {...props.dragHandleProps}
              {...props.draggableProps}
              ref={props.innerRef}
            >
              <Stack p={1} pl={2} sx={{ position: "relative" }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <BaseText
                    variant="subtitle2"
                    color="text.secondary"
                    isLoading={snapshot.isDragging}
                    width={snapshot.isDragging ? "20%" : undefined}
                  >
                    Day {index + 1}
                  </BaseText>
                  <Stack direction="row">
                    <Tooltip title="More">
                      <span>
                        <IconButton
                          aria-label="More"
                          size="small"
                          onClick={openMenu}
                          id="menu-button"
                        >
                          <MoreVert fontSize="small" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Tooltip title={workout.name}>
                    <Typography noWrap mb={0}>
                      {workout.name}
                    </Typography>
                  </Tooltip>
                </Box>
                {workout.isFavourite && (
                  <Favorite
                    fontSize={"small"}
                    sx={{
                      position: "absolute",
                      bottom: -15,
                      fontSize: 15,
                    }}
                    color="disabled"
                  />
                )}
              </Stack>
            </PlanBuilderCardBase>
          </>
        )}
      </Draggable>
      <WorkoutViewDialog
        workout={workout}
        canEdit={canEdit}
        close={closePeekWorkout}
        isOpen={isPeekingWorkout}
        onEdit={onEdit}
        weekIndex={weekIndex}
        dayIndex={index}
      />
      <ConfirmDialog
        subtitle={`Are you sure you want to remove ${workout.name} from this workout plan week?`}
        title={"Remove workout"}
        isOpen={isConfirmRemoveDialogShowing}
        onClose={closeConfirmRemove}
        cancelText="Cancel"
        confirmText="Remove"
        onClickConfirm={onDelete}
        confirmButtonProps={{ color: "error" }}
        onClickCancel={closeConfirmRemove}
      />
      <ConfirmDialog
        subtitle={`Would you like to ${
          workout.isFavourite ? "unfavourite" : "favourite"
        } ${workout.name}?`}
        title={`${workout.isFavourite ? "Unfavourite" : "Favourite"} workout`}
        isOpen={isFavouritingWorkout}
        onClose={closeFavouriteWorkout}
        cancelText="Cancel"
        confirmText={`${workout.isFavourite ? "Unfavourite" : "Favourite"}`}
        onClickConfirm={() =>
          onFavourite(workout.isFavourite).finally(closeFavouriteWorkout)
        }
        confirmButtonProps={{ color: "primary" }}
        onClickCancel={closeFavouriteWorkout}
        isLoading={isFavouritingWorkoutLoading}
      />
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "menu-button",
          dense: true,
        }}
      >
        <MenuItem onClick={closeMenuAnd(openPeekWorkout)}>
          <ListItemIcon>
            <Visibility fontSize="small" />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>
        <Tooltip
          title={
            !canEdit ? "Save the workout plan before editing this workout" : ""
          }
          placement="left"
        >
          <span>
            <MenuItem onClick={closeMenuAnd(onEdit)} disabled={!canEdit}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !canDuplicate
              ? `This week already has ${MAX_ITEMS_IN_LIST} items.`
              : ""
          }
          placement="left"
        >
          <span>
            <MenuItem
              disabled={!canDuplicate}
              onClick={closeMenuAnd(onDuplicate)}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
          </span>
        </Tooltip>
        {!workout.isFavourite ? (
          <Tooltip
            title={
              !canEdit
                ? "Save your changes before favouriting this workout."
                : ""
            }
            placement="left"
          >
            <span>
              <MenuItem
                disabled={!canEdit}
                onClick={closeMenuAnd(openFavouriteWorkout)}
              >
                <ListItemIcon>
                  <Favorite fontSize="small" />
                </ListItemIcon>
                <ListItemText>Favourite</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              !canEdit
                ? "Save your changes before unfavouriting this workout."
                : ""
            }
            placement="left"
          >
            <span>
              <MenuItem
                disabled={!canEdit}
                onClick={closeMenuAnd(openFavouriteWorkout)}
              >
                <ListItemIcon>
                  <HeartBroken fontSize="small" />
                </ListItemIcon>
                <ListItemText>Unfavourite</ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <Divider />
        <MenuItem onClick={closeMenuAnd(openConfirmRemove)}>
          <ListItemIcon>
            <DeleteOutline fontSize="small" />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
