import { CircularProgress, DialogActions, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useMemo, useRef, useState } from "react";
import { getSentences } from "../../../../../util/string/sentence";
import {
  DialogPrimaryButton,
  DialogSecondaryButton,
} from "../../../../common/Dialog/DialogButtons";
import { WorkoutPlanCheckComponentProps } from "./types";

const MIN_DESCRIPTION_LENGTH = 5;

export const CheckWorkoutPlanDescription: React.FC<
  WorkoutPlanCheckComponentProps
> = ({
  workoutPlan,
  onClickPrimary,
  onClickSecondary,
  updateNewValue,
  isLoading,
  isError,
}) => {
  const textFieldInputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState(workoutPlan.description);
  const hasValueChanged = useMemo(
    () => value !== workoutPlan.description,
    [value, workoutPlan.description]
  );
  const getErrorMessage = (text: string) => {
    if (text.trim().length < MIN_DESCRIPTION_LENGTH) {
      return "Your description must be at least 5 characters long";
    }
    if (getSentences(text).length > 4) {
      return "You can only have up to 4 sentences";
    }
    return "";
  };
  const [errorMessage, setErrorMessage] = useState(() =>
    getErrorMessage(workoutPlan.description)
  );

  return (
    <>
      <TextField
        inputRef={textFieldInputRef}
        id={"check-workout-plan-description"}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
          setErrorMessage(getErrorMessage(event.target.value));
        }}
        fullWidth
        variant="outlined"
        margin="normal"
        multiline
        autoFocus
        error={!!errorMessage}
        autoComplete="off"
        InputProps={{
          disabled: isLoading,
          endAdornment: isLoading && (
            <InputAdornment position="start" sx={{ pl: 1 }}>
              <CircularProgress size={"1.2em"} />
            </InputAdornment>
          ),
        }}
      />

      {!!errorMessage && (
        <Typography variant="body2" color="error.main" alignSelf={"baseline"}>
          {errorMessage}
        </Typography>
      )}

      <DialogActions sx={{ width: "100%" }}>
        <DialogSecondaryButton onClick={onClickSecondary}>
          {"Previous"}
        </DialogSecondaryButton>
        <DialogPrimaryButton
          variant="outlined"
          disabled={isLoading || !!errorMessage || isError}
          onClick={() => {
            const inputValue = textFieldInputRef.current?.value.trim();
            if (hasValueChanged) {
              inputValue && updateNewValue(inputValue).then(onClickPrimary);
            } else {
              onClickPrimary();
            }
          }}
        >
          {hasValueChanged ? (isLoading ? "Saving..." : "Save & Next") : "Next"}
        </DialogPrimaryButton>
      </DialogActions>
    </>
  );
};
