import React from "react";
import { DialogProps } from "@mui/material";
import { StyledDialog } from "./Dialog.styles";

export const BaseDialog: React.FC<React.PropsWithChildren<DialogProps>> = ({
  children,
  ...MUIdialogProps
}) => {
  return (
    <>
      <StyledDialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 100, 0.05)",
              backdropFilter: "blur(5px)",
            },
          },
        }}
        transitionDuration={500}
        disableRestoreFocus
        {...MUIdialogProps}
      >
        {children}
      </StyledDialog>
    </>
  );
};
