import {
  DataGrid,
  GridAlignment,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import React, { useMemo } from "react";
import { ExerciseEntry, ExerciseSet } from "../../../../store/workout/types";
import { REST_EXERCISE_PARAMETER } from "../fixtures";
import { CopyGridActionsCellItem } from "./CopyGridActionsCellItem";
import { DeleteGridActionsCellItem } from "./DeleteGridActionsCellItem";
import { renderTooltipEditCell } from "./EditCells/TooltipEditCell";
import {
  GridRow,
  getUniqueExerciseParametersFromSets,
  sortExerciseParameters,
  transformToExerciseSet,
} from "./utils";
import {
  getUnitLabelByParameterName,
  GRID_COL_TYPES_BY_PARAM,
  editingGridPropsByParameter,
} from "./EditCells/config";
import { getPreProcesserForParameter } from "./validation";

interface Props {
  exerciseEntry: ExerciseEntry;
  disableDelete: boolean;
  updateSet: (newSet: ExerciseSet) => void;
  onDeleteSet: (setNumber: number) => void;
  onDuplicateSet: (setNumber: number) => void;
  disableRest?: boolean;
}

export const ExerciseEntryDataGrid: React.FC<Props> = ({
  exerciseEntry,
  disableDelete,
  updateSet,
  onDeleteSet,
  onDuplicateSet,
  disableRest = false,
}) => {
  const restFieldName = useMemo(
    () => (disableRest ? "_Rest" : "Rest"),
    [disableRest]
  );
  const restGridColDef: GridColDef = useMemo(() => {
    return {
      headerName: `${REST_EXERCISE_PARAMETER.name}${
        REST_EXERCISE_PARAMETER.unit
          ? ` (${getUnitLabelByParameterName(REST_EXERCISE_PARAMETER.name)})`
          : ""
      }`,
      editable: !disableRest,
      type: GRID_COL_TYPES_BY_PARAM[REST_EXERCISE_PARAMETER.id],
      sortable: false,
      flex: 1,
      field: restFieldName,
      headerAlign: "center" as GridAlignment,
      align: "center" as GridAlignment,
      preProcessEditCellProps: getPreProcesserForParameter(
        REST_EXERCISE_PARAMETER.name
      ),
      renderEditCell: renderTooltipEditCell,
      ...editingGridPropsByParameter[REST_EXERCISE_PARAMETER.name],
    };
  }, [disableRest, restFieldName]);

  const gridColumnDefinitions: GridColDef[] = useMemo(() => {
    const params = (() => {
      const exerciseParameters = getUniqueExerciseParametersFromSets(
        exerciseEntry.sets
      );
      return sortExerciseParameters(exerciseParameters);
    })();

    const restParamGridColDef = restGridColDef;

    return [
      {
        field: "setNumber",
        headerName: "Set",
        type: "number",
        sortable: false,
        flex: 0.5,
      },
      ...params
        .filter((param) => param.id !== REST_EXERCISE_PARAMETER.id)
        .map((param) => ({
          headerName: `${param.name}${
            param.unit ? ` (${getUnitLabelByParameterName(param.name)})` : ""
          }`,
          editable: true,
          type: GRID_COL_TYPES_BY_PARAM[param.id],
          sortable: false,
          flex: 1,
          field: param.name,
          headerAlign: "center" as GridAlignment,
          align: "center" as GridAlignment,
          preProcessEditCellProps: getPreProcesserForParameter(param.name),
          renderEditCell: renderTooltipEditCell,
          ...editingGridPropsByParameter[param.name],
        })),
      restParamGridColDef,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 0.5,
        cellClassName: "actions",
        getActions: ({ row }: { row: GridRow }) => {
          return [
            <CopyGridActionsCellItem
              onClick={() => onDuplicateSet(row.setNumber)}
              key="copy"
            />,
            <DeleteGridActionsCellItem
              onClick={() => onDeleteSet(row.setNumber)}
              key="delete"
              disabled={disableDelete}
            />,
          ];
        },
      },
    ];
  }, [
    disableDelete,
    exerciseEntry.sets,
    onDeleteSet,
    onDuplicateSet,
    restGridColDef,
  ]);

  const gridRows: GridRowsProp<GridRow> = useMemo(() => {
    return exerciseEntry.sets.map((set, setIndex) => {
      const gridRow = gridColumnDefinitions.reduce(
        (acc: GridRow, param) => {
          const parameterValue = set.values.find(
            (value) => value.parameter.name === param.field
          );
          parameterValue &&
            Object.assign(acc, {
              [parameterValue.parameter.name]: parameterValue.value,
            });
          return acc;
        },
        { id: set.id, setNumber: setIndex + 1 }
      );
      if (disableRest) {
        Object.assign(gridRow, { _Rest: "-" });
      }
      return gridRow;
    });
  }, [exerciseEntry.sets, gridColumnDefinitions, disableRest]);

  return (
    <DataGrid
      columns={gridColumnDefinitions}
      rows={gridRows}
      sx={{
        border: 0,
        "& .MuiDataGrid-editInputCell": {
          "& input": {
            textAlign: "center",
          },
        },
      }}
      hideFooterPagination
      hideFooter
      processRowUpdate={(x: GridRow) => {
        updateSet(transformToExerciseSet({ ...x }));
        return x;
      }}
      getRowId={(x: GridRow) => x.id}
      disableColumnMenu
    />
  );
};
