import { Card, CardProps, styled } from "@mui/material";

export const DRAGGABLE_WORKOUT_WIDTH_SPACING = 40;
export const DRAGGABLE_WORKOUT_HEIGHT_SPACING = 12;

export const PlanBuilderCardBase = styled(Card)<CardProps>(({ theme }) => ({
  width: theme.spacing(DRAGGABLE_WORKOUT_WIDTH_SPACING),
  marginBottom: theme.spacing(1),
  minHeight: theme.spacing(DRAGGABLE_WORKOUT_HEIGHT_SPACING),
  boxShadow: theme.shadows[1],
}));
