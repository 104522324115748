import { HideImage } from "@mui/icons-material";
import { Box, BoxProps, Typography } from "@mui/material";
import React from "react";

interface Props extends BoxProps {}

export const NoWorkoutPlanArtwork: React.FC<Props> = ({ ...boxProps }) => {
  return (
    <Box
      width={"100%"}
      sx={{ aspectRatio: "1" }}
      bgcolor={"grey.100"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={1}
      {...boxProps}
    >
      <HideImage sx={{ color: "grey.500" }} />
      <Typography color="grey.500">No Artwork</Typography>
    </Box>
  );
};
