import { Dialog, styled } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(1, 0),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1, 0),
  },
}));
