import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import {
  GetExercises,
  SetCurrentCreatorExercise,
} from "../../../store/exercises/actions";
import { selectExercises } from "../../../store/exercises/selectors";
import { useExerciseAutocomplete } from "../WorkoutBuilder/ExerciseAutocomplete/useExerciseAutocomplete";
import { CreatorExercise } from "../../../store/exercises/types";

const EXERCISE_PER_PAGE = 25;

export const useExerciseShowroom = () => {
  const [select, dispatch] = useStore();
  const query = useQuery();
  const currentPage = parseInt(query.get("page") ?? "1", 10);
  const navigate = useNavigate();

  const paginatedExercises = select(selectExercises);
  const totalExercises = paginatedExercises.count || 0;
  const totalPages = Math.ceil(totalExercises / EXERCISE_PER_PAGE);
  const { isError, isLoading } = paginatedExercises;

  const [isShowingErrorMessage, hideErrorMessage] =
    useErrorMessageState(isError);

  const doesPageExist = useCallback(
    (pageNumber: number) => {
      return !!paginatedExercises.data?.[pageNumber];
    },
    [paginatedExercises.data]
  );

  useEffect(() => {
    if (!doesPageExist(currentPage)) {
      GetExercises(dispatch, currentPage, EXERCISE_PER_PAGE);
    }
  }, [currentPage, dispatch, doesPageExist]);

  const refreshExercises = () => {
    GetExercises(dispatch, 1, EXERCISE_PER_PAGE);
    navigate("/exercises");
  };
  const navigateToPlans = () => {
    navigate("/workout-plans?create=true");
  };

  const navigateToCreatorExercise = (exercise: CreatorExercise) => {
    SetCurrentCreatorExercise(dispatch, {
      ...exercise,
      creatorNotes: [],
      creatorVideos: [],
    });
    navigate(`/exercises/${exercise.id}`);
  };

  const exerciseAutocomplete = useExerciseAutocomplete();

  return {
    exercises: paginatedExercises.data?.[currentPage] || [],
    currentPage,
    totalPages,
    isShowingErrorMessage,
    hideErrorMessage,
    isLoading,
    totalExercises,
    navigateToPlans,
    refreshExercises,
    navigateToCreatorExercise,
    ...exerciseAutocomplete,
  };
};
