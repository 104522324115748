import React, { useCallback } from "react";
import { useMemo, useState } from "react";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";
import { Step } from "../../../common/Stepper";
import { CheckWorkoutPlanNameStep } from "./components/CheckWorkoutPlanNameStep";
import { CheckWorkoutPlanDescription } from "./components/CheckWorkoutPlanDescriptionStep";
import { useWorkoutPlanEdit } from "../../WorkoutPlan/WorkoutPlanEdit/useWorkoutPlanEdit";
import { CheckWorkoutPlanArtwork } from "./components/CheckWorkoutPlanArtworkStep";
import { CheckWorkoutPlanOverview } from "./components/CheckWorkoutPlanOverview";
import { PublishPlanCheckModalProps } from ".";
import { useErrorMessageState } from "../../../../hooks/useErrorMessageState";

// Set order based on order of enum
enum PublishPlanCheckKey {
  PLAN_NAME = "plan-name",
  PLAN_DESCRIPTION = "plan-description",
  PLAN_THUMBNAIL = "plan-thumbnail",
  PLAN_SUMMARY = "plan-summary",
}

export const PUBLISH_PLAN_CHECK_ORDER: PublishPlanCheckKey[] =
  Object.values(PublishPlanCheckKey);

interface PublishPlanCheckInfo {
  title: string;
  planAttribute: keyof BaseWorkoutPlan;
  label: string;
  subtitle: string;
  component: (workoutPlan: BaseWorkoutPlan) => JSX.Element;
}

export const usePublishPlanCheckModal = ({
  workoutPlan,
  onClose,
  refreshWorkoutPlans,
}: PublishPlanCheckModalProps) => {
  const [currentStep, setCurrentStep] = useState<PublishPlanCheckKey>(
    PUBLISH_PLAN_CHECK_ORDER[0]
  );
  const currentStepIndex = useMemo(
    () => PUBLISH_PLAN_CHECK_ORDER.findIndex((value) => value === currentStep),
    [currentStep]
  );
  const isLastStep = currentStepIndex === PUBLISH_PLAN_CHECK_ORDER.length - 1;

  const {
    isError,
    isLoading,
    updateWorkoutPlanName,
    updateWorkoutPlanDescription,
    updateWorkoutPlanArtwork,
    publishWorkoutPlan,
  } = useWorkoutPlanEdit({});

  const [isShowingAPIErrorMessage, hideAPIErrorMessage] =
    useErrorMessageState(isError);
  const goToNext = useCallback(() => {
    if (isLastStep) {
      return;
    }
    const nextStepKey = PUBLISH_PLAN_CHECK_ORDER[currentStepIndex + 1];
    setCurrentStep(nextStepKey);
  }, [currentStepIndex, isLastStep]);

  const goToPrev = useCallback(() => {
    if (currentStepIndex === 0) {
      return;
    }
    const nextStepKey = PUBLISH_PLAN_CHECK_ORDER[currentStepIndex - 1];
    setCurrentStep(nextStepKey);
  }, [currentStepIndex]);

  const onPressStep = (stepIndex: number) => {
    setCurrentStep(PUBLISH_PLAN_CHECK_ORDER[stepIndex]);
  };

  const resetPublishPlanCheck = () =>
    setCurrentStep(PUBLISH_PLAN_CHECK_ORDER[0]);

  const PUBLISH_PLAN_CHECK_STEPS: Record<
    PublishPlanCheckKey,
    PublishPlanCheckInfo
  > = useMemo(
    () => ({
      [PublishPlanCheckKey.PLAN_NAME]: {
        title: "First, check your workout plan name",
        planAttribute: "name",
        label: "Add plan name",
        subtitle: "Pick well, this could be your most popular one!",
        component: (workoutPlan: BaseWorkoutPlan) => (
          <CheckWorkoutPlanNameStep
            workoutPlan={workoutPlan}
            onClickPrimary={goToNext}
            onClickSecondary={goToPrev}
            updateNewValue={updateWorkoutPlanName}
            isLoading={isLoading}
            isError={isError}
          />
        ),
      },
      [PublishPlanCheckKey.PLAN_DESCRIPTION]: {
        title: "Now check the description",
        planAttribute: "description",
        label: "Add plan description",
        subtitle: "4 short sentences look best in the app!",
        component: (workoutPlan: BaseWorkoutPlan) => (
          <CheckWorkoutPlanDescription
            workoutPlan={workoutPlan}
            updateNewValue={updateWorkoutPlanDescription}
            onClickPrimary={goToNext}
            onClickSecondary={goToPrev}
            isLoading={isLoading}
            isError={isError}
          />
        ),
      },
      [PublishPlanCheckKey.PLAN_THUMBNAIL]: {
        title: "Pick some nice artwork",
        planAttribute: "thumbnail",
        label: "Upload cover photo",
        subtitle: "Remember, this will be all over your story!",
        component: () => (
          <CheckWorkoutPlanArtwork
            workoutPlan={workoutPlan}
            updateNewValue={updateWorkoutPlanArtwork}
            onClickPrimary={goToNext}
            onClickSecondary={goToPrev}
            isLoading={isLoading}
            isError={isError}
          />
        ),
      },
      [PublishPlanCheckKey.PLAN_SUMMARY]: {
        title: "Summary",
        planAttribute: "summary" as any,
        label: "Summary",
        subtitle: "Final checks before lift-off!",
        component: () => (
          <CheckWorkoutPlanOverview
            workoutPlan={workoutPlan}
            onClickEditName={() =>
              setCurrentStep(PublishPlanCheckKey.PLAN_NAME)
            }
            onClickEditDescription={() =>
              setCurrentStep(PublishPlanCheckKey.PLAN_DESCRIPTION)
            }
            onClickEditArtwork={() =>
              setCurrentStep(PublishPlanCheckKey.PLAN_THUMBNAIL)
            }
            onClickPrimaryAsync={publishWorkoutPlan}
            onClickSecondary={goToPrev}
            isLoading={isLoading}
          />
        ),
      },
    }),
    [
      goToNext,
      goToPrev,
      isError,
      isLoading,
      publishWorkoutPlan,
      updateWorkoutPlanArtwork,
      updateWorkoutPlanDescription,
      updateWorkoutPlanName,
      workoutPlan,
    ]
  );

  const formattedSteps: Step[] = PUBLISH_PLAN_CHECK_ORDER.map(
    (publishPlanCheckKey) => ({
      label: PUBLISH_PLAN_CHECK_STEPS[publishPlanCheckKey].label,
    })
  );

  const closeModalAndRefreshPlans = () => {
    onClose();
    refreshWorkoutPlans();
  };

  return {
    workoutPlan,
    isLoading,
    currentStep,
    currentStepIndex,
    goToPrev,
    onPressPrimary: isLastStep ? publishWorkoutPlan : goToNext,
    PUBLISH_PLAN_CHECK_STEPS,
    resetPublishPlanCheck,
    onPressStep,
    formattedSteps,
    isShowingAPIErrorMessage,
    hideAPIErrorMessage,
    closeModalAndRefreshPlans,
  };
};
