import { RefObject, useEffect, useRef, useState } from "react";

type ShouldDragPredicate = (
  target: HTMLElement,
  element: HTMLDivElement
) => boolean;

export const useDragScroll = (
  shouldDrag?: ShouldDragPredicate
): RefObject<HTMLDivElement> => {
  const ref = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number | null>(null);
  const [scrollLeft, setScrollLeft] = useState<number | null>(null);

  useEffect(() => {
    const element = ref.current;

    const handleMouseDown = (event: MouseEvent) => {
      if (!element) return;

      const target = event.target as HTMLElement;
      if (shouldDrag && !shouldDrag(target, element)) {
        setIsDragging(false);
        return;
      }
      setIsDragging(true);
      setStartX(event.pageX - (element?.offsetLeft || 0));
      setScrollLeft(element?.scrollLeft || 0);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isDragging || !element) return;
      event.preventDefault();
      const x = event.pageX - (element?.offsetLeft || 0);
      const distance = (x - (startX || 0)) * 1;
      element.scrollLeft = (scrollLeft || 0) - distance;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    element?.addEventListener("mousedown", handleMouseDown);
    element?.addEventListener("mousemove", handleMouseMove);
    document?.addEventListener("mouseup", handleMouseUp);

    return () => {
      element?.removeEventListener("mousedown", handleMouseDown);
      element?.removeEventListener("mousemove", handleMouseMove);
      document?.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startX, scrollLeft, shouldDrag]);

  return ref;
};
