import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { PaginatedAPI } from "../types";
import { favouritesActions } from "./slice";
import { transformFavouriteWorkoutsFromAPI } from "./transformer";
import { FavouriteWorkoutAPI } from "./types";

export const GetFavouriteWorkoutsPaginated = async (
  dispatch: DispatchFn,
  pageNumber: number
) => {
  dispatch(favouritesActions.getFavouriteWorkoutsAttempt());
  try {
    const response = await client.get<PaginatedAPI<FavouriteWorkoutAPI>>(
      "/builder/favourite/workout",
      { params: { page: pageNumber } }
    );
    const favourites = transformFavouriteWorkoutsFromAPI(response.data.results);
    dispatch(favouritesActions.setFavouriteWorkoutsCount(response.data.count));
    dispatch(
      favouritesActions.getFavouriteWorkoutsSuccess({ pageNumber, favourites })
    );
  } catch {
    dispatch(favouritesActions.getFavouriteWorkoutsFailure());
  }
};

export const GetAllFavouriteWorkouts = async (dispatch: DispatchFn) => {
  dispatch(favouritesActions.getAllFavouriteWorkoutsAttempt());
  try {
    const response = await client.get<FavouriteWorkoutAPI[]>(
      "/builder/all-favourite-workouts"
    );
    const favourites = transformFavouriteWorkoutsFromAPI(response.data);
    dispatch(favouritesActions.getAllFavouriteWorkoutsSuccess({ favourites }));
  } catch {
    dispatch(favouritesActions.getAllFavouriteWorkoutsFailure());
  }
};
