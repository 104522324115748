import React from "react";
import { Autocomplete, TextField, Chip, Stack } from "@mui/material";

interface AutocompleteInputProps<T> {
  options: T[];
  getOptionLabel: (option: T) => string;
  label?: string;
  placeholder?: string;
  selectedValues: T[];
  onSelectedValuesChange: (newValues: T[]) => void;
  width?: string | number;
  variant?: "standard" | "outlined" | "filled";
}

const GenericAutocomplete = <T,>({
  options,
  getOptionLabel,
  label,
  placeholder,
  selectedValues,
  onSelectedValuesChange,
  width = 400,
  variant = "outlined",
}: AutocompleteInputProps<T>) => {
  return (
    <Stack spacing={3} sx={{ width }}>
      <Autocomplete
        multiple
        options={options}
        getOptionLabel={getOptionLabel}
        value={selectedValues}
        onChange={(event, newValue) => {
          onSelectedValuesChange(newValue);
        }}
        renderTags={(value: readonly T[], getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                variant="outlined"
                label={getOptionLabel(option)}
                {...tagProps}
                sx={{
                  backgroundColor: "primary.hover",
                  color: "text.primary",
                }}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </Stack>
  );
};

export default GenericAutocomplete;
