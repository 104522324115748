import React from "react";

import { Workout } from "../../../common/Workout";
import { WorkoutSkeleton } from "../../../common/Workout/WorkoutSkeleton";
import { useWorkoutViewer } from "./useWorkoutViewer";

interface Props extends ReturnType<typeof useWorkoutViewer> {}

export const WorkoutViewer: React.FC<Props> = ({ isLoading, workout }) => {
  return (
    <>
      {isLoading && !workout && <WorkoutSkeleton />}
      {workout && <Workout workout={workout} />}
    </>
  );
};
