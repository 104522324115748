import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultUserState, User } from "./types";

const userSlice = createSlice({
  name: "user",
  initialState: defaultUserState,
  reducers: {
    signInAttempt: (state) => {
      state.user.isError = false;
      state.user.isLoading = true;
    },
    signInSuccess: (state, action: PayloadAction<User>) => {
      state.user.isLoading = false;
      state.user.isError = false;
      state.user.data = action.payload;
    },
    signInFailure: (state) => {
      state.user.isLoading = false;
      state.user.isError = true;
    },
    reset: () => defaultUserState,
  },
});

export const UserReducer = userSlice.reducer;
export const userActions = userSlice.actions;
