// NB: formats with more params should go first
const POSSIBLE_YOUTUBE_FORMATS_REGEX = [
  /https:\/\/(?:www.)?youtube\.com\/watch\?v=(.{11})&.+$/,
  /https:\/\/(?:www.)?youtube\.com\/watch\?v=(.{11})$/,
  /https:\/\/(?:www.)?youtu\.be\/(.{11})$/,
  /https:\/\/(?:www.)?youtube\.com\/shorts\/(.{11})$/,
  /https:\/\/(?:www.)?youtube\.com\/shorts\/(.{11})\?.+$/,
];

const matchFormatAndReturnVideoId = (
  testRegex: RegExp,
  inputString: string
): string | null => {
  const matchResult = inputString.match(testRegex);
  if (matchResult) {
    return matchResult[1] as string;
  }
  return null;
};

export const extractVideoIdFromUrl = (input: string): string | null => {
  const correctRegex = POSSIBLE_YOUTUBE_FORMATS_REGEX.find((regexFormat) =>
    regexFormat.test(input)
  );
  if (!correctRegex) {
    return null;
  }
  return matchFormatAndReturnVideoId(correctRegex, input);
};

export const isAcceptedYouTubeFormat = (input: string) => {
  const correctRegex = POSSIBLE_YOUTUBE_FORMATS_REGEX.find((regexFormat) =>
    regexFormat.test(input)
  );
  return Boolean(correctRegex);
};

export const getYoutubeThumbnailURL = (videoId: string) => {
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const getYoutubeThumbnailURLFromLink = (videoLink: string) => {
  const id = extractVideoIdFromUrl(videoLink);
  if (!id) throw Error(`Invalid youtube link: ${videoLink}`);
  return getYoutubeThumbnailURL(id);
};

export const getUniformYouTubeLink = (videoLink: string) => {
  const id = extractVideoIdFromUrl(videoLink);
  if (!id) return videoLink;
  return `https://youtu.be/${id}`;
};
