import { Container, Box, Typography } from "@mui/material";
import React from "react";

export const DesktopOnlyMessage: React.FC = () => {
  return (
    <Box display="flex" flex={1}>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom color={"primary"}>
          This app is currently only available on larger screen sizes.
        </Typography>
        <Typography variant="body1">
          Apologies for any inconvenience.
        </Typography>
      </Container>
    </Box>
  );
};
