import { Help, ShowChartRounded, TableRowsOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Toggle } from "../../../common/Toggle";
import { IconTooltipLabel } from "../../../common/Toggle/IconTooltipLabel";
import { WalletExpressAccountPayout } from "../types";
import { EmptyPayouts } from "./EmptyPayouts";
import { PayoutsComparison } from "./PayoutsComparisons";
import { PayoutsLineChart } from "./PayoutsLineChart";
import { PayoutsTable } from "./PayoutsTable";

interface Props {
  payouts: WalletExpressAccountPayout[];
}

export const Payouts: React.FC<Props> = ({ payouts }) => {
  const [payoutsDisplayMode, setPayoutsDisplayMode] = React.useState<
    "table" | "graph"
  >(() => (payouts.length < 2 ? "table" : "graph"));

  const canDisplayGraph = payouts.length > 1;

  useEffect(() => {
    if (payouts.length < 2) {
      setPayoutsDisplayMode("table");
    } else {
      setPayoutsDisplayMode("graph");
    }
  }, [payouts]);

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">
            Payouts
            <Tooltip title={"The monthly payout to your bank account."}>
              <IconButton size="small">
                <Help color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          </Typography>
          {canDisplayGraph && (
            <Toggle
              options={[
                {
                  label: (
                    <IconTooltipLabel
                      icon={ShowChartRounded}
                      tooltip="Display your payouts in a graph."
                    />
                  ),
                  value: "graph",
                },
                {
                  label: (
                    <IconTooltipLabel
                      icon={TableRowsOutlined}
                      tooltip="Display your payouts in a table."
                    />
                  ),
                  value: "table",
                },
              ]}
              onChange={(_, value) => {
                value && setPayoutsDisplayMode(value as "graph" | "table");
              }}
              value={payoutsDisplayMode}
            />
          )}
        </Stack>
      </Grid>
      {payouts.length === 0 ? (
        <Grid item xs={12}>
          <Card elevation={1} sx={{ width: "100%" }}>
            <CardContent>
              <EmptyPayouts />
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <>
          {/* TODO: add back in once we can backdate non-connect earnings */}
          {/* <TotalEarnings payouts={payouts} /> */}
          <PayoutsComparison payouts={payouts} />
          <Grid item xs={12}>
            <Card elevation={1} sx={{ overflow: "visible" }}>
              {payoutsDisplayMode === "graph" ? (
                <CardContent style={{ height: 400 }}>
                  <PayoutsLineChart
                    payouts={payouts}
                    currencyCode={payouts[0]?.currency || "usd"}
                  />
                </CardContent>
              ) : (
                <CardContent>
                  <PayoutsTable payouts={payouts} />
                </CardContent>
              )}
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};
