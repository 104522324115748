import { Menu, MenuProps } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: MenuProps["anchorEl"];
}

export const PlaylistCardMenu: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  anchorEl,
  children,
}) => {
  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <Menu open={isOpen} onClose={onClose} anchorEl={anchorEl}>
        {children}
      </Menu>
    </Paper>
  );
};
