import { transformWorkoutFromAPI } from "../workout/transformer";
import { FavouriteWorkoutAPI, FavouriteWorkout } from "./types";

export const transformFavouriteWorkoutFromAPI = (
  api: FavouriteWorkoutAPI
): FavouriteWorkout => {
  return {
    id: api.id,
    workout: transformWorkoutFromAPI(api.workout),
  };
};

export const transformFavouriteWorkoutsFromAPI = (
  api: FavouriteWorkoutAPI[]
): FavouriteWorkout[] => {
  return api.map(transformFavouriteWorkoutFromAPI);
};
