import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { payoutsActions } from "./slice";
import {
  transformExpressAccountFromAPI,
  transformPayoutInformationFromAPI,
} from "./transformer";
import { ExpressAccountResponse, PayoutInformationAPI } from "./types";

export const GetExpressAccount = async (dispatch: DispatchFn) => {
  dispatch(payoutsActions.getExpressAccountAttempt());
  try {
    const response = await client.get<ExpressAccountResponse>(
      "/payments/express-account"
    );
    const expressAccount =
      response.data.express_account &&
      transformExpressAccountFromAPI(response.data.express_account);
    dispatch(payoutsActions.getExpressAccountSuccess(expressAccount));
  } catch {
    dispatch(payoutsActions.getExpressAccountFailure());
  }
};

export const GetPayoutInformation = async (dispatch: DispatchFn) => {
  dispatch(payoutsActions.getPayoutInformationAttempt());
  try {
    const response = await client.get<PayoutInformationAPI>(
      "/payments/express-account/payouts"
    );
    const payoutInformation = transformPayoutInformationFromAPI(response.data);
    dispatch(payoutsActions.getPayoutInformationSuccess(payoutInformation));
  } catch {
    dispatch(payoutsActions.getPayoutInformationFailure());
  }
};
