import { MovieCreation } from "@mui/icons-material";
import React from "react";
import { EmptyList } from "../../../common/EmptyList";

interface Props {
  onClickCreateWorkout: () => void;
}

export const EmptyExercises: React.FC<Props> = ({ onClickCreateWorkout }) => {
  return (
    <EmptyList
      onClickButton={onClickCreateWorkout}
      primaryText={"No exercises"}
      secondaryText={
        "Exercises that you've used in workouts will show here! Start by creating a workout plan."
      }
      buttonText={"Create Workout Plan"}
      icon={<MovieCreation />}
    />
  );
};
