import { ArrowDownward, ArrowUpward, Remove } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { linearGradientDef } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import React, { useMemo } from "react";

interface Value {
  value: number;
  formattedValue: string;
  label: string;
}
interface Props {
  value: Value;
  referenceValue: Value;
  disabled?: boolean;
}

export const ComparisonStatistic: React.FC<Props> = ({
  value,
  referenceValue,
  disabled,
}) => {
  const percentageChange = useMemo(() => {
    return ((value.value - referenceValue.value) / referenceValue.value) * 100;
  }, [value, referenceValue]);

  const isPositive = percentageChange > 0;
  const isEqual = percentageChange === 0;
  const isNegative = percentageChange < 0;
  const formattedPercentageChange = `${
    isPositive ? "+" : ""
  }${percentageChange.toFixed(0)}%`;
  const theme = useTheme();

  return (
    <Card elevation={1} sx={{ height: "100%" }}>
      <Box
        sx={{ position: "relative", display: "flex", flex: 1, width: "100%" }}
      >
        <CardContent sx={{ width: "100%" }}>
          <Stack gap={2} flex={1}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Typography color="text.secondary">{value.label}</Typography>
            </Stack>
            <Stack direction={"row"} flex={1} alignItems={"flex-end"}>
              <Stack gap={1} flex={1}>
                <Typography variant="h5" fontWeight={800} gutterBottom={false}>
                  {value.formattedValue}
                </Typography>
                <Stack direction={"row"} alignItems="center">
                  {isPositive && (
                    <ArrowUpward color="success" fontSize="small" />
                  )}
                  {isNegative && (
                    <ArrowDownward color="error" fontSize="small" />
                  )}
                  {isEqual && <Remove color="info" fontSize="small" />}
                  <Typography color={"text.secondary"}>
                    <Typography
                      display={"inline"}
                      color={
                        isPositive
                          ? "success.main"
                          : isNegative
                          ? "error.main"
                          : "info.main"
                      }
                      fontWeight={"bold"}
                    >
                      {formattedPercentageChange}
                    </Typography>{" "}
                    vs {referenceValue.label}
                  </Typography>
                </Stack>
              </Stack>
              <Stack height={50} flex={0.5}>
                <ResponsiveLine
                  curve="natural"
                  colors={Object.values(
                    isPositive
                      ? theme.palette.success
                      : isNegative
                      ? theme.palette.error
                      : theme.palette.info
                  )}
                  yScale={{
                    type: "linear",
                  }}
                  margin={{ top: 2, right: 2, bottom: 2, left: 2 }}
                  axisBottom={null}
                  enableArea={true}
                  axisLeft={null}
                  axisTop={null}
                  axisRight={null}
                  enableGridX={false}
                  enableGridY={false}
                  pointSize={0}
                  defs={[
                    linearGradientDef("main", [
                      { offset: 0, color: "inherit" },
                      { offset: 100, color: "inherit", opacity: 0.25 },
                    ]),
                  ]}
                  fill={[{ match: "*", id: "main" }]}
                  data={[
                    {
                      id: "value",
                      data: [
                        { x: 0, y: referenceValue.value },
                        {
                          x: 0.2,
                          y:
                            referenceValue.value +
                            (value.value - referenceValue.value) * 0.1,
                        },
                        { x: 1, y: value.value },
                      ],
                    },
                  ]}
                />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        {disabled && (
          <Tooltip
            title={"You'll see your payout performance here once it's ready."}
          >
            <Stack
              sx={{
                position: "absolute",
                background: "rgba(25, 25, 25, 0)",
                borderRadius: "16px",
                backdropFilter: "blur(10px)",
                border: "1px solid rgba(255, 255, 255, 0.12)",
                flex: 1,
              }}
              width={"100%"}
              height={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography color="text.secondary">
                {"Awaiting payout data"}
              </Typography>
            </Stack>
          </Tooltip>
        )}
      </Box>
    </Card>
  );
};
