import GridView from "@mui/icons-material/GridView";
import React from "react";
import { EmptyList } from "../../../common/EmptyList";

export const EmptyChallengePlanTransformations: React.FC = () => {
  return (
    <EmptyList
      primaryText={"No challenge plan transformations"}
      icon={<GridView />}
    />
  );
};
