import { Search } from "@mui/icons-material";
import { Box, InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SearchFieldProps {
  isSearchFieldDisabled: boolean;
  handleOnChangeSearchField: (text: string) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  isSearchFieldDisabled,
  handleOnChangeSearchField,
}) => {
  return (
    <Box display="flex" alignSelf="stretch">
      <TextField
        label="Search Favourite Workouts"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{
          width: "100%",
        }}
        onChange={(event) => handleOnChangeSearchField(event.target.value)}
        disabled={isSearchFieldDisabled}
      />
    </Box>
  );
};
