import {
  ChallengePlan,
  ChallengePlanEntry,
  ChallengePlanEntryApi,
  ChallengePlansApi,
  ChallengePlanTransformation,
  ChallengePlanTransformationsApi,
  Media,
  MediaApi,
  Playlist,
  PlaylistApi,
  ProgressPicture,
  ProgressPictureApi,
  RunThroughApi,
  User,
  UserApi,
  WorkoutPlanRunThrough,
} from "./types";

export const transformChallengePlansApi = (
  api: ChallengePlansApi
): ChallengePlan[] => {
  return api.challenge_plans.map((plan) => ({
    id: plan.id,
    name: plan.name,
  }));
};

export const transformChallengePlanTransformationsFromAPI = (
  api: ChallengePlanTransformationsApi[]
): ChallengePlanTransformation[] =>
  api.map(transformChallengePlanTransformationFromAPI);

const transformChallengePlanTransformationFromAPI = (
  api: ChallengePlanTransformationsApi
): ChallengePlanTransformation => ({
  id: api.id,
  runThrough: transformRunThroughFromAPI(api.run_through),
  beforeEntry: transformChallengePlanEntryFromAPI(api.before_entry),
  afterEntry: api.after_entry
    ? transformChallengePlanEntryFromAPI(api.after_entry)
    : null,
  completedAt: api.completed_at,
  hasPublicSharingConsent: api.has_public_sharing_consent,
  user: transformUserFromAPI(api.user),
});

const transformRunThroughFromAPI = (
  api: RunThroughApi
): WorkoutPlanRunThrough => ({
  id: api.id,
  playlist: transformPlaylistFromAPI(api.playlist),
  numberOfCompletedWorkoutDays: api.number_of_completed_workout_days,
});

const transformPlaylistFromAPI = (api: PlaylistApi): Playlist => ({
  id: api.id,
  name: api.name,
  thumbnail: api.thumbnail,
  numberOfWorkouts: api.number_of_workouts,
});

const transformChallengePlanEntryFromAPI = (
  api: ChallengePlanEntryApi
): ChallengePlanEntry => ({
  id: api.id,
  front: transformProgressPictureFromAPI(api.front),
  back: transformProgressPictureFromAPI(api.back),
  side: transformProgressPictureFromAPI(api.side),
  submittedAt: api.submitted_at,
});

const transformProgressPictureFromAPI = (
  api: ProgressPictureApi
): ProgressPicture => ({
  id: api.id,
  media: transformMediaFromAPI(api.media),
  capturedAt: api.captured_at,
});

const transformMediaFromAPI = (api: MediaApi): Media => ({
  id: api.id,
  fileName: api.file_name,
  url: api.url,
});

const transformUserFromAPI = (api: UserApi): User => ({
  id: api.id,
  firstName: api.first_name,
  lastName: api.last_name,
  username: api.username,
  email: api.email,
});
