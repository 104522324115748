import { KeyboardBackspace, Redo, Undo } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  ActionButtonsContainer,
  OutlinedActionButton,
  SuccessActionButton,
} from "../../common/ActionButtons";
import { ConfirmDialog } from "../../common/Dialog";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import {
  EditWorkoutPlanDescription,
  EditWorkoutPlanName,
} from "../WorkoutPlan/WorkoutPlanEdit";
import { MyFavouritedWorkoutsList } from "./MyFavouritedWorkoutsList";
import { Weeks } from "./Weeks";
import { WorkoutPlanBuilderSkeleton } from "./WorkoutPlanBuilderSkeleton";
import { useWorkoutPlanBuilder } from "./useWorkoutPlanBuilder";

interface Props extends ReturnType<typeof useWorkoutPlanBuilder> {}

export const WorkoutPlanBuilder: React.FC<Props> = ({
  workoutLists,
  addWeek,
  onDragEnd,
  myWorkoutsList,
  deleteWeekAtIndex,
  duplicateWeekAtIndex,
  addRestToWeekAtIndex,
  isShowingDaysInWeekWarning,
  hideDaysInWeekWarning,
  isLoadingMyWorkouts,
  refreshMyWorkouts,
  dragScrollRef,
  currentWorkoutPlan,
  workoutPlanResource,
  navigateToViewPlan,
  removeItemFromWeekIndexAtIndex,
  saveWorkoutPlanDialog,
  hideWorkoutPlanResourceError,
  isWorkoutPlanResourceError,
  hasPlanBuilderStateChanged,
  navigateToWeekAndDay,
  createPlaylistWorkoutDay,
  isCreatingNewWorkout,
  toggleFavouriteWorkoutAtWeekAndDay,
  isFavouritingWorkout,
  isUpdateWorkoutResourceError,
  hideUpdateWorkoutResourceError,
  redo,
  undo,
  canRedo,
  canUndo,
  duplicateItemInWeekIndexAtIndex,
}) => {
  const myWorkoutsListRef = useRef<HTMLDivElement | null>(null);
  if (workoutPlanResource.isLoading) {
    return <WorkoutPlanBuilderSkeleton />;
  }
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          width: "50%",
        }}
        flexGrow={1}
        height="100vh"
        bgcolor={"common.white"}
      >
        <Box
          display="flex"
          p={1}
          justifyContent="space-between"
          justifySelf="stretch"
        >
          <Box
            display="flex"
            flexDirection="column"
            width="50%"
            overflow="none"
          >
            {currentWorkoutPlan && (
              <>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={navigateToViewPlan}>
                    <KeyboardBackspace fontSize="small" />
                  </IconButton>
                  <Typography variant="body2" color="GrayText">
                    View Mode
                  </Typography>
                </Box>
                <EditWorkoutPlanName workoutPlan={currentWorkoutPlan} />
                <EditWorkoutPlanDescription workoutPlan={currentWorkoutPlan} />
              </>
            )}
          </Box>
          <ActionButtonsContainer>
            <Box>
              <Tooltip title="Undo">
                <span>
                  <IconButton onClick={undo} disabled={!canUndo}>
                    <Undo />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Redo">
                <span>
                  <IconButton onClick={redo} disabled={!canRedo}>
                    <Redo />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
            <SuccessActionButton
              isActive={hasPlanBuilderStateChanged}
              onClick={saveWorkoutPlanDialog.openDialog}
              text="Save Changes"
              inactiveText="Changes Saved"
              label="Contains unsaved changes"
              inactiveLabel="No new changes"
            />
            <OutlinedActionButton
              isDisabled={hasPlanBuilderStateChanged}
              onClick={navigateToViewPlan}
              text="View Mode"
              tooltipText="Switch to view mode"
              disabledTooltipText="You have unsaved changes"
            />
          </ActionButtonsContainer>
        </Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box display="flex" sx={{ height: "50%" }} flexGrow={1}>
            <Box display="flex">
              <MyFavouritedWorkoutsList
                myFavouritedWorkoutsListRef={myWorkoutsListRef}
                onClickRefreshButton={refreshMyWorkouts}
                isLoadingMyWorkouts={isLoadingMyWorkouts}
                myWorkouts={myWorkoutsList}
              />
            </Box>
            <Box
              display="flex"
              pl={2}
              sx={{ overflowX: "scroll" }}
              ref={dragScrollRef}
            >
              {!!workoutLists.length && (
                <Weeks
                  workoutLists={workoutLists}
                  handleDeleteList={deleteWeekAtIndex}
                  handleDuplicateList={duplicateWeekAtIndex}
                  handleAddRest={addRestToWeekAtIndex}
                  handleRemoveItem={removeItemFromWeekIndexAtIndex}
                  navigateToWeekAndDay={navigateToWeekAndDay}
                  hasUnsavedChanges={hasPlanBuilderStateChanged}
                  onSubmitCreateNewWorkout={createPlaylistWorkoutDay}
                  isCreatingNewWorkout={isCreatingNewWorkout}
                  toggleFavouriteWorkout={toggleFavouriteWorkoutAtWeekAndDay}
                  isFavouritingWorkout={isFavouritingWorkout}
                  handleDuplicateItem={duplicateItemInWeekIndexAtIndex}
                />
              )}
              <Box>
                <Paper
                  sx={{
                    height: 150,
                    width: 300,
                    backgroundColor: "primary.main",
                    color: "white",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor: "primary.light",
                    },
                  }}
                >
                  <Button
                    onClick={addWeek}
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: 800,
                    }}
                  >
                    Add New Week
                  </Button>
                </Paper>
              </Box>
            </Box>
          </Box>
        </DragDropContext>
        <ConfirmDialog {...saveWorkoutPlanDialog.props} />
        <SnackbarAlert
          isOpen={isWorkoutPlanResourceError}
          close={hideWorkoutPlanResourceError}
          message="Oops, Something went wrong! Please try again later."
          severity="error"
        />
        <SnackbarAlert
          isOpen={isShowingDaysInWeekWarning}
          close={hideDaysInWeekWarning}
          severity="warning"
          message="You can't add more than 7 workouts (or rest days) to a single week!"
        />
        <SnackbarAlert
          isOpen={isUpdateWorkoutResourceError}
          close={hideUpdateWorkoutResourceError}
          severity="warning"
          message="Oops! There was an issue with updating this workout."
        />
      </Box>
    </>
  );
};
