import { transformCreatorExerciseFromAPI } from "../exercises/transformer";
import { CreatorExercise, CreatorExerciseAPI } from "../exercises/types";
import { Exercise, ExerciseAPI } from "./types";

export const transformExerciseFromAPI = (api: ExerciseAPI): Exercise => ({
  id: api.id,
  name: api.name,
  muscleGroup: api.major_muscle_group,
});

export const transformExercisesFromAPI = (api: ExerciseAPI[]): Exercise[] =>
  api.map(transformExerciseFromAPI);

export const transformCreatorExercisesFromAPI = (
  api: CreatorExerciseAPI[]
): CreatorExercise[] => api.map(transformCreatorExerciseFromAPI);
