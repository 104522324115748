import { Container, ContainerProps } from "@mui/material";
import React from "react";
import { PageHeaderWithButton } from "../PageHeader";

interface PageTemplateProps extends ContainerProps {
  fullWidthHeader?: React.ReactNode;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  fullWidthHeader,
  ...props
}) => {
  return (
    <Container>
      {fullWidthHeader}
      <Container maxWidth="lg" sx={{ p: 2 }} {...props} />
    </Container>
  );
};

interface Props extends React.ComponentProps<typeof PageHeaderWithButton> {}

export const PageTemplateWithHeader: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, ...pageHeaderProps }) => {
  return (
    <PageTemplate>
      <PageHeaderWithButton {...pageHeaderProps} />
      {children}
    </PageTemplate>
  );
};
