import React from "react";
import { Stepper as Component } from "./Stepper";

export interface Step {
  label: string;
  isCompleted?: boolean;
}

export interface StepperProps {
  activeStepIndex: number;
  steps: Step[];
  onClickStep?: (selectedStepIndex: number) => void;
}

export const Stepper: React.FC<StepperProps> = (props) => {
  return <Component {...props} />;
};
