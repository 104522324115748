import React from "react";
import { useWorkoutPlan } from "./useWorkoutPlan";
import { WorkoutPlan as Component } from "./WorkoutPlan";

export interface WorkoutPlanProps extends ReturnType<typeof useWorkoutPlan> {}

export const WorkoutPlan: React.FC = () => {
  const props = useWorkoutPlan();
  return <Component {...props} />;
};
