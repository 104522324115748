import { combineReducers, configureStore } from "@reduxjs/toolkit";
import React from "react";
import { Provider, TypedUseSelectorHook, useSelector } from "react-redux";
import { ExerciseReducer } from "./exercises/slice";
import { defaultExerciseState } from "./exercises/types";
import { FavouritesReducer } from "./favourites/slice";
import { defaultFavouritesState } from "./favourites/types";
import { PayoutsReducer } from "./payouts/slice";
import { defaultPayoutsState } from "./payouts/types";
import { PlaylistReducer } from "./playlist/slice";
import { defaultPlaylistState } from "./playlist/types";
import { TransformationsReducer } from "./transformations/slice";
import { defaultTransformationsState } from "./transformations/types";
import { UserReducer } from "./user/slice";
import { defaultUserState } from "./user/types";
import { WorkoutBuilderReducer } from "./workout-builder/slice";
import { defaultWorkoutBuilderState } from "./workout-builder/types";
import { WorkoutPlanReducer } from "./workout-plan/slice";
import { defaultWorkoutPlanState } from "./workout-plan/types";
import { WorkoutReducer } from "./workout/slice";
import { defaultWorkoutState } from "./workout/types";

const store = configureStore({
  reducer: combineReducers({
    userState: UserReducer,
    playlistState: PlaylistReducer,
    workoutState: WorkoutReducer,
    exerciseState: ExerciseReducer,
    workoutPlanState: WorkoutPlanReducer,
    workoutBuilderState: WorkoutBuilderReducer,
    favouritesState: FavouritesReducer,
    payoutsState: PayoutsReducer,
    transformationsState: TransformationsReducer,
  }),
});

export type RootStore = ReturnType<typeof store.getState>;
export type DispatchFn = typeof store.dispatch;

interface Props {}

export const StoreProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  return <Provider store={store}>{children}</Provider>;
};

export const defaultState: RootStore = {
  userState: defaultUserState,
  playlistState: defaultPlaylistState,
  workoutState: defaultWorkoutState,
  exerciseState: defaultExerciseState,
  workoutPlanState: defaultWorkoutPlanState,
  workoutBuilderState: defaultWorkoutBuilderState,
  favouritesState: defaultFavouritesState,
  payoutsState: defaultPayoutsState,
  transformationsState: defaultTransformationsState,
};

export const useStore = () => {
  const select: TypedUseSelectorHook<RootStore> = useSelector;
  const dispatch = store.dispatch;
  const getState = store.getState;
  return [select, dispatch, getState] as const;
};
