import {
  NoPhotographyOutlined as CameraIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import { Box, Button, CardMedia, Tooltip, Typography } from "@mui/material";
import React from "react";
import { TransformationPicture } from "../../../types";

export const TransformationImage: React.FC<
  Partial<TransformationPicture> & {
    hasPublicSharingConsent: boolean;
    downloadImage: (imageUrl: string) => Promise<void>;
  }
> = ({ image, submittedAt, hasPublicSharingConsent, downloadImage }) => {
  return (
    <Box sx={{ width: "49%", position: "relative" }}>
      {image && (
        <Box sx={{ position: "absolute", right: 5, top: 5 }}>
          <Tooltip
            title="User didn't give consent to share this image"
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            disableHoverListener={hasPublicSharingConsent}
          >
            <Box>
              <Button
                size="small"
                sx={{
                  minWidth: "unset",
                  "&:disabled": {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "grey.500",
                  },
                }}
                onClick={() => downloadImage(image)}
                disabled={!hasPublicSharingConsent}
                variant="contained"
              >
                <DownloadIcon fontSize="small" />
              </Button>
            </Box>
          </Tooltip>
        </Box>
      )}
      {image ? (
        <CardMedia
          component="img"
          image={image}
          sx={{
            height: 220,
            borderRadius: 0,
            marginBottom: 0,
            objectFit: "cover",
            pointerEvents: "none",
          }}
        />
      ) : (
        <Box
          sx={{
            height: 220,
            borderRadius: 0,
            backgroundColor: "grey.100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CameraIcon sx={{ fontSize: 40, color: "grey.400" }} />
        </Box>
      )}
      {image && (
        <Typography
          variant="caption"
          sx={{
            bottom: 0,
            width: "100%",
            textAlign: "center",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: "2px 0",
          }}
        >
          {submittedAt}
        </Typography>
      )}
    </Box>
  );
};
