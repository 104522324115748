import React from "react";
import { ExerciseParameter } from "../../../store/workout/types";
import { TableCell, Typography } from "@mui/material";
import { formattersByParameter } from "../../pages/WorkoutBuilder/ExerciseEntryDataGrid/EditCells/config";

interface ParameterProps {
  parameter: ExerciseParameter;
  parameterValue: number;
}

export const Parameter: React.FC<ParameterProps> = ({
  parameter,
  parameterValue,
}) => {
  return (
    <TableCell>
      <Typography>
        {formattersByParameter[parameter.name](parameterValue)}
      </Typography>
    </TableCell>
  );
};
