import { transformWorkoutFromAPI } from "../workout/transformer";
import { ScheduleItemAPI, ScheduleItem, WeekAndDay } from "../workout/types";
import {
  BaseWorkoutPlan,
  BaseWorkoutPlanAPI,
  WorkoutPlan,
  WorkoutPlanAPI,
} from "./types";

export const transformBaseWorkoutPlanFromAPI = (
  api: BaseWorkoutPlanAPI
): BaseWorkoutPlan => ({
  id: api.id,
  name: api.name,
  description: api.description,
  updatedAt: api.updated_at,
  publishedAt: api.published_at,
  thumbnail: api.thumbnail,
  assignations: api.assignations,
});

export const transformAllBaseWorkoutPlansFromAPI = (
  results: BaseWorkoutPlanAPI[]
): BaseWorkoutPlan[] => results.map(transformBaseWorkoutPlanFromAPI);

export const transformAllWorkoutPlansFromAPI = (
  results: WorkoutPlanAPI[]
): WorkoutPlan[] => results.map(transformWorkoutPlanFromAPI);

export const transformWorkoutPlanFromAPI = (
  api: WorkoutPlanAPI
): WorkoutPlan => ({
  id: api.id,
  name: api.name,
  description: api.description,
  updatedAt: api.updated_at,
  publishedAt: api.published_at,
  thumbnail: api.thumbnail,
  schedule: api.schedule.map(transformScheduleItemFromAPI),
  assignations: api.assignations,
});

export const transformScheduleItemFromAPI = (
  api: ScheduleItemAPI
): ScheduleItem => {
  return {
    weekAndDay: transformDayToDayOfWeek(api.day),
    id: api.id,
    workout: api.workout ? transformWorkoutFromAPI(api.workout) : null,
  };
};

export const transformDayToDayOfWeek = (day: number): WeekAndDay => ({
  dayOfWeek: ((day - 1) % 7) + 1,
  week: Math.ceil(day / 7),
  dayNumber: day,
});

export const transformWeekAndDayToDayOfPlan = ({
  week,
  day,
}: {
  week: number;
  day: number;
}) => {
  return (week - 1) * 7 + day;
};
