import { DeleteOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";

interface Props {
  onClick?: () => void;
  disabled?: boolean;
}

export const DeleteGridActionsCellItem: React.FC<Props> = ({
  onClick,
  disabled,
}) => {
  return (
    <GridActionsCellItem
      icon={
        <Tooltip title="Delete set">
          <DeleteOutline />
        </Tooltip>
      }
      label="Delete"
      onClick={onClick}
      key="Delete"
      disabled={disabled}
    />
  );
};
