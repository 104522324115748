import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  TransformationCardProps,
  TransformationPicAngle,
  TransformationType,
} from "../../types";
import { pluralize } from "../../../../../util/string";

export const useTransformationCard = ({
  name,
  email,
  workoutPlanName,
  totalWorkouts,
  workoutsCompleted,
  workoutsCompletedPercentage,
  transformationTime,
  transformationAngles,
  hasPublicSharingConsent,
}: TransformationCardProps) => {
  const [selectedAngle, setSelectedAngle] = useState<TransformationPicAngle>(
    TransformationPicAngle.FRONT
  );
  const [isWorkoutPlanNameTruncated, setIsWorkoutPlanNameTruncated] =
    useState<boolean>(false);
  const workoutPlanNameRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (workoutPlanNameRef.current) {
      const { scrollWidth, clientWidth } = workoutPlanNameRef.current;
      setIsWorkoutPlanNameTruncated(scrollWidth > clientWidth);
    }
  }, [workoutPlanName]);

  const handleAngleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newAngle: TransformationPicAngle) => {
      if (newAngle !== null) {
        setSelectedAngle(newAngle);
      }
    },
    []
  );

  const selectedAngleBeforePicture = useMemo(
    () => transformationAngles[selectedAngle][TransformationType.BEFORE],
    [transformationAngles, selectedAngle]
  );

  const selectedAngleAfterPicture = useMemo(
    () => transformationAngles[selectedAngle][TransformationType.AFTER],
    [transformationAngles, selectedAngle]
  );

  const downloadImageFromURL = useCallback(
    async (url: RequestInfo | URL, fileName: string) => {
      try {
        const response = await fetch(url, { mode: "cors" });
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.type = "image/jpeg";
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Error downloading image: ", error);
      }
    },
    []
  );

  const downloadImage = useCallback(
    async (imageUrl: string) => {
      const fileName = `${name}_${selectedAngle}_before_${workoutPlanName}_${new Date().toISOString()}.jpeg`;

      await downloadImageFromURL(imageUrl, fileName);
    },
    [name, selectedAngle, workoutPlanName, downloadImageFromURL]
  );

  return {
    name,
    email,
    workoutPlanName,
    selectedAngle,
    handleAngleChange,
    totalWorkouts,
    workoutsCompleted,
    workoutsCompletedPercentage,
    selectedAngleBeforePicture,
    selectedAngleAfterPicture,
    transformationTime:
      transformationTime !== null
        ? `${transformationTime} ${pluralize("day", transformationTime)}`
        : "Not finished yet",
    isWorkoutPlanNameTruncated,
    workoutPlanNameRef,
    downloadImage,
    hasPublicSharingConsent,
  };
};
