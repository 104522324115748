import { RootStore } from "..";
import { Resource } from "../types";
import { Workout } from "./types";

export const selectWorkoutsByPageNumber =
  (pageNumber: number) => (state: RootStore) => {
    if (state.workoutState.workouts.data) {
      return state.workoutState.workouts.data[pageNumber] ?? [];
    }
    return [];
  };

export const selectWorkouts = (state: RootStore) => {
  return state.workoutState.workouts;
};

export const selectAllWorkouts = (state: RootStore): Resource<Workout[]> => {
  return state.workoutState.allWorkouts;
};

export const selectCurrentWorkout = (state: RootStore) =>
  state.workoutState.currentWorkout;

export const selectCreateWorkoutResource = (state: RootStore) =>
  state.workoutState.create;

export const selectRetrieveWorkoutResource = (state: RootStore) =>
  state.workoutState.retrieve;

export const selectUpdateWorkoutResource = (state: RootStore) =>
  state.workoutState.update;

export const selectDestroyWorkoutResource = (state: RootStore) =>
  state.workoutState.destroy;
