import { Close } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { PlanBuilderCardBase } from "./styles";
import { BaseText } from "../../common/BaseText";
import { ConfirmDialog } from "../../common/Dialog";

interface Props {
  draggableId: string;
  index: number;
  onDelete: () => void;
}

export const DraggableRest: React.FC<Props> = ({
  draggableId,
  index,
  onDelete,
}) => {
  const [isConfirmRemoveDialogShowing, setIsConfirmRemoveDialogShowing] =
    useState(false);
  return (
    <>
      <Draggable draggableId={draggableId} index={index}>
        {(props, snapshot) => (
          <PlanBuilderCardBase
            {...props.dragHandleProps}
            {...props.draggableProps}
            ref={props.innerRef}
            sx={{ bgcolor: "#F9FAFB" }}
          >
            <Box display="flex" justifyContent={"space-between"} p={1}>
              <Box
                display="flex"
                flexDirection="column"
                width="80%"
                justifyContent="center"
                p={1}
              >
                <BaseText
                  variant="subtitle2"
                  color="text.secondary"
                  isLoading={snapshot.isDragging}
                  width={snapshot.isDragging ? "20%" : undefined}
                >
                  Day {index + 1}
                </BaseText>
                <Typography noWrap mb={0} sx={{ opacity: 0.5 }}>
                  {"Rest"}
                </Typography>
              </Box>

              <Stack>
                <Stack direction="row">
                  <Tooltip title="Remove rest">
                    <IconButton
                      aria-label="remove rest"
                      size="small"
                      onClick={() => setIsConfirmRemoveDialogShowing(true)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
          </PlanBuilderCardBase>
        )}
      </Draggable>
      <ConfirmDialog
        subtitle={
          "Are you sure you want to remove this Rest Day from this workout plan week?"
        }
        title={"Remove rest"}
        isOpen={isConfirmRemoveDialogShowing}
        onClose={() => setIsConfirmRemoveDialogShowing(false)}
        cancelText="Cancel"
        confirmText="Remove"
        onClickConfirm={onDelete}
        confirmButtonProps={{ color: "error" }}
        onClickCancel={() => setIsConfirmRemoveDialogShowing(false)}
      />
    </>
  );
};
