import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";

interface Props {}

export const ChallengePlanTransformationsLoading: React.FC<Props> = () => {
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      py={2}
      alignItems="stretch"
    >
      {[...new Array(12)].map((_, index) => (
        <Grid item xs={2} sm={4} md={4} key={index}>
          <Card elevation={1}>
            <CardContent sx={{ p: 0 }}>
              <Box sx={{ pl: 2, mt: 2 }}>
                <Typography variant="h5">
                  <Skeleton variant="text" width={"50%"} />
                </Typography>
                <Typography variant="subtitle2">
                  <Skeleton variant="text" width="90%" />
                </Typography>
                <Typography variant="subtitle2">
                  <Skeleton variant="text" width="75%" />
                </Typography>
              </Box>

              <Box sx={{ mt: 2, mb: 2, pr: 2, pl: 2 }}>
                <Typography variant="subtitle1">
                  <Skeleton variant="text" width="85%" />
                </Typography>

                <ToggleButtonGroup
                  value={1}
                  exclusive
                  aria-label="angle selection"
                  sx={{
                    width: "100%",
                    ".MuiToggleButtonGroup-grouped": {
                      flex: 1,
                      border: 0,
                      borderRadius: 2,
                    },
                  }}
                >
                  {[1, 2, 3].map((i) => (
                    <ToggleButton
                      key={i}
                      value={i}
                      fullWidth
                      sx={{ height: 20 }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Skeleton variant="text" width="40%" />
                      </Typography>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </Box>

              <Box
                sx={{
                  gap: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <ChallengePlanTransformationsImageSkeleton />
                <ChallengePlanTransformationsImageSkeleton />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

const ChallengePlanTransformationsImageSkeleton: React.FC = () => {
  return (
    <Box sx={{ flex: 1, position: "relative" }}>
      <Skeleton variant="rectangular" sx={{ pb: "120%" }} />
      <Typography
        variant="caption"
        sx={{
          gap: 1,
          bottom: 0,
          p: "2px 0",
          width: "100%",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
        }}
      >
        <Skeleton
          width="20%"
          variant="text"
          sx={{ backgroundColor: "white" }}
        />
        <Skeleton
          width="40%"
          variant="text"
          sx={{ backgroundColor: "white" }}
        />
      </Typography>
    </Box>
  );
};
