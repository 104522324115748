import React from "react";
import { PageTemplateWithHeader } from "../../common/PageTemplate";

import { Box, Pagination, PaginationItem } from "@mui/material";
import { useMyFavourites } from "./useMyFavourites";

import { Link } from "react-router-dom";
import { FormConfirmDialog } from "../../common/Dialog";
import { EmptyFavourites } from "./EmptyFavourites";
import { FavouritesTable } from "./FavouritesTable";
import { MyFavouritesLoading } from "./MyFavouritesLoading";
import { SnackbarAlert } from "../../common/SnackbarAlert";

interface Props extends ReturnType<typeof useMyFavourites> {}

export const MyFavourites: React.FC<Props> = ({
  workouts,
  currentPage,
  totalPages,
  newWorkoutDialogProps,
  openNewWorkoutDialog,
  totalWorkouts,
  isLoading,
  isShowingErrorMessage,
  hideErrorMessage,
}) => {
  return (
    <>
      <PageTemplateWithHeader
        title="My Favourites"
        buttonTitle="Create Favourite"
        subtitle="Manage your favourites here"
        onClickButton={openNewWorkoutDialog}
      >
        <Box gap={2} display="flex" flexDirection="column" pt={2}>
          {isLoading ? (
            <MyFavouritesLoading />
          ) : (
            <>
              {totalWorkouts ? (
                <FavouritesTable favourites={workouts} />
              ) : (
                <EmptyFavourites
                  onClickCreateFavourite={openNewWorkoutDialog}
                />
              )}
              <Pagination
                count={totalPages}
                page={currentPage}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`/favourites/workout${
                      item.page === 1 ? "" : `?page=${item.page}`
                    }`}
                    {...item}
                  />
                )}
              />
            </>
          )}
        </Box>
        <FormConfirmDialog {...newWorkoutDialogProps} />
      </PageTemplateWithHeader>
      <SnackbarAlert
        close={hideErrorMessage}
        isOpen={isShowingErrorMessage}
        message="Oops! Something went wrong when refreshing your workouts! Please try again later."
        severity="error"
      />
    </>
  );
};
