import { Skeleton, Typography, TypographyProps } from "@mui/material";
import React from "react";
interface BaseTextProps extends TypographyProps<"div"> {
  isLoading?: boolean;
}

export const BaseText: React.FC<React.PropsWithChildren<BaseTextProps>> = ({
  children,
  ...props
}) => {
  return (
    <Typography {...props}>
      {props.isLoading ? <Skeleton /> : children}
    </Typography>
  );
};
