import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Workout } from "../workout/types";
import {
  defaultPlanBuilderState,
  ListMaxLengthViolationError,
  MAX_ITEMS_IN_LIST,
  WorkoutList,
  WorkoutPlanDraggableItem,
} from "./types";
import { randomUUID } from "../../util/uuid";

const planBuilderSlice = createSlice({
  name: "planBuilder",
  initialState: defaultPlanBuilderState,
  reducers: {
    setWorkoutLists: (
      state,
      action: PayloadAction<{ workoutLists: WorkoutList[] }>
    ) => {
      state.planBuilder.workoutLists = action.payload.workoutLists;
    },
    deleteListAtIndex: (state, action: PayloadAction<{ index: number }>) => {
      const newLists = [...state.planBuilder.workoutLists];
      newLists.splice(action.payload.index, 1);
      state.planBuilder.workoutLists = newLists;
    },
    createListAtIndex: (
      state,
      action: PayloadAction<{ index?: number; newList: WorkoutList }>
    ) => {
      const newLists = [...state.planBuilder.workoutLists];
      newLists.splice(
        action.payload.index || newLists.length,
        0,
        action.payload.newList
      );
      state.planBuilder.workoutLists = newLists;
    },
    duplicateListAtIndex: (
      state,
      action: PayloadAction<{ copyFromIndex: number }>
    ) => {
      const newLists = [...state.planBuilder.workoutLists];
      const selectedList = newLists[action.payload.copyFromIndex];
      const newList: WorkoutList = {
        id: randomUUID(),
        workouts: selectedList.workouts.map((item) => ({
          workout: item.workout,
          uuid: randomUUID(),
        })),
      };
      newLists.splice(action.payload.copyFromIndex, 0, newList);
      state.planBuilder.workoutLists = newLists;
    },
    duplicateCardAtIndex: (
      state,
      action: PayloadAction<{ listIndex: number; cardIndex: number }>
    ) => {
      const { listIndex, cardIndex } = action.payload;
      const newLists = [...state.planBuilder.workoutLists];
      const itemToDuplicate = newLists[listIndex].workouts[cardIndex];
      const duplicatedItem: WorkoutPlanDraggableItem = {
        ...itemToDuplicate,
        uuid: randomUUID(),
      };
      newLists[listIndex].workouts.splice(cardIndex, 0, duplicatedItem);
      state.planBuilder.workoutLists = newLists;
    },
    appendRestToListAtIndex: (
      state,
      action: PayloadAction<{ listIndex: number }>
    ) => {
      const newLists = [...state.planBuilder.workoutLists];
      const selectedList = newLists[action.payload.listIndex];
      selectedList.workouts.push({
        uuid: randomUUID(),
        workout: null,
      });
      state.planBuilder.workoutLists = newLists;
    },
    moveList: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>
    ) => {
      const newLists = [...state.planBuilder.workoutLists];
      const listToMove = newLists[action.payload.fromIndex];
      newLists.splice(action.payload.fromIndex, 1);
      newLists.splice(action.payload.toIndex, 0, listToMove);
      state.planBuilder.workoutLists = newLists;
    },
    moveItem: (
      state,
      action: PayloadAction<{
        from: { listId: string; itemIndex: number };
        to: { listId: string; itemIndex: number };
      }>
    ) => {
      const { from, to } = action.payload;
      const newLists = [...state.planBuilder.workoutLists];
      const fromList = newLists.filter((list) => list.id === from.listId)[0];
      const toList = newLists.filter((list) => list.id === to.listId)[0];
      if (toList.workouts.length >= MAX_ITEMS_IN_LIST) {
        throw new ListMaxLengthViolationError();
      }
      const draggedItem = fromList.workouts[from.itemIndex];
      fromList.workouts.splice(from.itemIndex, 1);
      toList.workouts.splice(to.itemIndex, 0, draggedItem);
      state.planBuilder.workoutLists = newLists;
    },
    reorderItemInList: (
      state,
      action: PayloadAction<{
        listId: string;
        fromIndex: number;
        toIndex: number;
      }>
    ) => {
      const { listId, fromIndex, toIndex } = action.payload;
      const newLists = [...state.planBuilder.workoutLists];
      const listToReorder = newLists.filter((list) => list.id === listId)[0];
      const item = listToReorder.workouts[fromIndex];
      listToReorder.workouts.splice(fromIndex, 1);
      listToReorder.workouts.splice(toIndex, 0, item);
      state.planBuilder.workoutLists = newLists;
    },
    createItemInListAtIndex: (
      state,
      action: PayloadAction<{
        listId: string;
        index: number;
        workout: Workout | null;
      }>
    ) => {
      const { listId, index, workout } = action.payload;
      const newLists = [...state.planBuilder.workoutLists];
      const listToAddTo = newLists.filter((list) => list.id === listId)[0];
      if (listToAddTo.workouts.length >= MAX_ITEMS_IN_LIST) {
        throw new ListMaxLengthViolationError();
      }
      listToAddTo.workouts.splice(index, 0, { uuid: randomUUID(), workout });
      state.planBuilder.workoutLists = newLists;
    },
    removeItemFromWeekIndexAtIndex: (
      state,
      action: PayloadAction<{ weekIndex: number; itemIndex: number }>
    ) => {
      const { weekIndex, itemIndex } = action.payload;
      state.planBuilder.workoutLists[weekIndex].workouts.splice(itemIndex, 1);
    },
    reset: () => defaultPlanBuilderState,
  },
});

export const PlanBuilderReducer = planBuilderSlice.reducer;
export const planBuilderActions = planBuilderSlice.actions;
