import { AxiosRequestConfig } from "axios";
import { useRequest } from "./useRequest";

export const useGet = <T>(path: string, config?: AxiosRequestConfig) =>
  useRequest<T>("GET", path, config);

export const usePost = <T, S>(
  path: string,
  body: S,
  config?: AxiosRequestConfig
) => useRequest<T>("POST", path, { data: body, ...config });

export const usePatch = <T, S>(
  path: string,
  body: S,
  config?: AxiosRequestConfig
) => useRequest<T>("PATCH", path, { data: body, ...config });
