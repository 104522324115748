import {
  Snackbar,
  Slide,
  Alert,
  SnackbarProps,
  AlertColor,
} from "@mui/material";
import React from "react";

interface Props extends Omit<SnackbarProps, "open" | "onClose"> {
  isOpen: boolean;
  close: () => void;
  severity: AlertColor;
  message: string;
}

export const SnackbarAlert: React.FC<Props> = ({
  isOpen,
  close,
  severity,
  message,
  ...snackbarProps
}) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={close}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Slide}
      {...snackbarProps}
    >
      <Alert onClose={close} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
