import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useWorkoutPlanBuilderNavigators = () => {
  const navigate = useNavigate();
  const navigateToWorkoutPlanNoEdit = useCallback(
    (workoutPlanId: string) => {
      navigate(`/workout-plans/${workoutPlanId}?no_edit=true`);
    },
    [navigate]
  );
  const navigateToWorkoutPlanDayEdit = useCallback(
    ({
      playlistId,
      playlistWorkoutDayId,
    }: {
      playlistId: string;
      playlistWorkoutDayId: string;
    }) => {
      const workoutPlanDayEditPath = `/workout-plans/edit/${playlistId}/day/${playlistWorkoutDayId}`;
      navigate(workoutPlanDayEditPath);
    },
    [navigate]
  );

  const navigateToPlaylistWorkoutDay = useCallback(
    (playlistWorkoutDayId: string) => {
      navigate(`day/${[playlistWorkoutDayId]}`);
    },
    [navigate]
  );

  const navigateToWorkoutPlan = (playlistId: string) => {
    const workoutPlanViewPath = `/workout-plans/${playlistId}`;
    navigate(workoutPlanViewPath);
  };

  return {
    navigateToWorkoutPlan,
    navigateToWorkoutPlanNoEdit,
    navigateToWorkoutPlanDayEdit,
    navigateToPlaylistWorkoutDay,
  };
};
