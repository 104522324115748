import { AxiosResponse } from "axios";
import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { PaginatedAPI } from "../types";
import { workoutPlanActions } from "./slice";
import {
  transformAllBaseWorkoutPlansFromAPI,
  transformWorkoutPlanFromAPI,
} from "./transformer";
import {
  BaseWorkoutPlanAPI,
  PatchableWorkoutPlan,
  WorkoutPlan,
  WorkoutPlanAPI,
} from "./types";

export const GetWorkoutPlans = async (
  dispatch: DispatchFn,
  pageNumber: number
) => {
  dispatch(workoutPlanActions.getWorkoutPlansAttempt());
  try {
    const response = await client.get<PaginatedAPI<BaseWorkoutPlanAPI>>(
      "/builder/workout-plan/",
      { params: { page: pageNumber } }
    );
    const workoutPlans = transformAllBaseWorkoutPlansFromAPI(
      response.data.results
    );
    dispatch(
      workoutPlanActions.setWorkoutPlansCount({ count: response.data.count })
    );
    dispatch(
      workoutPlanActions.getWorkoutPlansSuccess({ pageNumber, workoutPlans })
    );
  } catch (e) {
    dispatch(workoutPlanActions.getWorkoutPlansFailure());
  }
};

export const GetWorkoutPlan = async (
  dispatch: DispatchFn,
  workoutPlanId: string
) => {
  dispatch(workoutPlanActions.getWorkoutPlanAttempt());
  try {
    const response = await client.get<WorkoutPlanAPI>(
      `/builder/workout-plan/${workoutPlanId}/`
    );
    const workoutPlan = transformWorkoutPlanFromAPI(response.data);
    dispatch(workoutPlanActions.getWorkoutPlanSuccess({ workoutPlan }));
    return workoutPlan;
  } catch (e) {
    dispatch(workoutPlanActions.getWorkoutPlanFailure());
  }
};

export const PostWorkoutPlan = async (
  dispatch: DispatchFn,
  nameOnlyWorkoutPlan: Pick<WorkoutPlanAPI, "name">
) => {
  dispatch(workoutPlanActions.patchWorkoutPlanAttempt());
  try {
    const response = await client.post<
      WorkoutPlanAPI,
      AxiosResponse<WorkoutPlanAPI>,
      Pick<WorkoutPlanAPI, "name">
    >(`/builder/workout-plan/`, nameOnlyWorkoutPlan);
    const workoutPlan = transformWorkoutPlanFromAPI(response.data);
    dispatch(workoutPlanActions.patchWorkoutPlanSuccess({ workoutPlan }));
    dispatch(workoutPlanActions.clearWorkoutPlansData());
    return workoutPlan;
  } catch {
    dispatch(workoutPlanActions.patchWorkoutPlanFailure());
  }
};

export const PatchWorkoutPlan = async (
  dispatch: DispatchFn,
  playlistId: string,
  patchableWorkoutPlan: PatchableWorkoutPlan
) => {
  dispatch(workoutPlanActions.patchWorkoutPlanAttempt());
  try {
    const response = await client.patch<
      WorkoutPlanAPI,
      AxiosResponse<WorkoutPlanAPI>,
      PatchableWorkoutPlan
    >(`/builder/workout-plan/${playlistId}/`, patchableWorkoutPlan);
    const workoutPlan = transformWorkoutPlanFromAPI(response.data);
    dispatch(workoutPlanActions.patchWorkoutPlanSuccess({ workoutPlan }));
    return workoutPlan;
  } catch {
    dispatch(workoutPlanActions.patchWorkoutPlanFailure());
  }
};

export const PatchWorkoutPlanThumbnail = async (
  dispatch: DispatchFn,
  playlistId: string,
  image: File
) => {
  dispatch(workoutPlanActions.patchWorkoutPlanAttempt());
  try {
    const formData = new FormData();
    formData.append("thumbnail", image);
    const response = await client.patch<WorkoutPlanAPI>(
      `/builder/workout-plan/${playlistId}/`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    const workoutPlan = transformWorkoutPlanFromAPI(response.data);
    dispatch(workoutPlanActions.patchWorkoutPlanSuccess({ workoutPlan }));
    dispatch(
      workoutPlanActions.replaceWorkoutPlanInPaginatedWorkoutPlans({
        ...workoutPlan,
        updatedAt: new Date().toISOString(),
      })
    );
  } catch {
    dispatch(workoutPlanActions.patchWorkoutPlanFailure());
  }
};

export const DeleteWorkoutPlan = async (
  dispatch: DispatchFn,
  playlistId: string
) => {
  dispatch(workoutPlanActions.deleteWorkoutPlanAttempt());
  try {
    await client.delete<WorkoutPlanAPI, AxiosResponse<WorkoutPlanAPI>>(
      `/builder/workout-plan/${playlistId}/`
    );
    dispatch(workoutPlanActions.deleteWorkoutPlanSuccess());
    dispatch(workoutPlanActions.clearWorkoutPlansData());
  } catch {
    dispatch(workoutPlanActions.deleteWorkoutPlanFailure());
  }
};

export const DuplicateWorkoutPlan = async (
  dispatch: DispatchFn,
  playlistId: string
) => {
  dispatch(workoutPlanActions.duplicateWorkoutPlanAttempt());
  try {
    const playlist = await client.post<never, AxiosResponse<{ id: string }>>(
      `/builder/duplicate-workout-plan/${playlistId}`
    );
    dispatch(workoutPlanActions.duplicateWorkoutPlanSuccess());
    dispatch(workoutPlanActions.clearWorkoutPlansData());
    return playlist.data.id;
  } catch {
    dispatch(workoutPlanActions.duplicateWorkoutPlanFailure());
  }
};

export const ClearCurrentWorkoutPlan = (dispatch: DispatchFn) =>
  dispatch(workoutPlanActions.clearCurrentWorkoutPlan());

export const ClearWorkoutPlansData = (dispatch: DispatchFn) =>
  dispatch(workoutPlanActions.clearWorkoutPlansData());

export const SetCurrentWorkoutPlan = (
  dispatch: DispatchFn,
  workoutPlan: WorkoutPlan
) => dispatch(workoutPlanActions.setCurrentWorkoutPlan({ workoutPlan }));
