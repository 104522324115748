import React from "react";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { Dashboard } from "./Dashboard";
import { useDashboardPage } from "./useDashboardPage";

interface DashboardPageProps extends ReturnType<typeof useDashboardPage> {}

export const DashboardPage: React.FC<DashboardPageProps> = ({
  dashboardProps,
}) => {
  return (
    <PageTemplate>
      <PageHeader
        title="My Dashboard"
        subtitle="Analytics about your content and fans"
      />
      <Dashboard {...dashboardProps} />
    </PageTemplate>
  );
};
