export const getSentences = (text: string): string[] => {
  const sentences = text.match(/[^.!?]+[.!?]+/g) || [];
  const lastSentence = text.replace(sentences.join(""), "").trim();
  const trimmedSentences = sentences.map((sentence) => sentence.trim());
  const nonEmptySentences = trimmedSentences.filter(
    (sentence) => sentence.length > 0
  );

  if (lastSentence.length > 0) {
    nonEmptySentences.push(lastSentence);
  }

  return nonEmptySentences;
};
