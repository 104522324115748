import { useTheme } from "@mui/material";
import { ResponsiveLine, Serie } from "@nivo/line";
import React, { useMemo } from "react";
import { pluralize } from "../../../../util/string/pluralize";
import { ChartTooltip } from "../../../common/Charts/ChartTooltip";
import {
  formatDateAxisValuesForSignupLineChart,
  formatDateTooltipForSignupLineChart,
} from "../utils";

interface SignupLineChartProps {
  data: Serie[];
}

const MAX_X_AXIS_TICKS = 7;
const MAX_Y_VALUE = 5;

export const SignupLineChart: React.FC<SignupLineChartProps> = ({ data }) => {
  const theme = useTheme();
  const maximumSignupCount = useMemo(
    () =>
      data[0].data.reduce((acc, curr) => {
        return Math.max(acc, curr.y as number);
      }, 0),
    [data]
  );

  return (
    <>
      <ResponsiveLine
        data={data}
        margin={{ top: 10, right: 50, bottom: 50, left: 60 }}
        xScale={{ type: "time", useUTC: false }}
        yScale={{
          type: "linear",
          max: Math.max(MAX_Y_VALUE, maximumSignupCount),
        }}
        defs={[
          {
            id: "gradient",
            type: "linearGradient",
            colors: [
              { offset: 0, color: "inherit" },
              { offset: 100, color: "inherit", opacity: 0 },
            ],
          },
        ]}
        fill={[{ match: "*", id: "gradient" }]}
        curve="monotoneX"
        colors={Object.values(theme.palette.primary)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: formatDateAxisValuesForSignupLineChart,
          tickValues: Math.min(data[0].data.length, MAX_X_AXIS_TICKS),
        }}
        tooltip={({ point }) => {
          return (
            <ChartTooltip
              xValue={`${point.data.yFormatted} ${pluralize(
                "signup",
                point.data.y as number
              )}`}
              yValue={formatDateTooltipForSignupLineChart(
                new Date(point.data.x)
              )}
            />
          );
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 20,
          tickRotation: 0,
          tickValues: 4,
          legend: "New signups per day",
          legendOffset: -50,
          legendPosition: "middle",
        }}
        enableGridX={false}
        enableGridY={false}
        enableArea={true}
        pointSize={8}
        pointColor={theme.palette.primary.main}
        isInteractive={true}
        enableCrosshair={false}
        useMesh={true}
      />
    </>
  );
};
