import { Box, DialogActions } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {
  DialogPrimaryButton,
  DialogSecondaryButton,
} from "../../../../common/Dialog/DialogButtons";
import { ImageInput } from "../../../../common/ImageInput";
import { WorkoutPlanCheckComponentProps } from "./types";

export const CheckWorkoutPlanArtwork: React.FC<
  WorkoutPlanCheckComponentProps<File>
> = ({
  workoutPlan,
  isLoading,
  updateNewValue,
  onClickPrimary,
  onClickSecondary,
  isError,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleChangeFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };
  const imageSrc =
    (selectedFile && URL.createObjectURL(selectedFile)) ??
    workoutPlan.thumbnail ??
    null;
  const hasValueChanged = selectedFile !== null;

  return (
    <>
      <Box display="flex" width="50%">
        <ImageInput
          imageSrc={imageSrc}
          onChangeFile={handleChangeFile}
          isLoading={isLoading}
          isError={!imageSrc}
        />
      </Box>
      {!imageSrc && (
        <Typography variant="body2" color="error.main">
          Please upload a plan cover photo
        </Typography>
      )}
      <DialogActions sx={{ width: "100%" }}>
        <DialogSecondaryButton onClick={onClickSecondary}>
          {"Previous"}
        </DialogSecondaryButton>
        <DialogPrimaryButton
          variant="outlined"
          disabled={isLoading || !imageSrc || isError}
          onClick={() => {
            if (hasValueChanged) {
              selectedFile && updateNewValue(selectedFile).then(onClickPrimary);
            } else {
              onClickPrimary();
            }
          }}
        >
          {hasValueChanged ? (isLoading ? "Saving..." : "Save & Next") : "Next"}
        </DialogPrimaryButton>
      </DialogActions>
    </>
  );
};
