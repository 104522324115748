export interface APICallState<E = boolean> {
  isLoading: boolean;
  isError: E;
}
export interface Resource<T, K = boolean> extends APICallState<K> {
  data: T | null;
}

export const createDefaultResource = <T>(defaultData?: T): Resource<T> => {
  return {
    data: defaultData ?? null,
    isLoading: false,
    isError: false,
  };
};

export interface PaginatedAPI<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface Pages<T> {
  [pageNumber: number]: T[];
}
export interface PaginatedResource<T> extends Resource<Pages<T>> {
  count: number | null;
}

export const successLoadingErrorState: APICallState = {
  isLoading: false,
  isError: false,
};

export const failureLoadingErrorState: APICallState = {
  isLoading: false,
  isError: true,
};

export const attemptLoadingErrorState: APICallState = {
  isLoading: true,
  isError: false,
};

export const createDefaultPaginatedResource = <T>(
  defaultPaginatedData?: Pages<T>
): PaginatedResource<T> => {
  return {
    ...createDefaultResource(defaultPaginatedData),
    count: null,
  };
};
