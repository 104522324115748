import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { AppleAppStoreBadge } from "../../common/StoreBadges/AppleAppStoreBadge";
import { GooglePlayStoreBadge } from "../../common/StoreBadges/GooglePlayStoreBadge";
import { useAppStoreShowcase } from "./useAppStoreShowcase";

interface Props extends ReturnType<typeof useAppStoreShowcase> {}

export const AppStoreShowcase: React.FC<Props> = ({ firstName }) => {
  return (
    <>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 8,
          }}
        >
          <Box display="flex" flexDirection={"column"}>
            <Box>
              <Typography component="h1" variant="h3">
                {"Hey"}
                {firstName && (
                  <Typography
                    component="h1"
                    variant="h3"
                    display={"inline"}
                    color="primary.main"
                  >
                    {` ${firstName}`}
                  </Typography>
                )}
                , welcome to MyARC!
              </Typography>
              <Typography component={"h2"} variant="h5" sx={{ my: 2 }}>
                Get the best of fitness, all in one place. Download the app
                today!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <AppleAppStoreBadge />
                <GooglePlayStoreBadge />
              </Box>
            </Box>
          </Box>
          <Box
            component="div"
            sx={{ display: "flex", maxWidth: { xs: "100%", sm: "30%" } }}
            maxWidth="md"
          >
            <img src="/iphone-mockup.png" width={"100%"} />
          </Box>
        </Box>
      </Container>
    </>
  );
};
