import React from "react";
import { WorkoutPlanDescriptionInlineInput } from "./WorkoutPlanDescriptionInlineInput";
import { WorkoutPlanNameInlineInput } from "./WorkoutPlanNameInlineInput";
import { useWorkoutPlanEdit } from "./useWorkoutPlanEdit";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";

interface EditWorkoutPlanProps {
  workoutPlan?: BaseWorkoutPlan;
}

export const EditWorkoutPlanName: React.FC<EditWorkoutPlanProps> = (props) => {
  const { name, isLoading, updateWorkoutPlanName } = useWorkoutPlanEdit(props);
  return (
    <WorkoutPlanNameInlineInput
      name={name}
      isLoading={isLoading}
      updateWorkoutPlanName={updateWorkoutPlanName}
    />
  );
};

export const EditWorkoutPlanDescription: React.FC<EditWorkoutPlanProps> = (
  props
) => {
  const { description, isLoading, updateWorkoutPlanDescription } =
    useWorkoutPlanEdit(props);
  return (
    <WorkoutPlanDescriptionInlineInput
      description={description}
      isLoading={isLoading}
      updateWorkoutPlanDescription={updateWorkoutPlanDescription}
    />
  );
};
