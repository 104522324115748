import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { ImageInputProps } from ".";
import { NoWorkoutPlanArtwork } from "../../pages/MyWorkoutPlans/PlaylistCard/NoWorkoutPlanArtwork";
import { ImageWithSkeleton } from "../Image/ImageWithSkeleton";

interface ComponentProps extends ImageInputProps {}

export const ImageInput: React.FC<ComponentProps> = ({
  imageSrc,
  onChangeFile,
  isLoading = false,
  isError = false,
  containerStyle,
}) => {
  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        sx={containerStyle}
      >
        <Box
          display="flex"
          width={"100%"}
          position="relative"
          bgcolor={isError ? "error.main" : "neutral"}
          borderRadius={1}
        >
          <Button component="label" fullWidth sx={{ p: 0.5 }}>
            {imageSrc ? (
              <ImageWithSkeleton
                alt={`Uploaded workout plan cover`}
                src={imageSrc}
                style={{
                  aspectRatio: 1,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: 1,
                }}
              />
            ) : (
              <NoWorkoutPlanArtwork borderRadius={1} />
            )}
            <input
              type="file"
              hidden
              onChange={onChangeFile}
              accept="image/*"
            />

            <Box position="absolute" bottom={0} width={"100%"}>
              {isLoading && <LinearProgress color="neutral" />}
              <Box
                sx={({ palette }) => ({
                  background: `linear-gradient(0deg, ${palette.grey[800]} 0%, rgba(255,255,255,0) 80%)`,
                })}
                display="flex"
                justifyContent="center"
                width="100%"
                height="100%"
                py={2}
                borderRadius={1}
              >
                <Typography color="primary.contrastText">
                  {imageSrc ? "Replace image" : "Select image"}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </Box>
    </>
  );
};
