import { GridPreProcessEditCellProps } from "@mui/x-data-grid";
import { ParameterNameToType, convertMMSSToSeconds } from "./EditCells/config";

interface Rule<T> {
  validator: (x: T) => boolean;
  errorMessage: string;
}

const moreThan = (limit: number) => (test: number) => test > limit;

const lessThan = (limit: number) => (test: number) => test < limit;

const moreThanZero = moreThan(0);

const isInteger = Number.isInteger;

const isNonEmptyString = (x: string) => x.length > 0;

const timeStringMoreThan = (limit: number) => (test: string) =>
  convertMMSSToSeconds(test) > limit;

const timeStringLessThan = (limit: number) => (test: string) =>
  convertMMSSToSeconds(test) < limit;

const REPS_RULES: Rule<number>[] = [
  {
    validator: moreThanZero,
    errorMessage: "Reps value needs to be more than 0",
  },
  {
    validator: lessThan(100),
    errorMessage: "Reps value needs to be less than 100",
  },
  {
    validator: isInteger,
    errorMessage: "Reps value needs to be a whole number",
  },
];

const TIME_RULES: Rule<string>[] = [
  {
    validator: isNonEmptyString,
    errorMessage: "Time (mm:ss) cannot be empty",
  },
  {
    validator: timeStringMoreThan(0),
    errorMessage: "Time (mm:ss) value needs to be more than 0",
  },
  {
    validator: timeStringLessThan(3600),
    errorMessage: "Time (mm:ss) value needs to be less than an hour",
  },
];

const REST_RULES: Rule<string>[] = [
  {
    validator: isNonEmptyString,
    errorMessage: "Rest (mm:ss) cannot be empty",
  },
  {
    validator: timeStringMoreThan(0),
    errorMessage: "Rest (mm:ss) value needs to be more than 0",
  },
  {
    validator: timeStringLessThan(3600),
    errorMessage: "Rest (mm:ss) value needs to be less than an hour",
  },
];

export const validateRepsValue = (value: number) => {
  const result = validateRules(value, REPS_RULES);
  return result;
};

export const validateTimeValue = (value: string) => {
  const result = validateRules(value, TIME_RULES);
  return result;
};
export const validateRestValue = (value: string) => {
  const result = validateRules(value, REST_RULES);
  return result;
};

const validateRules = <T>(value: T, rules: Rule<T>[]) => {
  const result = rules
    .filter((x) => !x.validator(value))
    .map((x) => x.errorMessage);
  return result.length ? result : undefined;
};

const validatorsByParameter: {
  [K in keyof ParameterNameToType]: (
    value: ParameterNameToType[K]
  ) => ReturnType<typeof validateRules>;
} = {
  Reps: validateRepsValue,
  Time: validateTimeValue,
  Rest: validateRestValue,
  Weight: () => undefined,
};

const getValidatorByParameterName = <K extends keyof ParameterNameToType>(
  parameterName: K
) => validatorsByParameter[parameterName] || (() => undefined);

export const getPreProcesserForParameter =
  <K extends keyof ParameterNameToType>(parameterName: K) =>
  (params: GridPreProcessEditCellProps) => {
    const value = params.props.value as ParameterNameToType[K];
    const errorMessage = getValidatorByParameterName(parameterName)(value);
    return { ...params.props, error: errorMessage };
  };
