import React from "react";
import { Box, Skeleton } from "@mui/material";
import { PageHeaderSkeleton } from "../../common/PageHeader/PageHeaderSkeleton";
import { PageTemplate } from "../../common/PageTemplate";

export const WorkoutPlanBuilderSkeleton: React.FC = () => {
  const NUM_WEEKS = 3;
  return (
    <PageTemplate
      sx={{
        display: "flex",
        height: "95vh",
        flexDirection: "column",
      }}
    >
      <PageHeaderSkeleton />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          pt: 3,
          gap: 3,
        }}
      >
        <Skeleton variant="rounded" height={"100%"} width={"30%"} />
        {[...new Array(NUM_WEEKS)].map((item, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            height={"100%"}
            width={"25%"}
          />
        ))}
      </Box>
    </PageTemplate>
  );
};
