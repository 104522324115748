import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useRequest } from "../../../hooks/request/useRequest";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useStore } from "../../../store";
import {
  GetExpressAccount,
  GetPayoutInformation,
} from "../../../store/payouts/actions";
import {
  selectExpressAccountResource,
  selectPayoutInformationResource,
} from "../../../store/payouts/selectors";
import { BalanceAvailability } from "../../../store/payouts/types";
import { relativeDate } from "../../../util/date";
import { formatPayoutDate, getNext15thDate } from "./components/utils";

const DEFAULT_BALANCE: BalanceAvailability = {
  amount: 0,
  currency: "usd",
};

export const useWallet = () => {
  const [select, dispatch] = useStore();
  const expressAccountResource = select(selectExpressAccountResource);
  const { refresh: shouldRefreshString } = useParams();

  const shouldRefresh = shouldRefreshString?.toLowerCase() === "true";

  const [GetOnboardingLink, onboardingLinkResource] = useRequest<{
    account_link: string;
  }>("GET", "/payments/account-link/onboarding");

  const getOnboardingLink = async () => {
    if (!expressAccountResource.data?.detailsSubmitted) {
      const response = await GetOnboardingLink();
      if (response) {
        window.location.href = response.account_link;
      }
    }
  };

  const getExpressAccount = useCallback(() => {
    GetExpressAccount(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getExpressAccount();
  }, [getExpressAccount]);

  useEffect(() => {
    shouldRefresh && getExpressAccount();
  }, [getExpressAccount, shouldRefresh]);

  const payoutInformationResource = select(selectPayoutInformationResource);

  const getPayoutInformation = useCallback(() => {
    GetPayoutInformation(dispatch);
  }, [dispatch]);

  const [isErrorMessageOpen, closeErrorMessage] = useErrorMessageState(
    payoutInformationResource.isError
  );

  const payDate = useMemo(getNext15thDate, []);
  const daysUntilPayday = useMemo(() => relativeDate(payDate), [payDate]);

  const payoutsInTransit = useMemo(
    () =>
      payoutInformationResource.data?.payouts.filter(
        (item) => item.status === "in_transit"
      ) || [],
    [payoutInformationResource.data?.payouts]
  );

  const {
    availableBalance,
    pendingBalance,
  }: {
    availableBalance: BalanceAvailability;
    pendingBalance: BalanceAvailability;
  } = useMemo(() => {
    const availableBalanceAPI =
      payoutInformationResource.data?.balance.available[0];
    const pendingBalanceAPI =
      payoutInformationResource.data?.balance.pending[0];

    if (!availableBalanceAPI || !pendingBalanceAPI) {
      return {
        availableBalance: DEFAULT_BALANCE,
        pendingBalance: DEFAULT_BALANCE,
      };
    }

    const totalPayoutAmount = payoutsInTransit
      .filter(
        (item) =>
          item.currency === availableBalanceAPI.currency &&
          item.currency === pendingBalanceAPI.currency
      )
      .reduce((acc, cur) => acc + cur.amount, 0);

    return {
      availableBalance: {
        ...availableBalanceAPI,
        amount: Math.max(availableBalanceAPI.amount - totalPayoutAmount, 0),
      },
      pendingBalance: {
        ...pendingBalanceAPI,
        amount: payoutsInTransit.length
          ? pendingBalanceAPI.amount + availableBalanceAPI.amount
          : pendingBalanceAPI.amount,
      },
    };
  }, [
    payoutInformationResource.data?.balance.available,
    payoutInformationResource.data?.balance.pending,
    payoutsInTransit,
  ]);

  useEffect(() => {
    !payoutInformationResource.data && getPayoutInformation();
  }, [getPayoutInformation, payoutInformationResource.data]);

  const formattedPayouts = useMemo(
    () =>
      payoutInformationResource.data?.payouts.map((payout) => ({
        ...payout,
        arrivalDate: formatPayoutDate(payout.arrivalDate),
      })),
    [payoutInformationResource.data?.payouts]
  );

  return {
    // payout onboarding
    expressAccount: expressAccountResource.data,
    getOnboardingLink,
    isFetchingOnboardingLink: onboardingLinkResource.isLoading,
    getExpressAccount,
    // wallet
    availableBalance,
    pendingBalance,
    payouts: formattedPayouts,
    isLoading: payoutInformationResource.isLoading,
    isErrorMessageOpen,
    closeErrorMessage,
    daysUntilPayday,
    payDate,
  };
};
