import React from "react";
import { PageHeaderSkeleton } from "../../common/PageHeader/PageHeaderSkeleton";
import { PageTemplate } from "../../common/PageTemplate";
import { TableSkeleton } from "../../common/Table/TableSkeleton";

export const WorkoutPlanLoading: React.FC = () => {
  return (
    <PageTemplate>
      <PageHeaderSkeleton />
      <TableSkeleton columns={["20%", "80%"]} rows={15} />
    </PageTemplate>
  );
};
