import { useDebouncedAction } from "./useDebouncedAction";

export const useAutosave = (
  saveFunction: () => Promise<void>,
  timeoutInMs: number,
  hasValueChanged: boolean
) => {
  const saveIfShouldSave = () => {
    if (hasValueChanged) {
      saveFunction();
    }
  };
  useDebouncedAction(saveIfShouldSave, timeoutInMs, [hasValueChanged]);
  return;
};
