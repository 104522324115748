import React from "react";
import { WorkoutSection } from "../../../store/workout/types";
import { Box, Stack, Typography } from "@mui/material";
import { Block } from "./Block";

interface Props {
  section: WorkoutSection;
}

export const Section: React.FC<Props> = ({ section }) => {
  return (
    <Box>
      <Stack gap={1}>
        {section.blocks.map((block) => (
          <Block block={block} key={block.id} />
        ))}
        {!section.blocks.length && (
          <Typography variant="body1">No Blocks</Typography>
        )}
      </Stack>
    </Box>
  );
};
