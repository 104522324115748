import { useState } from "react";
import { ConfirmDialogProps } from "./types";
import { ButtonProps } from "@mui/material";

export enum DialogName {
  SAVE_PLAN = "save-plan",
  PUBLISH_PLAN = "publish-plan",
  UNPUBLISH_PLAN = "unpublish-plan",
  DELETE_PLAN = "delete-plan",
  SAVE_WORKOUT = "save-workout",
}

export const DIALOG_TITLE: Record<DialogName, string> = {
  [DialogName.SAVE_PLAN]: "Save your progress",
  [DialogName.PUBLISH_PLAN]: "You're about to go live on MyARC!",
  [DialogName.UNPUBLISH_PLAN]: "You're about to remove this plan",
  [DialogName.DELETE_PLAN]: "You're about to delete this plan!",
  [DialogName.SAVE_WORKOUT]: "Save your workout",
};
export const DIALOG_SUBTITLE: Record<DialogName, string> = {
  [DialogName.SAVE_PLAN]:
    "All your missing slots will be replaced with rest days.",
  [DialogName.PUBLISH_PLAN]:
    "Once you publish this workout plan, you will not be able to change its name, description or contents.",
  [DialogName.UNPUBLISH_PLAN]:
    "This means fans won't be able to schedule this plan from now. Fans who are halfway through this plan will still have their schedules set.",
  [DialogName.DELETE_PLAN]:
    "You will lose all workouts in this plan that have not been favourited.",
  [DialogName.SAVE_WORKOUT]: "Make sure you've checked all your programming!",
};
export const DIALOG_SUBTITLE2: Record<DialogName, string> = {
  [DialogName.SAVE_PLAN]: "",
  [DialogName.PUBLISH_PLAN]:
    "You will be able to archive it and create a duplicate if you want.",
  [DialogName.UNPUBLISH_PLAN]: "Remember, you can always re-publish this plan.",
  [DialogName.DELETE_PLAN]: "",
  [DialogName.SAVE_WORKOUT]: "",
};

export const DIALOG_CONFIRM_TEXT: Record<DialogName, string> = {
  [DialogName.SAVE_PLAN]: "Sounds good",
  [DialogName.PUBLISH_PLAN]: "Publish and go live",
  [DialogName.UNPUBLISH_PLAN]: "Remove plan",
  [DialogName.DELETE_PLAN]: "Delete plan",
  [DialogName.SAVE_WORKOUT]: "Save",
};

export const DIALOG_CONFIRM_BUTTON_PROPS: Record<DialogName, ButtonProps> = {
  [DialogName.SAVE_PLAN]: { color: "success" },
  [DialogName.PUBLISH_PLAN]: { color: "primary" },
  [DialogName.UNPUBLISH_PLAN]: { color: "warning" },
  [DialogName.DELETE_PLAN]: { color: "error" },
  [DialogName.SAVE_WORKOUT]: { color: "success" },
};

export const useDialog = (initialVisibility = false) => {
  const [isVisible, setIsVisible] = useState(initialVisibility);
  const openDialog = () => setIsVisible(true);
  const closeDialog = () => setIsVisible(false);
  return [isVisible, openDialog, closeDialog] as const;
};

export interface ConfirmDialogConfig {
  openDialog: () => void;
  props: ConfirmDialogProps;
}

export const useConfirmDialog = (
  key: DialogName,
  handleSubmit?: () => Promise<void>,
  customProps?: Partial<ConfirmDialogProps>,
  initialVisibility = false
): ConfirmDialogConfig => {
  const [isVisible, openDialog, closeDialog] = useDialog(initialVisibility);
  return {
    openDialog,
    props: {
      title: DIALOG_TITLE[key],
      subtitle: DIALOG_SUBTITLE[key],
      subtitle2: DIALOG_SUBTITLE2[key],
      isOpen: isVisible,
      onClose: closeDialog,
      onClickConfirm: () => {
        handleSubmit?.().finally(() => closeDialog());
      },
      onClickCancel: closeDialog,
      confirmText: DIALOG_CONFIRM_TEXT[key],
      confirmButtonProps: DIALOG_CONFIRM_BUTTON_PROPS[key],
      ...customProps,
    },
  };
};
