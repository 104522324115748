import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useQuery } from "../../../hooks/useQuery";
import { useStore } from "../../../store";
import {
  clearChallengePlanTransformations,
  GetChallengePlans,
  GetChallengePlanTransformations,
} from "../../../store/transformations/actions";
import {
  selectChallengePlans,
  selectChallengePlanTransformationsPaginatedResource,
} from "../../../store/transformations/selector";
import { ChallengePlan } from "../../../store/transformations/types";
import { mapTransformationToCardData } from "./utils";

const CHALLENGE_PLAN_TRANSFORMATIONS_PER_PAGE = 12;

export const useTransformations = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [select, dispatch] = useStore();
  const currentPage = parseInt(query.get("page") ?? "1", 10);

  const [completedTransformationsFilter, setCompletedTransformationsFilter] =
    useState<boolean>(false);

  const [hasGivenConsentFilter, setHasGivenConsentFilter] =
    useState<boolean>(false);

  const [selectedChallengePlans, setSelectedChallengePlans] = useState<
    ChallengePlan[]
  >([]);

  const challengePlans = select(selectChallengePlans);
  const paginatedChallengePlanTransformationsResource = select(
    selectChallengePlanTransformationsPaginatedResource
  );

  useEffect(() => {
    const fetchChallengePlans = async () => {
      await GetChallengePlans(dispatch);
    };
    fetchChallengePlans();
  }, [dispatch]);

  const totalChallengePlanTransformations =
    paginatedChallengePlanTransformationsResource.count || 0;

  const totalPages = Math.ceil(
    totalChallengePlanTransformations / CHALLENGE_PLAN_TRANSFORMATIONS_PER_PAGE
  );

  const { isError, isLoading } = paginatedChallengePlanTransformationsResource;

  const [isShowingErrorMessage, hideErrorMessage] =
    useErrorMessageState(isError);

  const doesPageExist = useCallback(
    (pageNumber: number) => {
      return !!paginatedChallengePlanTransformationsResource.data?.[pageNumber];
    },
    [paginatedChallengePlanTransformationsResource.data]
  );

  useEffect(() => {
    if (selectedChallengePlans.length === 0) {
      setHasGivenConsentFilter(false);
      setCompletedTransformationsFilter(false);
    }
  }, [dispatch, navigate, selectedChallengePlans]);

  useEffect(() => {
    navigate("/transformations");
    clearChallengePlanTransformations(dispatch);
  }, [
    dispatch,
    hasGivenConsentFilter,
    completedTransformationsFilter,
    selectedChallengePlans,
    navigate,
  ]);

  useEffect(() => {
    const playlists = selectedChallengePlans.map(
      (challengePlan) => challengePlan.id
    );

    if (playlists.length === 0) {
      return;
    }

    if (!doesPageExist(currentPage)) {
      GetChallengePlanTransformations(
        dispatch,
        currentPage,
        playlists.join(","),
        completedTransformationsFilter,
        hasGivenConsentFilter
      );
    }
  }, [
    completedTransformationsFilter,
    currentPage,
    dispatch,
    doesPageExist,
    hasGivenConsentFilter,
    selectedChallengePlans,
  ]);

  const onPressCompletedTransformationsFilter = useCallback(() => {
    setCompletedTransformationsFilter(!completedTransformationsFilter);
  }, [completedTransformationsFilter]);

  const onPressHasGivenConsentFilter = useCallback(() => {
    setHasGivenConsentFilter(!hasGivenConsentFilter);
  }, [hasGivenConsentFilter]);

  const tranformationCardsData = useMemo(() => {
    const transformations =
      paginatedChallengePlanTransformationsResource.data?.[currentPage] || [];
    return transformations.map(mapTransformationToCardData);
  }, [currentPage, paginatedChallengePlanTransformationsResource.data]);

  return {
    challengePlans: challengePlans.data,
    selectedChallengePlans,
    setSelectedChallengePlans,
    completedTransformationsFilter,
    hasGivenConsentFilter,
    onPressCompletedTransformationsFilter,
    onPressHasGivenConsentFilter,
    isLoading,
    isShowingErrorMessage,
    hideErrorMessage,
    currentPage,
    totalPages,
    tranformationCardsData,
    totalChallengePlanTransformations,
  };
};
