import { SxProps } from "@mui/material";
import React from "react";
import { ImageInput as Component } from "./ImageInput";

export interface ImageInputProps {
  imageSrc: string | null;
  onChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  isError?: boolean;
  containerStyle?: SxProps;
}

export const ImageInput: React.FC<ImageInputProps> = (props) => {
  return <Component {...props} />;
};
