import React, { useMemo } from "react";
import { ExerciseEntry, ExerciseParameter } from "../../../store/workout/types";

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableBodyClasses,
  tableCellClasses,
  tableRowClasses,
  typographyClasses,
} from "@mui/material";
import styled from "styled-components";
import { filterUniqueBy } from "../../../util/array";
import { sortExerciseParameters } from "../../pages/WorkoutBuilder/ExerciseEntryDataGrid/utils";
import { getUnitLabelByParameterName } from "../../pages/WorkoutBuilder/ExerciseEntryDataGrid/EditCells/config";
import { Parameter } from "./Parameter";

interface Props {
  exerciseEntry: ExerciseEntry;
}

export const Table = styled(MuiTable)(({ theme }) => ({
  [`& .${tableCellClasses.head}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${tableCellClasses.body}`]: {
    borderBottom: "1px solid #EAECF0",
  },
  [`.${tableBodyClasses.root} .${tableRowClasses.root}:nth-child(odd)`]: {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
  [`.${typographyClasses.body1}`]: {
    fontSize: "unset",
  },
}));

export const ExerciseEntryTable: React.FC<Props> = ({ exerciseEntry }) => {
  const parameters = useMemo(() => {
    const params = exerciseEntry.sets.reduce(
      (acc: ExerciseParameter[], current) => {
        current.values
          .map((value) => value.parameter)
          .forEach((param) => acc.push(param));

        return acc;
      },
      []
    );
    return sortExerciseParameters(
      params.filter(filterUniqueBy((a, b) => a.name === b.name))
    );
  }, [exerciseEntry.sets]);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableCell>
            <Typography>Set</Typography>
          </TableCell>
          {parameters.map((parameter) => (
            <TableCell key={parameter.id}>
              <Typography>{`${parameter.name}${
                parameter.unit.length
                  ? ` (${getUnitLabelByParameterName(parameter.name)})`
                  : ""
              }`}</Typography>
            </TableCell>
          ))}
        </TableHead>
        <TableBody>
          {exerciseEntry.sets.map((set, index) => {
            return (
              <TableRow key={set.id}>
                <TableCell>
                  <Typography>{index + 1}</Typography>
                </TableCell>
                {parameters.map((parameter) => {
                  const parameterValue = set.values.find(
                    (val) => val.parameter.name === parameter.name
                  )?.value;
                  if (parameterValue) {
                    return (
                      <Parameter
                        key={parameter.id}
                        parameterValue={parameterValue}
                        parameter={parameter}
                      />
                    );
                  }
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
