import {
  DashboardCustomizeRounded,
  Favorite,
  Insights,
  VideoLibrary,
  Wallet,
  PhotoFilter,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { Box, Button, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearAuthenticationHeadersAndCookies } from "../../../../util/http/jwt";
import { MenuList, MenuListItem } from "../MenuList";
import { Drawer } from "./SideNavigation.styles";
import { SideNavigationHeader } from "./SideNavigationHeader";
import { useSideNavigation } from "./useSideNavigation";
import styled from "styled-components";

const menuListItems: MenuListItem[] = [
  {
    title: "My Workout Plans",
    icon: DashboardCustomizeRounded,
    path: "/workout-plans",
    isActive: true,
  },
  {
    title: "Transformations",
    icon: PhotoFilter,
    path: "/transformations",
    isActive: true,
  },
  {
    title: "My Workouts",
    icon: Favorite,
    path: "/favourites/workout",
    isActive: false,
  },
  {
    title: "Exercise Showroom",
    icon: VideoLibrary,
    path: "/exercises",
    isActive: true,
  },
  {
    title: "Dashboard",
    icon: Insights,
    path: "/dashboard",
    isActive: true,
  },
  {
    title: "Wallet",
    icon: Wallet,
    path: "/wallet",
    isActive: true,
  },
];

const UserProfileRing = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 100px;
  border: 2px solid;
  height: 40px;
  width: 40px;
  padding: 5px;
`;

export interface SideNavigationProps
  extends ReturnType<typeof useSideNavigation> {}

export const SideNavigation: React.FC<SideNavigationProps> = ({
  firstName,
  lastName,
  resetGlobalState,
}) => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const logout = () => {
    resetGlobalState();
    clearAuthenticationHeadersAndCookies();
    navigate("/login");
  };

  const activeMenuListItems = useMemo(() => {
    return menuListItems.filter((item) => item.isActive);
  }, []);

  return (
    <>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            pb: open ? 0 : 2,
            borderTopRightRadius: ({ spacing }) => spacing(1),
            borderBottomRightRadius: ({ spacing }) => spacing(1),
          },
        }}
      >
        <Box>
          <SideNavigationHeader
            isOpen={open}
            onClickHeaderIcon={open ? closeDrawer : openDrawer}
            firstName={firstName}
            lastName={lastName}
          />
          <MenuList isOpen={open} menuListItems={activeMenuListItems} />
        </Box>
        {open ? (
          <Button variant="contained" onClick={logout} endIcon={<LogoutIcon />}>
            Sign Out
          </Button>
        ) : (
          <UserProfileRing>
            <Typography variant="body1">{`${firstName?.[0]}${lastName?.[0]}`}</Typography>
          </UserProfileRing>
        )}
      </Drawer>
    </>
  );
};
