import { Favorite } from "@mui/icons-material";
import React from "react";
import { EmptyList } from "../../../common/EmptyList";

interface Props {
  onClickCreateFavourite: () => void;
}

export const EmptyFavourites: React.FC<Props> = ({
  onClickCreateFavourite: onClickCreateWorkout,
}) => {
  return (
    <EmptyList
      onClickButton={onClickCreateWorkout}
      primaryText={"No favourites"}
      secondaryText={
        "Time to flex those creative muscles and build a workout that will leave your fans begging for more."
      }
      buttonText={"Create Favourite"}
      icon={<Favorite />}
    />
  );
};
