import { Serie } from "@nivo/line";
import { capitalCase } from "change-case";
import { theme } from "../../../theme/theme";
import { formatToShortDate } from "../../../util/date";
import { PieChartDatum } from "../../common/Charts/types";
import {
  UserSignup,
  UserSignupAPI,
  SubscriptionStatus,
  SubscriptionStatusBreakdown,
  SubscriptionStatusBreakdownAPI,
  SignupCountOnDate,
} from "./types";
import { DateTime } from "luxon";

export const transformUserSignupFromAPI = (api: UserSignupAPI): UserSignup => ({
  id: api.id,
  dateJoinedUTCString: api.date_joined,
});

export const groupUserSignupsByDate = (rawData: UserSignup[]) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const countsByDate: Record<string, number> = {};

  rawData.forEach((obj) => {
    const joinedDateTime = DateTime.fromISO(obj.dateJoinedUTCString, {
      zone: "utc",
    });

    // Convert the UTC date to the local timezone
    const localDateTime = joinedDateTime.setZone(localTimeZone);

    // Extract the date part in the format YYYY-MM-DD
    const dateKey = localDateTime.toISODate() as string;

    // Increment the count for the corresponding date
    countsByDate[dateKey] = (countsByDate[dateKey] || 0) + 1;
  });
  return countsByDate;
};

interface AddMissingDaysToSignupCountDataArgs {
  countsByDate: Record<string, number>;
  numberOfDays: number;
}

export const addMissingDaysToSignupCountData = ({
  countsByDate,
  numberOfDays,
}: AddMissingDaysToSignupCountDataArgs): SignupCountOnDate[] => {
  // Get the date for numberOfDays days ago
  const startDate = DateTime.local()
    .startOf("day")
    .minus({ days: numberOfDays - 1 });
  // Generate an array of date strings for the specified number of days
  const dateRange = Array.from({ length: numberOfDays }, (_, index) =>
    startDate.plus({ days: index })
  );
  return dateRange.map((date) => ({
    // Return TZ information so we can convert to JS Date() properly
    // Return start of day as we want signup chart tick values to be at midnight
    signupDateString: date.toISO() as string,
    count: countsByDate[date.toISODate() as string] || 0,
  }));
};

export const convertSignupCountAPIToLineSerie = (
  rawData: SignupCountOnDate[]
): Serie => {
  return {
    id: "signup-count",
    data: rawData.map((item) => ({
      x: new Date(item.signupDateString),
      y: item.count,
    })),
  };
};

export const formatDateAxisValuesForSignupLineChart = (date: Date) => {
  return formatToShortDate(date);
};
export const formatDateTooltipForSignupLineChart = (date: Date) => {
  return formatToShortDate(date);
};

const SUBSCRIPTION_STATUS_COLORS: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.ACTIVE]: theme.palette.success.main,
  [SubscriptionStatus.TRIALING]: theme.palette.info.main,
  [SubscriptionStatus.PAST_DUE]: theme.palette.error.main,
  [SubscriptionStatus.CANCELED]: theme.palette.neutral.main,
  [SubscriptionStatus.INCOMPLETE]: theme.palette.neutral.main,
  [SubscriptionStatus.INCOMPLETE_EXPIRED]: theme.palette.neutral.main,
  [SubscriptionStatus.UNPAID]: theme.palette.neutral.main,
};

const getColorFromSubscriptionStatus = (status: SubscriptionStatus): string =>
  SUBSCRIPTION_STATUS_COLORS[status];

type SubscriptionStatusString = keyof typeof SubscriptionStatus;
const getSubscriptionStatusFromString = (raw: string): SubscriptionStatus => {
  return SubscriptionStatus[raw.toUpperCase() as SubscriptionStatusString];
};

const transformSubscriptionStatusBreakdownFromAPI = (
  api: SubscriptionStatusBreakdownAPI
): SubscriptionStatusBreakdown => ({
  subscriptionStatus: getSubscriptionStatusFromString(api.subscription_status),
  count: api.count,
});

export const formatSubscriptionStatusBreakdownAPIToPieChartData = (
  rawData: SubscriptionStatusBreakdownAPI[]
): PieChartDatum<SubscriptionStatus>[] => {
  return rawData
    .map(transformSubscriptionStatusBreakdownFromAPI)
    .map((item) => ({
      id: item.subscriptionStatus,
      label: capitalCase(item.subscriptionStatus),
      value: item.count,
      color: getColorFromSubscriptionStatus(item.subscriptionStatus),
    }));
};
