import RelativeTimeFormat, { Unit } from "relative-time-format";

import { sentenceCase } from "change-case";
import en from "relative-time-format/locale/en";

RelativeTimeFormat.addLocale(en);

export const relativeDate = (timestamp: Date) => {
  const today = Date.now();

  const formatter = new RelativeTimeFormat("en", {
    numeric: "auto",
  });

  const difference = getDateDifference(timestamp, new Date(today));

  return difference
    ? sentenceCase(formatter.format(difference.value, difference.unit))
    : undefined;
};

const getDateDifference = (
  firstDate: Date,
  secondDate: Date
): { value: number; unit: Unit } | undefined => {
  const elapsed = firstDate.getTime() - secondDate.getTime();
  for (const u in units) {
    if (Math.abs(elapsed) > units[u as keyof typeof units] || u === "seconds") {
      return {
        value: Math.round(elapsed / units[u as keyof typeof units]),
        unit: u as Unit,
      };
    }
  }
};

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  week: 24 * 60 * 60 * 1000 * 7,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

export const formatDate = (value: Date, format: Intl.DateTimeFormatOptions) => {
  return new Intl.DateTimeFormat("en-GB", format).format(value);
};

export const SHORT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "2-digit",
};

export const formatToShortDate = (date: Date) =>
  formatDate(date, SHORT_DATE_FORMAT_OPTIONS);

export const formatToShortMonthAndYear = (date: Date) =>
  formatDate(date, { month: "short", year: "numeric" });

export const startOfMonth = (date: Date) => {
  const d = new Date(date);
  d.setDate(1);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const formatLongDateWithoutWeekday = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const getCalendarDayEpoch = (date: Date) =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

export const getDateDifferenceInWholeDays = (
  earlierDate: Date,
  laterDate: Date
) => {
  return Math.floor(
    (getCalendarDayEpoch(laterDate) - getCalendarDayEpoch(earlierDate)) /
      (1000 * 60 * 60 * 24)
  );
};
