import { randomUUID } from "../../util/uuid";
import { WriteableScheduleItem } from "../workout-plan/types";
import { Schedule } from "../workout/types";
import { WorkoutList } from "./types";

const NUMBER_OF_DAYS_IN_WEEK = 7;

export const transformWorkoutListIntoWriteableScheduleItems = (
  workoutLists: WorkoutList[]
): WriteableScheduleItem[] =>
  workoutLists
    .map((item, weekIndex) =>
      item.workouts.map((item, dayIndex) => ({
        day: weekIndex * NUMBER_OF_DAYS_IN_WEEK + dayIndex + 1,
        workout: item.workout?.id ?? null,
      }))
    )
    .flat();

export const transformWorkoutListsIntoScheduleAPI = (
  workoutLists: WorkoutList[]
): WriteableScheduleItem[] =>
  workoutLists
    .map((week, weekIndex) =>
      weekIndex === workoutLists.length - 1 ? week : fillWeekWithRestDays(week)
    )
    .map((item, weekIndex) =>
      item.workouts.map((item, dayIndex) => ({
        day: weekIndex * NUMBER_OF_DAYS_IN_WEEK + dayIndex + 1,
        workout: item.workout?.id ?? null,
      }))
    )
    .flat();

const fillWeekWithRestDays = (week: WorkoutList): WorkoutList => {
  const workouts = [...week.workouts];
  const numberOfRestDaysToPush = NUMBER_OF_DAYS_IN_WEEK - workouts.length;

  for (let index = 0; index < numberOfRestDaysToPush; index++) {
    workouts.push({
      uuid: randomUUID(),
      workout: null,
    });
  }
  return { ...week, workouts };
};

export const transformScheduleToWriteableScheduleItems = (
  schedule: Schedule
): WriteableScheduleItem[] =>
  schedule.map((item) => ({
    day: item.weekAndDay.dayNumber,
    workout: item.workout?.id || null,
  }));
