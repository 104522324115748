import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import undoable, { ActionCreators } from "redux-undo";
import { useDragScroll } from "../../../hooks/useDragScroll";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useStore } from "../../../store";
import { GetAllFavouriteWorkouts } from "../../../store/favourites/actions";
import { selectAllFavouritedWorkoutsResource } from "../../../store/favourites/selectors";
import {
  PlanBuilderReducer,
  planBuilderActions,
} from "../../../store/plan-builder/slice";
import {
  transformScheduleToWriteableScheduleItems,
  transformWorkoutListIntoWriteableScheduleItems,
} from "../../../store/plan-builder/transformer";
import {
  MAX_ITEMS_IN_LIST,
  WorkoutList as WorkoutListType,
  defaultPlanBuilderState,
} from "../../../store/plan-builder/types";
import { GetWorkoutPlan } from "../../../store/workout-plan/actions";
import { selectCurrentWorkoutPlanResource } from "../../../store/workout-plan/selectors";
import { PatchWorkoutFromScheduleItem } from "../../../store/workout/actions";
import { selectUpdateWorkoutResource } from "../../../store/workout/selectors";
import { Schedule } from "../../../store/workout/types";
import { randomUUID } from "../../../util/uuid";
import { DialogName, useConfirmDialog } from "../../common/Dialog/hooks";
import { WorkoutPlanInitialiser } from "./WorkoutPlanBuilder.utils";
import { useUpdateWorkoutPlan } from "./useUpdateWorkoutPlan";
import { useWorkoutPlanBuilderNavigators } from "./useWorkoutPlanBuilderNavigators";
import { useAutosave } from "../../../hooks/useAutosave";

const SAVE_PLAN_BUILDER_TIMEOUT_MS = 30000;

export const useWorkoutPlanBuilder = () => {
  const [select, dispatchStore] = useStore();

  const [workoutPlanBuilderState, dispatch] = useReducer(
    undoable(PlanBuilderReducer),
    { past: [], present: defaultPlanBuilderState, future: [] }
  );
  const { id: playlistIdFromPath } = useParams();
  const playlistId = playlistIdFromPath!;

  const workoutPlanResource = select(selectCurrentWorkoutPlanResource);
  const updateWorkoutResource = select(selectUpdateWorkoutResource);
  const currentWorkoutPlan = workoutPlanResource.data;

  const {
    navigateToWorkoutPlanNoEdit,
    navigateToWorkoutPlanDayEdit,
    navigateToPlaylistWorkoutDay,
    navigateToWorkoutPlan,
  } = useWorkoutPlanBuilderNavigators();

  const navigateToViewPlan = () => {
    if (playlistIdFromPath) {
      navigateToWorkoutPlan(playlistIdFromPath);
    }
  };
  const navigateToWeekAndDay = (week: number, day: number) => {
    const playlistWorkoutDayId = currentWorkoutPlan?.schedule.find(
      (item) =>
        item.weekAndDay.week === week && item.weekAndDay.dayOfWeek === day
    )?.id;
    if (playlistWorkoutDayId) {
      navigateToWorkoutPlanDayEdit({
        playlistId,
        playlistWorkoutDayId,
      });
    }
  };

  const dragScrollRef = useDragScroll(
    (target, element) =>
      target === element ||
      target.parentElement === element ||
      target.parentElement === element.firstChild
  );

  const undo = () => {
    dispatch(ActionCreators.undo());
  };
  const redo = () => {
    dispatch(ActionCreators.redo());
  };

  const canUndo = workoutPlanBuilderState.past.length > 0;
  const canRedo = workoutPlanBuilderState.future.length > 0;

  const retrieveWorkoutPlan = useCallback(async () => {
    if (playlistId) {
      const workoutPlan = await GetWorkoutPlan(dispatchStore, playlistId);
      if (workoutPlan?.publishedAt || !!workoutPlan?.assignations) {
        navigateToWorkoutPlanNoEdit(workoutPlan.id);
      }
      return;
    }
  }, [dispatchStore, navigateToWorkoutPlanNoEdit, playlistId]);

  const [isUpdateWorkoutResourceError, hideUpdateWorkoutResourceError] =
    useErrorMessageState(updateWorkoutResource.isError);

  const toggleFavouriteWorkoutAtWeekAndDay = async ({
    week,
    day,
    isFavourite,
  }: {
    week: number;
    day: number;
    isFavourite: boolean;
  }) => {
    const playlistWorkoutDayId = currentWorkoutPlan?.schedule.find(
      (item) =>
        item.weekAndDay.week === week && item.weekAndDay.dayOfWeek === day
    )?.id;
    if (playlistWorkoutDayId) {
      await PatchWorkoutFromScheduleItem(dispatchStore, playlistWorkoutDayId, {
        isFavourite: !isFavourite,
      });
      await GetAllFavouriteWorkouts(dispatchStore);
    }
  };

  const currentWorkoutLists =
    workoutPlanBuilderState.present.planBuilder.workoutLists;

  const persistedSchedule = useMemo(
    () =>
      workoutPlanResource.data?.schedule
        ? transformScheduleToWriteableScheduleItems(
            workoutPlanResource.data.schedule
          )
        : null,
    [workoutPlanResource.data?.schedule]
  );

  const currentSchedule = useMemo(
    () => transformWorkoutListIntoWriteableScheduleItems(currentWorkoutLists),
    [currentWorkoutLists]
  );

  const hasPlanBuilderStateChanged = useMemo(
    () => JSON.stringify(persistedSchedule) !== JSON.stringify(currentSchedule),
    [currentSchedule, persistedSchedule]
  );

  const initialiseWorkoutPlanBuilder = useCallback(
    (schedule?: Schedule) => {
      const workoutPlanInitialiser = new WorkoutPlanInitialiser(schedule || []);
      const workoutLists = workoutPlanInitialiser.build();
      dispatch(planBuilderActions.setWorkoutLists({ workoutLists }));
    },
    [dispatch]
  );

  useEffect(() => {
    retrieveWorkoutPlan().then(() => dispatch(ActionCreators.clearHistory()));
  }, [initialiseWorkoutPlanBuilder, retrieveWorkoutPlan]);

  useEffect(() => {
    workoutPlanResource.data &&
      initialiseWorkoutPlanBuilder(workoutPlanResource.data.schedule);
  }, [initialiseWorkoutPlanBuilder, workoutPlanResource.data]);

  const myFavouritedWorkouts = select(selectAllFavouritedWorkoutsResource);
  const builder = workoutPlanBuilderState.present.planBuilder;
  const myWorkoutsList: WorkoutListType = useMemo(
    () => ({
      id: randomUUID(),
      workouts: (myFavouritedWorkouts.data || []).map(({ workout }) => ({
        workout,
        uuid: randomUUID(),
      })),
    }),
    [myFavouritedWorkouts.data]
  );
  const isLoadingMyWorkouts = myFavouritedWorkouts.isLoading;

  const refreshMyWorkouts = () => {
    GetAllFavouriteWorkouts(dispatchStore);
  };

  useEffect(() => {
    !myFavouritedWorkouts.data?.length &&
      GetAllFavouriteWorkouts(dispatchStore);
  }, [dispatchStore, myFavouritedWorkouts.data?.length]);

  const [isShowingDaysInWeekWarning, setIsShowingDaysInWeekWarning] =
    useState(false);

  const showDaysInWeekWarning = () => {
    setIsShowingDaysInWeekWarning(true);
  };
  const hideDaysInWeekWarning = () => {
    setIsShowingDaysInWeekWarning(false);
  };
  const deleteWeekAtIndex = (index: number) => {
    dispatch(planBuilderActions.deleteListAtIndex({ index }));
  };

  const duplicateWeekAtIndex = (index: number) => {
    dispatch(
      planBuilderActions.duplicateListAtIndex({
        copyFromIndex: index,
      })
    );
  };

  const addRestToWeekAtIndex = (index: number) => {
    dispatch(planBuilderActions.appendRestToListAtIndex({ listIndex: index }));
  };

  const removeItemFromWeekIndexAtIndex = (
    weekIndex: number,
    itemIndex: number
  ) => {
    dispatch(
      planBuilderActions.removeItemFromWeekIndexAtIndex({
        weekIndex,
        itemIndex,
      })
    );
  };
  const addWeek = useCallback(() => {
    dispatch(
      planBuilderActions.createListAtIndex({
        newList: WorkoutPlanInitialiser.createWorkoutList(),
      })
    );
  }, [dispatch]);

  const duplicateItemInWeekIndexAtIndex = (
    weekIndex: number,
    itemIndex: number
  ) => {
    dispatch(
      planBuilderActions.duplicateCardAtIndex({
        listIndex: weekIndex,
        cardIndex: itemIndex,
      })
    );
  };

  const getByDraggableId = (week: WorkoutListType, draggableId: string) => {
    return week.workouts.filter((workout) => workout.uuid === draggableId)[0];
  };

  const onDragEnd: OnDragEndResponder = (result) => {
    const { destination, source, draggableId, type } = result;
    const isFromOrigin = source.droppableId === "origin";
    const isToOrigin = destination?.droppableId === "origin";

    // No destination
    if (!destination) {
      return;
    }

    const destinationListHasMaxItems = () =>
      currentWorkoutLists.find((list) => list.id === destination.droppableId)
        ?.workouts.length === MAX_ITEMS_IN_LIST;

    // source = destination
    if (
      destination.index === source.index &&
      destination.droppableId === source.droppableId
    ) {
      return;
    }

    // Can't drag into origin
    if (isToOrigin) {
      return;
    }

    // Reordering lists
    if (type === "list") {
      dispatch(
        planBuilderActions.moveList({
          fromIndex: source.index,
          toIndex: destination.index,
        })
      );
      return;
    }
    // Reordering items in the same list
    if (!isFromOrigin && destination.droppableId === source.droppableId) {
      dispatch(
        planBuilderActions.reorderItemInList({
          listId: destination.droppableId,
          fromIndex: source.index,
          toIndex: destination.index,
        })
      );
      return;
    }

    // Moving items between different lists (not including origin)
    if (!isFromOrigin && destination.droppableId !== source.droppableId) {
      if (destinationListHasMaxItems()) {
        showDaysInWeekWarning();
      } else {
        dispatch(
          planBuilderActions.moveItem({
            from: {
              listId: source.droppableId,
              itemIndex: source.index,
            },
            to: {
              listId: destination.droppableId,
              itemIndex: destination.index,
            },
          })
        );
      }
      return;
    }

    // Moving items from origin to a week list
    if (isFromOrigin && !isToOrigin) {
      if (destinationListHasMaxItems()) {
        showDaysInWeekWarning();
      } else {
        dispatch(
          planBuilderActions.createItemInListAtIndex({
            listId: destination.droppableId,
            index: destination.index,
            workout: getByDraggableId(myWorkoutsList, draggableId).workout!,
          })
        );
      }
      return;
    }
  };

  const {
    updateWorkoutPlanName,
    updateWorkoutPlanDescription,
    saveWorkoutPlanChanges,
    createPlaylistWorkoutDay,
    playlistWorkoutDayResource,
  } = useUpdateWorkoutPlan({
    currentSchedule,
    dispatchStore,
    hasPlanBuilderStateChanged,
    navigateToPlaylistWorkoutDay,
    playlistId,
    workoutLists: currentWorkoutLists,
  });

  useAutosave(
    saveWorkoutPlanChanges,
    SAVE_PLAN_BUILDER_TIMEOUT_MS,
    hasPlanBuilderStateChanged
  );

  const saveWorkoutPlanDialog = useConfirmDialog(
    DialogName.SAVE_PLAN,
    saveWorkoutPlanChanges,
    { isLoading: workoutPlanResource.isLoading }
  );

  const [isWorkoutPlanResourceError, hideWorkoutPlanResourceError] =
    useErrorMessageState(workoutPlanResource.isError);

  return {
    addWeek,
    workoutLists: builder.workoutLists,
    onDragEnd,
    myWorkoutsList,
    deleteWeekAtIndex,
    duplicateWeekAtIndex,
    addRestToWeekAtIndex,
    hideDaysInWeekWarning,
    isShowingDaysInWeekWarning,
    isLoadingMyWorkouts,
    refreshMyWorkouts,
    dragScrollRef,
    currentWorkoutPlan,
    workoutPlanResource,
    updateWorkoutPlanName,
    updateWorkoutPlanDescription,
    hasPlanBuilderStateChanged,
    navigateToViewPlan,
    isWorkoutPlanResourceError,
    hideWorkoutPlanResourceError,
    saveWorkoutPlanDialog,
    removeItemFromWeekIndexAtIndex,
    navigateToWeekAndDay,
    createPlaylistWorkoutDay,
    isCreatingNewWorkout: playlistWorkoutDayResource.isLoading,
    isFavouritingWorkout: updateWorkoutResource.isLoading,
    toggleFavouriteWorkoutAtWeekAndDay,
    isUpdateWorkoutResourceError,
    hideUpdateWorkoutResourceError,
    undo,
    redo,
    canUndo,
    canRedo,
    duplicateItemInWeekIndexAtIndex,
  };
};
