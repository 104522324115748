import { Box, Button, Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { PageHeader } from "../../common/PageHeader/PageHeader";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { TableSkeleton } from "../../common/Table/TableSkeleton";
import { EmptyExercises } from "./EmptyExercises";
import { ExercisesTable } from "./ExercisesTable";
import { useExerciseShowroom } from "./useExerciseShowroom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ExerciseAutocomplete } from "../WorkoutBuilder/ExerciseAutocomplete/ExerciseAutocomplete";

interface Props extends ReturnType<typeof useExerciseShowroom> {}

export const ExerciseShowroom: React.FC<Props> = ({
  totalPages,
  currentPage,
  exercises,
  totalExercises,
  navigateToPlans,
  isLoading,
  isShowingErrorMessage,
  hideErrorMessage,
  refreshExercises,
  exerciseSearchResultsResource,
  getNextPage,
  searchQuery,
  setSearchQuery,
  navigateToCreatorExercise,
}) => {
  return (
    <>
      <PageTemplate>
        <PageHeader
          title="Exercise Showroom"
          subtitle="Manage your exercises here"
        />
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <>
            {totalExercises ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems={"end"}
                  sx={{ mb: 1 }}
                >
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    renderItem={(item) => (
                      <PaginationItem
                        component={Link}
                        to={`/exercises${
                          item.page === 1 ? "" : `?page=${item.page}`
                        }`}
                        {...item}
                      />
                    )}
                  />
                  <Box display="flex" flexDirection="column">
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon />}
                      sx={{
                        bgcolor: "primary",
                      }}
                      onClick={refreshExercises}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Box>
                <ExerciseAutocomplete
                  options={exerciseSearchResultsResource.data || []}
                  isLoading={exerciseSearchResultsResource.isLoading}
                  getNextPage={getNextPage}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  onSelectExercise={(exercise) => {
                    exercise && navigateToCreatorExercise(exercise);
                  }}
                />
                <ExercisesTable
                  exercises={exercises}
                  onSelectExercise={navigateToCreatorExercise}
                />
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  renderItem={(item) => (
                    <PaginationItem
                      component={Link}
                      to={`/exercises${
                        item.page === 1 ? "" : `?page=${item.page}`
                      }`}
                      {...item}
                    />
                  )}
                  sx={{ mt: 1 }}
                />
              </>
            ) : (
              <EmptyExercises onClickCreateWorkout={navigateToPlans} />
            )}
          </>
        )}
      </PageTemplate>
      <SnackbarAlert
        isOpen={isShowingErrorMessage}
        close={hideErrorMessage}
        message="Oops! Something went wrong when refreshing your exercise showroom! Please try again later."
        severity="error"
      />
    </>
  );
};
