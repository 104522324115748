import {
  Box,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  TypographyTypeMap,
  useTheme,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import React, { useEffect, useState } from "react";
import { CancelIconButton } from "./CancelIconButton";
import { SaveButton } from "./SaveButton";

interface InlineInputEditProps {
  initialValue: string;
  variant: Variant;
  typographyProps?: Omit<TypographyTypeMap["props"], "variant">;
  textFieldProps?: TextFieldProps;
  onBlur?: () => void;
  isInputLoading?: boolean;
  handleSubmitText?: (value: string) => Promise<void>;
  validateText?: (input: string) => boolean;
  unFocusOnBlur?: boolean;
  vertical?: boolean;
  placeholderText?: string;
  minInputSizeChars?: number;
  tooltipText?: string;
}

export const InlineInputEdit: React.FC<InlineInputEditProps> = ({
  initialValue,
  variant,
  onBlur,
  isInputLoading = false,
  handleSubmitText,
  validateText = () => true,
  unFocusOnBlur,
  vertical = false,
  placeholderText,
  typographyProps,
  textFieldProps,
  minInputSizeChars = 0,
  tooltipText = "Edit",
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);

  const handleChangeText = (text: string) => {
    setValue(text);
    const isValid = validateText(text);
    setIsError(!isValid);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const resetInput = () => {
    setValue(initialValue);
    setIsEditMode(false);
  };
  const theme = useTheme();

  return isEditMode ? (
    <Box display="flex" flexDirection={vertical ? "column" : "row"} gap={1}>
      <TextField
        onClick={(event) => {
          event.preventDefault();
        }}
        fullWidth
        id={initialValue}
        autoFocus
        value={value}
        onChange={(e) => {
          handleChangeText(e.target.value);
        }}
        onBlur={() => {
          if (unFocusOnBlur) {
            if (!isInputLoading) {
              resetInput();
            }
          }
          onBlur?.();
        }}
        error={isError}
        sx={{
          "& .MuiInputBase-root": {
            padding: 0,
          },
        }}
        {...textFieldProps}
        inputProps={{
          size: Math.max(initialValue.length + 1, minInputSizeChars),
          style: {
            ...theme.typography[variant],
            padding: theme.spacing(1),
          },
          ...textFieldProps?.inputProps,
        }}
        placeholder={placeholderText}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: vertical ? "row" : "column",
          justifyContent: "flex-end",
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <SaveButton
            isLoading={isInputLoading}
            onClickButton={async () => {
              handleSubmitText?.(value).then(() => {
                setIsEditMode(false);
              });
            }}
            isButtonDisabled={
              isInputLoading || isError || value === initialValue
            }
          />
          <CancelIconButton onClick={resetInput} disabled={isInputLoading} />
        </Box>
      </Box>
    </Box>
  ) : (
    <Tooltip title={tooltipText}>
      <Typography
        onClick={(event) => {
          event.preventDefault();
          setIsEditMode(true);
        }}
        variant={variant}
        {...typographyProps}
        sx={{
          p: 1,
          border: 2,
          borderColor: "rgba(0,0,0,0)",
          borderRadius: 1,
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.04)",
            cursor: "pointer",
          },
          ...typographyProps?.sx,
        }}
      >
        {value.length ? value : placeholderText || ""}
      </Typography>
    </Tooltip>
  );
};
