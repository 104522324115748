import { Box } from "@mui/material";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import { WorkoutList } from "../../../store/plan-builder/types";
import { WorkoutListDroppable } from "./WorkoutListDroppable";

interface WeeksProps {
  handleDeleteList: (index: number) => void;
  handleDuplicateList: (index: number) => void;
  handleAddRest: (index: number) => void;
  handleRemoveItem: (weekIndex: number, itemIndex: number) => void;
  handleDuplicateItem: (weekIndex: number, itemIndex: number) => void;
  workoutLists: WorkoutList[];
  navigateToWeekAndDay: (week: number, day: number) => void;
  hasUnsavedChanges: boolean;
  onSubmitCreateNewWorkout: (dayNumber: number, name: string) => Promise<void>;
  isCreatingNewWorkout: boolean;
  isFavouritingWorkout: boolean;
  toggleFavouriteWorkout: ({
    week,
    day,
    isFavourite,
  }: {
    week: number;
    day: number;
    isFavourite: boolean;
  }) => Promise<void>;
}

export const Weeks: React.FC<WeeksProps> = ({
  handleDeleteList,
  handleDuplicateList,
  handleAddRest,
  workoutLists,
  handleRemoveItem,
  navigateToWeekAndDay,
  hasUnsavedChanges,
  onSubmitCreateNewWorkout,
  isCreatingNewWorkout,
  toggleFavouriteWorkout,
  isFavouritingWorkout,
  handleDuplicateItem,
}) => {
  return (
    <Droppable droppableId="main" direction="horizontal" type="list">
      {(props) => (
        <Box
          {...props.droppableProps}
          ref={props.innerRef}
          flexDirection="row"
          display="flex"
        >
          {workoutLists.map((list, weekIndex) => (
            <Box key={list.id} display="flex" flexDirection="column">
              <WorkoutListDroppable
                workoutList={list}
                weekIndex={weekIndex}
                deleteList={() => handleDeleteList(weekIndex)}
                duplicateList={() => handleDuplicateList(weekIndex)}
                addRest={() => handleAddRest(weekIndex)}
                removeItem={(itemIndex) =>
                  handleRemoveItem(weekIndex, itemIndex)
                }
                canEditWorkout={!hasUnsavedChanges}
                navigateToDay={(day) => {
                  navigateToWeekAndDay(weekIndex + 1, day);
                }}
                isCreatingWorkout={isCreatingNewWorkout}
                onCreateNewWorkout={onSubmitCreateNewWorkout}
                favouriteWorkout={toggleFavouriteWorkout}
                isFavouritingWorkout={isFavouritingWorkout}
                duplicateItem={(itemIndex) =>
                  handleDuplicateItem(weekIndex, itemIndex)
                }
              />
            </Box>
          ))}
          {props.placeholder}
        </Box>
      )}
    </Droppable>
  );
};
