import { Home, QuestionMark } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageTemplate } from "../components/common/PageTemplate";

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageTemplate sx={{ display: "flex", flex: 1, height: "100vh" }}>
      <Stack
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Card variant="outlined" elevation={0} sx={{ width: "80%" }}>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
              justifyContent="center"
              p={4}
              gap={1}
            >
              <Box
                borderRadius={3}
                bgcolor="primary.xlight"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={1}
                mb={2}
                sx={({ palette }) => ({
                  ".MuiSvgIcon-root": {
                    color: palette.primary.main,
                  },
                })}
              >
                {<QuestionMark />}
              </Box>
              <Box width="40%">
                <Typography
                  variant="h5"
                  fontWeight={700}
                  align="center"
                  gutterBottom
                >
                  {"Not Found"}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  align="center"
                  gutterBottom
                >
                  {"We're just as confused as you are..."}
                  <br />
                  {"How did you get here?"}
                </Typography>
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  startIcon={<Home />}
                  onClick={() => navigate("/")}
                >
                  {"Take me home"}
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Stack>
    </PageTemplate>
  );
};
