import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { CreatorExerciseAPI } from "../exercises/types";
import { PaginatedAPI } from "../types";
import { workoutBuilderActions } from "./slice";
import { transformCreatorExercisesFromAPI } from "./transformer";

export const GetExercisesBySearchQuery = async (
  dispatch: DispatchFn,
  query: string,
  pageNumber: number
) => {
  dispatch(workoutBuilderActions.getExercisesByQueryAttempt());
  try {
    const response = await client.get<PaginatedAPI<CreatorExerciseAPI>>(
      "analysis/exercise-search/",
      { params: { search: query, page: pageNumber } }
    );
    const searchResults = transformCreatorExercisesFromAPI(
      response.data.results
    );
    dispatch(
      workoutBuilderActions.getExercisesByQuerySuccess({
        results: searchResults,
        pageNumber,
      })
    );
    return response.data;
  } catch (e) {
    dispatch(workoutBuilderActions.getExercisesByQueryFailure());
  }
};

export const ClearExerciseSearchResults = (dispatch: DispatchFn) => {
  dispatch(workoutBuilderActions.clearExerciseSearchResults());
};
