import { Serie } from "@nivo/line";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRequest } from "../../../../hooks/request/useRequest";
import { pluralize } from "../../../../util/string";
import { SelectOption } from "../../../common/Select/Select";
import { UserSignup, UserSignupAPI } from "../types";
import {
  addMissingDaysToSignupCountData,
  groupUserSignupsByDate,
  convertSignupCountAPIToLineSerie,
  transformUserSignupFromAPI,
} from "../utils";

const AVAILABLE_TIMEFRAMES = [7, 30];

export const useSignups = () => {
  const [timeframe, setTimeframe] = useState(AVAILABLE_TIMEFRAMES[0]);

  const [_getUserSignups, userSignupsResource] = useRequest<UserSignupAPI[]>(
    "GET",
    "/dashboard/signup-history"
  );

  const getSignups = useCallback(
    async (_timeframe?: number) => {
      const rawData = await _getUserSignups("/dashboard/signup-history", {
        params: { days_ago: _timeframe ?? timeframe },
      });
      const transformed = rawData?.map(transformUserSignupFromAPI);
      return transformed;
    },
    [_getUserSignups, timeframe]
  );
  const [signupDataLineSerie, setSignupDataLineSerie] = useState<Serie | null>(
    null
  );

  const convertRawSignupDataIntoLineSerieData = useCallback(
    (userSignups: UserSignup[], totalDays: number) => {
      const countsByDate = groupUserSignupsByDate(userSignups);
      const completeIntervals = addMissingDaysToSignupCountData({
        countsByDate,
        numberOfDays: totalDays,
      });
      const formattedSignupData =
        convertSignupCountAPIToLineSerie(completeIntervals);
      return formattedSignupData;
    },
    []
  );

  const handleChangeSignupTimeframe = useCallback(
    async (newValue: string) => {
      setTimeframe(parseInt(newValue));
      const newData = await getSignups(parseInt(newValue));
      if (newData) {
        const formattedSignupData = convertRawSignupDataIntoLineSerieData(
          newData,
          parseInt(newValue)
        );

        setSignupDataLineSerie(formattedSignupData);
      }
    },
    [convertRawSignupDataIntoLineSerieData, getSignups]
  );

  const refreshSignups = useCallback(async () => {
    handleChangeSignupTimeframe(`${timeframe}`);
  }, [handleChangeSignupTimeframe, timeframe]);

  const initialiseSignupData = useCallback(() => {
    handleChangeSignupTimeframe(`${AVAILABLE_TIMEFRAMES[0]}`);
  }, [handleChangeSignupTimeframe]);

  useEffect(() => {
    if (!userSignupsResource.data) {
      initialiseSignupData();
    }
  }, [initialiseSignupData, userSignupsResource.data]);

  const formattedTimeframeOptions: SelectOption[] = AVAILABLE_TIMEFRAMES.map(
    (numberOfDays) => ({
      value: numberOfDays,
      label: `Last ${numberOfDays} ${pluralize("day", numberOfDays)}`,
    })
  );
  const totalUserSignupsInTimeframe = useMemo(
    () =>
      signupDataLineSerie?.data.reduce(
        (acc, cur) => acc + parseInt(cur.y?.toString() || "0"),
        0
      ) ?? 0,
    [signupDataLineSerie?.data]
  );

  return {
    timeframe,
    refreshSignups,
    userSignupsResource,
    signupDataLineSerie,
    handleChangeSignupTimeframe,
    formattedTimeframeOptions,
    totalUserSignupsInTimeframe,
  };
};
