import MUIStep from "@mui/material/Step";
import MUIStepLabel from "@mui/material/StepLabel";
import MUIStepper from "@mui/material/Stepper";
import React from "react";
import { Step, StepperProps } from "./index";

interface ComponentProps extends StepperProps {}

export const Stepper: React.FC<ComponentProps> = ({
  activeStepIndex,
  steps,
  onClickStep,
}) => {
  return (
    <MUIStepper activeStep={activeStepIndex} alternativeLabel>
      {steps.map((step: Step, stepIndex: number) => {
        return (
          <MUIStep
            key={step.label}
            completed={step.isCompleted ?? activeStepIndex > stepIndex}
            onClick={() => onClickStep?.(stepIndex)}
            sx={{
              "& .MuiStepLabel-root": {
                "&:hover": {
                  cursor: "pointer",
                },
              },
            }}
          >
            <MUIStepLabel>{step.label}</MUIStepLabel>
          </MUIStep>
        );
      })}
    </MUIStepper>
  );
};
