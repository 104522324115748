import { CssBaseline } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import { Router, routes } from "./router";
import { BaseRoute } from "./router/BaseRoute";
import { NotFound } from "./router/NotFound";
import { ProtectedRoute } from "./router/ProtectedRoute";
import { StoreProvider } from "./store";
import { ThemeProvider } from "./theme/ThemeProvider";
import { Clarity } from "./components/common/Clarity";

const App: React.FC = () => {
  return (
    <>
      {process.env.REACT_APP_ENV === "production" && <Clarity />}
      <StoreProvider>
        <ThemeProvider>
          <CssBaseline />
          <Router>
            <Routes>
              {routes.protectedRoutes
                .filter((route) => route.isVisible)
                .map((route) => (
                  <Route
                    key={route.name}
                    path={route.path}
                    element={
                      <BaseRoute {...route}>
                        <ProtectedRoute>{route.component}</ProtectedRoute>
                      </BaseRoute>
                    }
                  />
                ))}
              {routes.unprotectedRoutes
                .filter((route) => route.isVisible)
                .map((route) => (
                  <Route
                    path={route.path}
                    element={
                      <BaseRoute {...route}>{route.component}</BaseRoute>
                    }
                    key={route.name}
                  />
                ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </StoreProvider>
    </>
  );
};

export default App;
