import {
  createDefaultPaginatedResource,
  createDefaultResource,
  PaginatedResource,
  Resource,
} from "../types";

export interface ChallengePlansApi {
  challenge_plans: Array<{
    id: string;
    name: string;
  }>;
}

export interface ChallengePlanTransformationsApi {
  id: string;
  run_through: RunThroughApi;
  before_entry: ChallengePlanEntryApi;
  after_entry: ChallengePlanEntryApi | null;
  completed_at: string | null;
  has_public_sharing_consent: boolean;
  user: UserApi;
}

export interface RunThroughApi {
  id: string;
  playlist: PlaylistApi;
  number_of_completed_workout_days: number;
}

export interface PlaylistApi {
  id: string;
  name: string;
  thumbnail: string;
  number_of_workouts: number;
}

export interface ChallengePlanEntryApi {
  id: string;
  front: ProgressPictureApi;
  back: ProgressPictureApi;
  side: ProgressPictureApi;
  submitted_at: string;
}

export interface ProgressPictureApi {
  id: string;
  media: MediaApi;
  captured_at: string;
}

export interface MediaApi {
  id: string;
  file_name: string;
  url: string;
}

export interface UserApi {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  email: string;
}

export interface ChallengePlanTransformation {
  id: string;
  runThrough: WorkoutPlanRunThrough;
  beforeEntry: ChallengePlanEntry;
  afterEntry: ChallengePlanEntry | null;
  completedAt: string | null;
  hasPublicSharingConsent: boolean;
  user: User;
}

export interface WorkoutPlanRunThrough {
  id: string;
  playlist: Playlist;
  numberOfCompletedWorkoutDays: number;
}

export interface Playlist {
  id: string;
  name: string;
  thumbnail: string;
  numberOfWorkouts: number;
}

export interface ChallengePlanEntry {
  id: string;
  front: ProgressPicture;
  back: ProgressPicture;
  side: ProgressPicture;
  submittedAt: string;
}

export interface ProgressPicture {
  id: string;
  media: Media;
  capturedAt: string;
}

export interface Media {
  id: string;
  fileName: string;
  url: string;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
}

export interface ChallengePlan {
  id: string;
  name: string;
}

export interface TransformationState {
  challengePlans: Resource<ChallengePlan[]>;
  challengePlanTransformations: PaginatedResource<ChallengePlanTransformation>;
}

export const defaultTransformationsState: TransformationState = {
  challengePlans: createDefaultResource(),
  challengePlanTransformations: createDefaultPaginatedResource(),
};
