import React from "react";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { WorkoutBuilder } from "../WorkoutBuilder";
import { WorkoutBuilderLoading } from "../WorkoutBuilder/WorkoutBuilderLoading";
import { useWorkoutPlanDayPage } from "./useWorkoutPlanDayPage";

interface WorkoutPlanDayPageProps
  extends ReturnType<typeof useWorkoutPlanDayPage> {}

export const WorkoutPlanDayPage: React.FC<WorkoutPlanDayPageProps> = ({
  expandedWorkout,
  isGetWorkoutError,
  hideGetWorkoutError,
  saveWorkout,
  updateWorkoutDescription,
  updateWorkoutName,
  isUpdating,
  isUpdateError,
  navigateToPlan,
  weekAndDay,
  workoutPlanName,
}) => {
  return (
    <>
      {expandedWorkout ? (
        <PageTemplate>
          <WorkoutBuilder
            expandedWorkout={expandedWorkout}
            weekAndDay={weekAndDay}
            workoutPlanName={workoutPlanName}
            updateName={updateWorkoutName}
            updateDescription={updateWorkoutDescription}
            saveWorkout={saveWorkout}
            isUpdating={isUpdating}
            isUpdateError={isUpdateError}
            onPressBack={navigateToPlan}
          />
        </PageTemplate>
      ) : (
        <WorkoutBuilderLoading />
      )}
      <SnackbarAlert
        close={hideGetWorkoutError}
        isOpen={isGetWorkoutError}
        message="Something went wrong whilst retrieving the workout. Please try again later."
        severity="error"
      />
    </>
  );
};
