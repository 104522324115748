import { useCallback } from "react";
import { useStore } from "../../../../store";
import {
  PatchWorkoutPlan,
  PatchWorkoutPlanThumbnail,
} from "../../../../store/workout-plan/actions";
import { selectCurrentWorkoutPlanResource } from "../../../../store/workout-plan/selectors";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";

export interface WorkoutPlanEditHookReturnProps {
  name: string;
  description: string;
  updateWorkoutPlanName: (name: string) => Promise<void>;
  updateWorkoutPlanDescription: (description: string) => Promise<void>;
  updateWorkoutPlanArtwork: (file: File) => Promise<void>;
  publishWorkoutPlan: () => Promise<void>;
  isLoading: boolean;
  isError: boolean;
}

export const useWorkoutPlanEdit = ({
  workoutPlan,
}: {
  workoutPlan?: BaseWorkoutPlan;
}): WorkoutPlanEditHookReturnProps => {
  const [select, dispatch] = useStore();
  const { data, isLoading, isError } = select(selectCurrentWorkoutPlanResource);
  const workoutPlanId = workoutPlan?.id ?? data?.id;

  const updateWorkoutPlanName = useCallback(
    async (newName: string) => {
      workoutPlanId &&
        (await PatchWorkoutPlan(dispatch, workoutPlanId, { name: newName }));
    },
    [dispatch, workoutPlanId]
  );

  const updateWorkoutPlanDescription = useCallback(
    async (newDescription: string) => {
      workoutPlanId &&
        (await PatchWorkoutPlan(dispatch, workoutPlanId, {
          description: newDescription,
        }));
    },
    [dispatch, workoutPlanId]
  );

  const updateWorkoutPlanArtwork = useCallback(
    async (selectedFile: File | null) => {
      if (selectedFile) {
        workoutPlanId &&
          (await PatchWorkoutPlanThumbnail(
            dispatch,
            workoutPlanId,
            selectedFile
          ));
      }
    },
    [dispatch, workoutPlanId]
  );

  const publishWorkoutPlan = useCallback(async () => {
    workoutPlanId &&
      (await PatchWorkoutPlan(dispatch, workoutPlanId, {
        published_at: new Date().toISOString(),
      }));
  }, [dispatch, workoutPlanId]);

  return {
    name: workoutPlan?.name ?? data?.name ?? "",
    description: workoutPlan?.description ?? data?.description ?? "",
    updateWorkoutPlanName,
    updateWorkoutPlanDescription,
    updateWorkoutPlanArtwork,
    publishWorkoutPlan,
    isLoading,
    isError,
  };
};
