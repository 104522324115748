import { useCallback, useMemo, useState } from "react";
import client from "../../util/http/axios";
import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { Resource } from "../../store/types";

export const useRequest = <T>(
  method: Method,
  path: string,
  config?: AxiosRequestConfig
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const doRequest = useCallback(
    async (_path?: string, _config?: AxiosRequestConfig) => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await client<T, AxiosResponse<T>>(_path || path, {
          method,
          ...config,
          ..._config,
        });
        setData(result.data);
        setIsLoading(false);
        setIsError(false);
        return result.data;
      } catch {
        setIsLoading(false);
        setIsError(true);
      }
    },
    [config, method, path]
  );

  const resource: Resource<T> = useMemo(
    () => ({
      isError,
      isLoading,
      data,
    }),
    [data, isError, isLoading]
  );

  return [doRequest, resource] as const;
};
