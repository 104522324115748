import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

export const useMuxAddVideoDialogForm = () => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "video/*": [".mp4", ".mov", ".avi", ".mkv"],
    },
    maxFiles: 1,
    onDrop: (files) => {
      if (files.length > 0) {
        setSelectedFile(files[0]);
        setFileUrl(URL.createObjectURL(files[0]));
      }
    },
  });

  const removeFile = () => {
    URL.revokeObjectURL(fileUrl!);
    setFileUrl(null);
    setSelectedFile(null);
  };

  useEffect(() => {
    return () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl);
      }
    };
  }, [fileUrl]);

  return {
    fileUrl,
    selectedFile,
    getRootProps,
    getInputProps,
    removeFile,
    isDragActive,
    isDragAccept,
    isDragReject,
  };
};
