import { Box, DialogTitle } from "@mui/material";
import React from "react";
import { Stepper } from "../Stepper";
import { StyledDialog } from "./Dialog.styles";
import { BaseStepperDialogProps } from "./types";

export const BaseStepperDialog: React.FC<
  React.PropsWithChildren<BaseStepperDialogProps>
> = ({
  title,
  isOpen,
  onClose,
  onExitedTransition,
  steps,
  currentStepIndex,
  onClickStep,
  children,
}) => {
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        onExited: onExitedTransition,
      }}
      fullWidth
      maxWidth={"md"}
      PaperProps={{
        sx: {
          p: 3,
        },
      }}
    >
      <Stepper
        steps={steps}
        activeStepIndex={currentStepIndex}
        onClickStep={onClickStep}
      />
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <Box>{children}</Box>
    </StyledDialog>
  );
};
