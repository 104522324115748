import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultExerciseState, CreatorExercise } from "./types";

const exerciseSlice = createSlice({
  name: "exercises",
  initialState: defaultExerciseState,
  reducers: {
    getCreatorExerciseAttempt: (state) => {
      state.currentCreatorExercise.isError = false;
      state.currentCreatorExercise.isLoading = true;
    },
    getCreatorExerciseFailure: (state) => {
      state.currentCreatorExercise.isError = true;
      state.currentCreatorExercise.isLoading = false;
    },
    getCreatorExerciseSuccess: (
      state,
      action: PayloadAction<{ creatorExercise: CreatorExercise }>
    ) => {
      state.currentCreatorExercise.isError = false;
      state.currentCreatorExercise.isLoading = false;
      state.currentCreatorExercise.data = action.payload.creatorExercise;
    },
    setCurrentCreatorExercise: (
      state,
      action: PayloadAction<{ creatorExercise: CreatorExercise }>
    ) => {
      state.currentCreatorExercise.data = action.payload.creatorExercise;
    },
    updateCreatorExerciseNoteAttempt: (state) => {
      state.updateCreatorExerciseNote.isError = false;
      state.updateCreatorExerciseNote.isLoading = true;
    },
    updateCreatorExerciseNoteFailure: (state) => {
      state.updateCreatorExerciseNote.isError = true;
      state.updateCreatorExerciseNote.isLoading = false;
    },
    updateCreatorExerciseNoteSuccess: (
      state,
      action: PayloadAction<{ instruction: string }>
    ) => {
      state.updateCreatorExerciseNote.isError = false;
      state.updateCreatorExerciseNote.isLoading = false;
      if (state.currentCreatorExercise.data) {
        state.currentCreatorExercise.data.defaultNote =
          action.payload.instruction;
      }
    },
    clearCurrentCreatorExercise: (state) => {
      state.currentCreatorExercise.data = null;
    },
    getExercisesAttempt: (state) => {
      state.exercises.isError = false;
      state.exercises.isLoading = true;
    },
    getExercisesFailure: (state) => {
      state.exercises.isError = true;
      state.exercises.isLoading = false;
    },
    getExercisesSuccess: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        exercises: CreatorExercise[];
      }>
    ) => {
      state.exercises.isError = false;
      state.exercises.isLoading = false;
      if (state.exercises.data) {
        state.exercises.data[action.payload.pageNumber] =
          action.payload.exercises;
      } else {
        state.exercises.data = {
          [action.payload.pageNumber]: action.payload.exercises,
        };
      }
    },
    setExercisesCount: (state, action: PayloadAction<{ count: number }>) => {
      state.exercises.count = action.payload.count;
    },
    clearExercisesData: (state) => {
      state.exercises.data = null;
    },
    createExerciseVideoAttempt: (state) => {
      state.createExerciseVideo.isLoading = true;
      state.createExerciseVideo.isError = false;
    },
    createExerciseVideoFailure: (state) => {
      state.createExerciseVideo.isLoading = false;
      state.createExerciseVideo.isError = true;
    },
    createExerciseVideoSuccess: (state) => {
      state.createExerciseVideo.isLoading = false;
      state.createExerciseVideo.isError = false;
    },
    deleteExerciseVideoAttempt: (state) => {
      state.deleteExerciseVideo.isLoading = true;
      state.deleteExerciseVideo.isError = false;
    },
    deleteExerciseVideoFailure: (state) => {
      state.deleteExerciseVideo.isLoading = false;
      state.deleteExerciseVideo.isError = true;
    },
    deleteExerciseVideoSuccess: (state) => {
      state.deleteExerciseVideo.isLoading = false;
      state.deleteExerciseVideo.isError = false;
    },
    reset: () => defaultExerciseState,
  },
});

export const ExerciseReducer = exerciseSlice.reducer;
export const exerciseActions = exerciseSlice.actions;
