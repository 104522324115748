import { IconButton, Tooltip, IconButtonProps } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

export const CancelIconButton: React.FC<IconButtonProps> = (props) => {
  return (
    <Tooltip title="Cancel">
      <span>
        <IconButton
          {...props}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            props.onClick?.(event);
          }}
        >
          <CancelIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
