import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ExpressAccount,
  PayoutInformation,
  PayoutsState,
  defaultPayoutsState,
} from "./types";

const payoutsSlice = createSlice({
  name: "payouts",
  initialState: defaultPayoutsState,
  reducers: {
    reset: () => defaultPayoutsState,
    getExpressAccountAttempt: (state: PayoutsState) => {
      state.expressAccount.isLoading = true;
      state.expressAccount.isError = false;
    },
    getExpressAccountSuccess: (
      state: PayoutsState,
      payload: PayloadAction<ExpressAccount | null>
    ) => {
      state.expressAccount.isError = false;
      state.expressAccount.isLoading = false;
      state.expressAccount.data = payload.payload;
    },
    getExpressAccountFailure: (state: PayoutsState) => {
      state.expressAccount.isError = true;
      state.expressAccount.isLoading = false;
    },
    getPayoutInformationAttempt: (state: PayoutsState) => {
      state.payoutInformation.isLoading = true;
      state.payoutInformation.isError = false;
    },
    getPayoutInformationSuccess: (
      state: PayoutsState,
      payload: PayloadAction<PayoutInformation | null>
    ) => {
      state.payoutInformation.isError = false;
      state.payoutInformation.isLoading = false;
      state.payoutInformation.data = payload.payload;
    },
    getPayoutInformationFailure: (state: PayoutsState) => {
      state.payoutInformation.isError = true;
      state.payoutInformation.isLoading = false;
    },
  },
});

export const PayoutsReducer = payoutsSlice.reducer;
export const payoutsActions = payoutsSlice.actions;
