import React from "react";
import { InlineInputEdit } from "../../../common/InlineInputEdit";

interface WorkoutPlanNameInlineInputProps {
  name: string;
  updateWorkoutPlanName: (newName: string) => Promise<void>;
  isLoading: boolean;
}
export const WorkoutPlanNameInlineInput: React.FC<
  WorkoutPlanNameInlineInputProps
> = ({ name, updateWorkoutPlanName, isLoading }) => {
  return (
    <InlineInputEdit
      initialValue={name}
      variant="h5"
      handleSubmitText={updateWorkoutPlanName}
      isInputLoading={isLoading}
      validateText={(text: string) => text.length > 5}
      typographyProps={{
        fontWeight: "800",
        sx: {
          p: 0.5,
        },
      }}
    />
  );
};
