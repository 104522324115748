import React from "react";
import { WorkoutBuilder as Component } from "./WorkoutBuilder";
import { WorkoutBuilderProps } from "./types";
import { useWorkoutBuilder } from "./useWorkoutBuilder";

export const WorkoutBuilder: React.FC<WorkoutBuilderProps> = ({
  expandedWorkout,
  updateDescription,
  updateName,
  saveWorkout,
  isUpdating,
  isUpdateError,
  onPressBack,
  weekAndDay,
  workoutPlanName,
}) => {
  const props = useWorkoutBuilder({
    expandedWorkout,
    updateDescription,
    updateName,
    saveWorkout,
    isUpdating,
    isUpdateError,
    onPressBack,
    weekAndDay,
    workoutPlanName,
  });
  return <Component {...props} />;
};
