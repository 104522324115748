import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ResponsivePie } from "@nivo/pie";
import React from "react";
import { pluralize } from "../../../../util/string/pluralize";
import { PieChartDatum } from "../../../common/Charts/types";
import { SubscriptionStatus } from "../types";

interface PieChartProps {
  data: PieChartDatum<SubscriptionStatus>[];
}

export const SubscripionBreakdownPieChart: React.FC<PieChartProps> = ({
  data,
}) => {
  return (
    <>
      <ResponsivePie
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        colors={{ datum: "data.color" }}
        layers={["arcs", "arcLinkLabels", "arcLabels", "legends"]}
        theme={{
          labels: {
            text: {
              fontSize: 14,
              fontWeight: 800,
            },
          },
        }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["brighter", 20]],
        }}
        tooltip={({ datum }) => {
          return (
            <Card elevation={1} sx={{ p: 1, borderRadius: 1 }}>
              <Stack>
                <Typography variant="body2" fontSize={12}>
                  {datum.label}
                </Typography>
                <Typography variant="body2" fontWeight={800}>
                  {`${datum.value} ${pluralize("fan", datum.value)}`}
                </Typography>
              </Stack>
            </Card>
          );
        }}
        enableArcLinkLabels={false}
        sortByValue={true}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.15)",
            size: 4,
            padding: 1,
            stagger: true,
          },
        ]}
        fill={[
          {
            match: {
              id: "active",
            },
            id: "dots",
          },
          {
            match: {
              id: "trialing",
            },
            id: "dots",
          },
        ]}
      />
    </>
  );
};
