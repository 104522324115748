import { Pages } from "../../store/types";

export const groupBy = <T, K>(array: T[], groupByKey: (item: T) => K) => {
  const groups = new Map<K, T[]>();

  array.forEach((item) => {
    const groupKey = groupByKey(item);
    if (!groups.has(groupKey)) {
      groups.set(groupKey, []);
    }
    groups.get(groupKey)?.push(item);
  });

  return Array.from(groups.values());
};

export const searchPages = <T>(
  pages: Pages<T>,
  predicate: (item: T) => boolean
): { pageNumber: number; index: number } | null => {
  for (const [pageNumber, pageItems] of Object.entries(pages)) {
    const index = pageItems.findIndex(predicate);
    if (index !== -1) {
      return { pageNumber: Number(pageNumber), index };
    }
  }
  return null;
};

export const removeNthElementFromArray = <T>(
  array: T[],
  n: number,
  replaceWith?: T
) => {
  const replaceWithArray = replaceWith ? [replaceWith] : [];
  return [...array.slice(0, n), ...replaceWithArray, ...array.slice(n + 1)];
};

export const filterUnique = <T>(value: T, index: number, array: T[]) =>
  array.indexOf(value) === index;

export const filterUniqueBy =
  <T>(predicate: (a: T, b: T) => boolean) =>
  (value: T, index: number, array: T[]) =>
    array.findIndex((obj) => predicate(obj, value)) === index;

export const getFirstNItems = <T>(array: T[], n: number) => {
  return array.slice(0, n);
};
export const getLastNItems = <T>(array: T[], n: number) => {
  return array.slice(Math.max(array.length - n, 0));
};
