import {
  APICallState,
  createDefaultPaginatedResource,
  PaginatedResource,
  successLoadingErrorState,
} from "../types";

export interface PlaylistAPI {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  updated_at: string;
}
export interface WorkoutPlanAPI {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  updated_at: string;
}

export interface Playlist {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  updatedAt: string;
}

export interface PatchablePlaylist
  extends Partial<Pick<Playlist, "name" | "description">> {}

export interface PlaylistState {
  playlists: PaginatedResource<Playlist>;
  currentPlaylist: Playlist | null;
  create: APICallState;
  retrieve: APICallState;
  update: APICallState;
  destroy: APICallState;
}

export const defaultPlaylistState: PlaylistState = {
  playlists: createDefaultPaginatedResource(),
  currentPlaylist: null,
  create: successLoadingErrorState,
  retrieve: successLoadingErrorState,
  update: successLoadingErrorState,
  destroy: successLoadingErrorState,
};
