import GridView from "@mui/icons-material/GridView";
import React from "react";
import { EmptyList } from "../../../common/EmptyList";

interface Props {
  onClickCreateWorkoutPlan: () => void;
}

export const EmptyWorkoutPlans: React.FC<Props> = ({
  onClickCreateWorkoutPlan,
}) => {
  return (
    <EmptyList
      onClickButton={onClickCreateWorkoutPlan}
      primaryText={"No workout plans"}
      secondaryText={
        "Rome wasn't built in a day, but a workout plan can be! Let's get started!"
      }
      buttonText={"Create Workout Plan"}
      icon={<GridView />}
    />
  );
};
