import { RootStore } from "..";

export const selectCurrentCreatorExercise = (state: RootStore) =>
  state.exerciseState.currentCreatorExercise;

export const selectExercisesByPageNumber =
  (pageNumber: number) => (state: RootStore) => {
    if (state.exerciseState.exercises.data) {
      return state.exerciseState.exercises.data[pageNumber] ?? [];
    }
    return [];
  };

export const selectExercises = (state: RootStore) => {
  return state.exerciseState.exercises;
};

export const selectUpdateCreatorExerciseNote = (state: RootStore) =>
  state.exerciseState.updateCreatorExerciseNote;

export const selectUpdateCreatorExerciseVideo = (state: RootStore) =>
  state.exerciseState.updateCreatorExerciseVideo;

export const selectCreateExerciseVideoAPICallState = (state: RootStore) => {
  return state.exerciseState.createExerciseVideo;
};
