import { createDefaultRestParameterValue } from "../../components/pages/WorkoutBuilder/fixtures";
import { randomUUID } from "../../util/uuid";
import { ExerciseParameterValue } from "../workout/types";

export const createRestValueFromListIndexOrDefault = (
  index: number,
  restValues: ExerciseParameterValue[]
): ExerciseParameterValue => ({
  ...(restValues?.at(index) || createDefaultRestParameterValue()),
  id: randomUUID(),
});
