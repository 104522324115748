import React from "react";
import { MobileGate } from "./MobileGate";
import { RouteConfig } from "./types";

interface Props extends RouteConfig {}

export const BaseRoute: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return (
    <MobileGate shouldAllowMobile={!!props.allowMobileDimensions}>
      {children}
    </MobileGate>
  );
};
