import {
  APICallState,
  createDefaultPaginatedResource,
  createDefaultResource,
  PaginatedResource,
  Resource,
  successLoadingErrorState,
} from "../types";
import { Workout, WorkoutAPI } from "../workout/types";

export interface FavouriteWorkoutAPI {
  id: string;
  created_at: string;
  workout: WorkoutAPI;
}

export interface FavouriteWorkout {
  id: string;
  workout: Workout;
}

export interface FavouritesState {
  allFavourites: Resource<FavouriteWorkout[]>;
  favourites: PaginatedResource<FavouriteWorkout>;
  create: APICallState;
  delete: APICallState;
}

export const defaultFavouritesState: FavouritesState = {
  allFavourites: createDefaultResource(),
  favourites: createDefaultPaginatedResource(),
  create: successLoadingErrorState,
  delete: successLoadingErrorState,
};
