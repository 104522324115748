import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { Table } from "../../../common/Table";
import { WalletExpressAccountPayout } from "../types";
import { PayoutStatusChip } from "./PayoutStatusChip";
import { formatCurrencyFromBaseUnit } from "./utils";

interface Props {
  payouts: WalletExpressAccountPayout[];
}

export const PayoutsTable: React.FC<Props> = ({ payouts }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell align="right">Estimated Arrival Date</TableCell>
          <TableCell align="right">Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payouts.map((payout) => {
          return (
            <TableRow key={payout.id}>
              <TableCell>{`${new Date(payout.arrivalDate).toLocaleString(
                "default",
                {
                  month: "long",
                }
              )} ${new Date(payout.arrivalDate).getFullYear()}`}</TableCell>
              <TableCell>
                {formatCurrencyFromBaseUnit(payout.amount, payout.currency)}
              </TableCell>
              <TableCell align="right">
                {new Intl.DateTimeFormat(undefined, {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(payout.arrivalDate)}
              </TableCell>
              <TableCell align="right">
                <PayoutStatusChip status={payout.status} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
