import { SvgIconComponent } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";

interface Props {
  icon: SvgIconComponent;
  tooltip: string;
}

export const IconTooltipLabel: React.FC<Props> = ({ icon: Icon, tooltip }) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <Icon />
    </Tooltip>
  );
};
