import axios, { AxiosError } from "axios";
import { decodeToken } from "react-jwt";
import { DispatchFn } from "..";
import { signIn } from "../../util/http/jwt";
import { DecodedToken } from "../../util/http/types";
import { userActions } from "./slice";
import { transformUserFromAPI } from "./transformer";

export const SignIn = async (
  dispatch: DispatchFn,
  email: string,
  password: string
) => {
  try {
    dispatch(userActions.signInAttempt());
    const token = await signIn(email, password);
    const user = transformUserFromAPI(token.user);
    dispatch(userActions.signInSuccess(user));
    return user;
  } catch (e) {
    dispatch(userActions.signInFailure());
    if (axios.isAxiosError(e)) {
      throw e as AxiosError;
    }
    throw e;
  }
};

export const SetUserFromToken = (dispatch: DispatchFn, accessToken: string) => {
  const token = decodeToken<DecodedToken>(accessToken)!;
  const user = transformUserFromAPI(token.user);
  dispatch(userActions.signInSuccess(user));
};
