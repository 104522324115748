import { Pagination, PaginationItem } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { FormConfirmDialog } from "../../common/Dialog";
import { PageTemplateWithHeader } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { EmptyWorkoutPlans } from "./EmptyWorkoutPlans";
import { MyWorkoutPlansLoading } from "./MyWorkoutPlansLoading";
import { PlaylistCardsGrid } from "./PlaylistCardsGrid";
import { useMyWorkoutPlans } from "./useMyWorkoutPlans";

interface Props extends ReturnType<typeof useMyWorkoutPlans> {}

export const MyWorkoutPlans: React.FC<Props> = ({
  workoutPlans,
  totalPages,
  currentPage,
  newWorkoutPlanDialogProps,
  openNewWorkoutPlanDialog,
  isLoading,
  isShowingErrorMessage,
  hideErrorMessage,
  totalWorkoutPlans,
}) => {
  return (
    <>
      <PageTemplateWithHeader
        title="My Workout Plans"
        buttonTitle="Create Workout Plan"
        subtitle="Manage your workout plans here"
        onClickButton={openNewWorkoutPlanDialog}
      >
        {isLoading ? (
          <MyWorkoutPlansLoading />
        ) : (
          <>
            <Pagination
              count={totalPages}
              page={currentPage}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/workout-plans${
                    item.page === 1 ? "" : `?page=${item.page}`
                  }`}
                  {...item}
                />
              )}
            />
            {totalWorkoutPlans ? (
              <PlaylistCardsGrid workoutPlans={workoutPlans} />
            ) : (
              <EmptyWorkoutPlans
                onClickCreateWorkoutPlan={openNewWorkoutPlanDialog}
              />
            )}
          </>
        )}
        <FormConfirmDialog {...newWorkoutPlanDialogProps} />
      </PageTemplateWithHeader>
      <SnackbarAlert
        close={hideErrorMessage}
        isOpen={isShowingErrorMessage}
        severity="error"
        message="Oops! Something went wrong when refreshing your workout plans! Please try again later."
      />
    </>
  );
};
