import React from "react";
import { useMyFavouritedWorkoutsList } from "./useMyFavouritedWorkoutsList";
import { MyFavouritedWorkoutsList as Component } from "./MyFavouritedWorkoutsList";
import { WorkoutList } from "../../../../store/plan-builder/types";

export interface MyFavouritedWorkoutsListProps {
  myFavouritedWorkoutsListRef: React.MutableRefObject<HTMLDivElement | null>;
  onClickRefreshButton: () => void;
  isLoadingMyWorkouts: boolean;
  myWorkouts: WorkoutList;
}

export const MyFavouritedWorkoutsList: React.FC<
  MyFavouritedWorkoutsListProps
> = ({
  myFavouritedWorkoutsListRef,
  onClickRefreshButton,
  isLoadingMyWorkouts,
  myWorkouts,
}) => {
  const props = useMyFavouritedWorkoutsList({
    myFavouritedWorkoutsListRef,
    onClickRefreshButton,
    isLoadingMyWorkouts,
    myWorkouts,
  });
  return <Component {...props} />;
};
