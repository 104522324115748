import React from "react";
import { BaseWorkoutPlan } from "../../../../store/workout-plan/types";
import { PlaylistCard as Component } from "./PlaylistCard";
import { usePlaylistCard } from "./usePlaylistCard";

export interface PlaylistCardProps {
  workoutPlan: BaseWorkoutPlan;
}

export const PlaylistCard: React.FC<PlaylistCardProps> = (props) => {
  const componentProps = usePlaylistCard(props);
  return <Component {...componentProps} />;
};
