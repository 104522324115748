import { AxiosResponse } from "axios";
import { DispatchFn } from "..";
import client from "../../util/http/axios";
import { PaginatedAPI } from "../types";
import { workoutActions } from "./slice";
import {
  transformAllWorkoutsFromAPI,
  transformExpandedScheduleItemFromAPI,
  transformExpandedWorkoutFromAPI,
  transformExpandedWorkoutToAPI,
} from "./transformer";
import {
  ExpandedWorkoutAPI,
  ExpandedWorkout,
  Workout,
  WorkoutAPI,
  ExpandedScheduleItemAPI,
} from "./types";
import { workoutPlanActions } from "../workout-plan/slice";

export const GetWorkouts = async (dispatch: DispatchFn, pageNumber: number) => {
  dispatch(workoutActions.getWorkoutsAttempt());
  try {
    const response = await client.get<PaginatedAPI<WorkoutAPI>>(
      "/builder/workout",
      { params: { page: pageNumber } }
    );
    const workouts = transformAllWorkoutsFromAPI(response.data.results);
    dispatch(workoutActions.setWorkoutsCount({ count: response.data.count }));
    dispatch(workoutActions.getWorkoutsSuccess({ pageNumber, workouts }));
  } catch {
    dispatch(workoutActions.getWorkoutsFailure());
  }
};

export const GetAllWorkouts = async (dispatch: DispatchFn) => {
  dispatch(workoutActions.getAllWorkoutsAttempt());
  try {
    const response = await client.get<WorkoutAPI[]>("builder/all-workouts");
    const workouts = transformAllWorkoutsFromAPI(response.data);
    dispatch(workoutActions.getAllWorkoutsSuccess({ workouts }));
  } catch {
    dispatch(workoutActions.getAllWorkoutsFailure());
  }
};

export const PostWorkout = async (
  dispatch: DispatchFn,
  workoutName: string
) => {
  dispatch(workoutActions.postWorkoutAttempt());
  try {
    const response = await client.post<
      ExpandedWorkoutAPI,
      AxiosResponse<ExpandedWorkoutAPI>,
      Pick<Workout, "name">
    >("/builder/workout/", { name: workoutName });
    const newWorkout = transformExpandedWorkoutFromAPI(response.data);
    dispatch(workoutActions.postWorkoutSuccess({ newWorkout }));
    dispatch(workoutActions.clearWorkoutsData());
    return newWorkout.id;
  } catch {
    dispatch(workoutActions.postWorkoutFailure());
  }
};

export const GetWorkout = async (dispatch: DispatchFn, workoutId: string) => {
  dispatch(workoutActions.getWorkoutAttempt());
  try {
    const response = await client.get<
      ExpandedWorkoutAPI,
      AxiosResponse<ExpandedWorkoutAPI>
    >(`/builder/workout/${workoutId}`);
    const expandedWorkout = transformExpandedWorkoutFromAPI(response.data);
    dispatch(
      workoutActions.getWorkoutSuccess({ selectedWorkout: expandedWorkout })
    );
    return expandedWorkout;
  } catch (e) {
    dispatch(workoutActions.getWorkoutFailure());
  }
};

export const PatchWorkout = async (
  dispatch: DispatchFn,
  workoutId: string,
  patchableWorkout: Partial<ExpandedWorkout>
) => {
  dispatch(workoutActions.patchWorkoutAttempt());
  try {
    const patchableWorkoutAPI = transformExpandedWorkoutToAPI(patchableWorkout);

    const response = await client.patch<
      ExpandedWorkoutAPI,
      AxiosResponse<ExpandedWorkoutAPI>,
      Partial<ExpandedWorkoutAPI>
    >(`/builder/workout/${workoutId}/`, patchableWorkoutAPI);

    const patchedWorkout = transformExpandedWorkoutFromAPI(response.data);
    dispatch(workoutActions.patchWorkoutSuccess({ patchedWorkout }));
    return patchedWorkout;
  } catch {
    dispatch(workoutActions.patchWorkoutFailure());
  }
};

export const DeleteWorkout = async (
  dispatch: DispatchFn,
  workoutId: string
) => {
  dispatch(workoutActions.deleteWorkoutAttempt());
  try {
    await client.delete(`/builder/workout/${workoutId}/`);
    dispatch(workoutActions.deleteWorkoutSuccess());
  } catch {
    dispatch(workoutActions.deleteWorkoutFailure());
  }
};

export const GetExpandedScheduleItem = async (
  dispatch: DispatchFn,
  scheduleItemId: string
) => {
  dispatch(workoutActions.getWorkoutAttempt());
  try {
    const scheduleItemAPI = await client.get<
      ExpandedScheduleItemAPI,
      AxiosResponse<ExpandedScheduleItemAPI>
    >(`/builder/playlist-workout-day/${scheduleItemId}`);

    const expandedScheduleItem = transformExpandedScheduleItemFromAPI(
      scheduleItemAPI.data
    );
    dispatch(
      workoutActions.getWorkoutSuccess({
        selectedWorkout: expandedScheduleItem.workout,
      })
    );
    return expandedScheduleItem;
  } catch (e) {
    dispatch(workoutActions.getWorkoutFailure());
  }
};

export const PutWorkoutFromScheduleItemID = async (
  dispatch: DispatchFn,
  scheduleItemId: string,
  putableExpandedWorkout: Partial<ExpandedWorkout>
) => {
  dispatch(workoutActions.patchWorkoutAttempt());
  try {
    const putableExpandedWorkoutAPI = transformExpandedWorkoutToAPI(
      putableExpandedWorkout
    );

    const scheduleItemAPI = await client.put<
      ExpandedScheduleItemAPI,
      AxiosResponse<ExpandedScheduleItemAPI>
    >(
      `/builder/playlist-workout-day/${scheduleItemId}/`,
      putableExpandedWorkoutAPI
    );
    const expandedScheduleItem = transformExpandedScheduleItemFromAPI(
      scheduleItemAPI.data
    );
    dispatch(
      workoutActions.patchWorkoutSuccess({
        patchedWorkout: expandedScheduleItem.workout,
      })
    );
    return expandedScheduleItem;
  } catch {
    dispatch(workoutActions.patchWorkoutFailure());
  }
};

export const PatchWorkoutFromScheduleItem = async (
  dispatch: DispatchFn,
  scheduleItemId: string,
  patchableExpandedWorkout: Partial<ExpandedWorkout>
) => {
  dispatch(workoutActions.patchWorkoutAttempt());
  try {
    const patchableExpandedWorkoutAPI = transformExpandedWorkoutToAPI(
      patchableExpandedWorkout
    );
    const scheduleItemAPI = await client.patch<
      ExpandedScheduleItemAPI,
      AxiosResponse<ExpandedScheduleItemAPI>
    >(
      `/builder/playlist-workout-day/${scheduleItemId}/`,
      patchableExpandedWorkoutAPI
    );
    const expandedScheduleItem = transformExpandedScheduleItemFromAPI(
      scheduleItemAPI.data
    );
    dispatch(
      workoutActions.patchWorkoutSuccess({
        patchedWorkout: expandedScheduleItem.workout,
      })
    );
    dispatch(
      workoutPlanActions.updateWorkoutInAllScheduleItems({
        workout: expandedScheduleItem.workout,
      })
    );
  } catch (e) {
    dispatch(workoutActions.patchWorkoutFailure());
  }
};
