import { useCallback, useEffect, useMemo } from "react";
import { useRequest } from "../../../../hooks/request/useRequest";
import { SubscriptionStatusBreakdownAPI } from "../types";
import { formatSubscriptionStatusBreakdownAPIToPieChartData } from "../utils";

export const useSubscriptionStatusBreakdown = () => {
  const [_refreshSubStatusBreakdown, subscriptionStatusBreakdownResource] =
    useRequest<SubscriptionStatusBreakdownAPI[]>(
      "GET",
      "/dashboard/subscription-status-breakdown"
    );

  const refreshSubStatusBreakdown = useCallback(async () => {
    _refreshSubStatusBreakdown();
  }, [_refreshSubStatusBreakdown]);

  const subscriptionStatusBreakdownPieChartData = useMemo(() => {
    if (subscriptionStatusBreakdownResource.data) {
      return formatSubscriptionStatusBreakdownAPIToPieChartData(
        subscriptionStatusBreakdownResource.data
      );
    }
    return null;
  }, [subscriptionStatusBreakdownResource.data]);

  useEffect(() => {
    !subscriptionStatusBreakdownResource.data && refreshSubStatusBreakdown();
  }, [refreshSubStatusBreakdown, subscriptionStatusBreakdownResource]);

  return {
    refreshSubStatusBreakdown,
    subscriptionStatusBreakdownResource,
    subscriptionStatusBreakdownPieChartData,
  };
};
